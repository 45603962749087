import { takeLatest, spawn, put } from 'redux-saga/effects';

// Project
import * as APP from 'commons/reducer';
import apiRequest, { apiSuccess, post } from '../../utils/api';
import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL, // LOG_OUT,
  appActions,
} from '../reducer';

function* getUser() {
  yield takeLatest(GET_USER, function* getUserApi() {
    const response = yield apiRequest('api/v1/auth/user', { method: 'get' }, true, true);
    if (!response.error) {
      yield put(apiSuccess(GET_USER_SUCCESS, response));
    } else {
      localStorage.removeItem('user');
      yield put(apiSuccess(GET_USER_FAIL));
    }
  });
}
function* checkUser() {
  yield takeLatest(APP.CHECK_USER, function* getUserApi() {
    const response = yield apiRequest('api/v1/auth/user', { method: 'get' }, true, true);
    if (!response.error) {
      yield put(apiSuccess(APP.CHECK_USER_SUCCESS, response));
      if (response.user.disabled) {
        yield put(apiSuccess(APP.RESET));
      }
    } else {
      localStorage.removeItem('user');
      yield put(apiSuccess(APP.CHECK_USER_FAIL));
    }
  });
}

function* getMaterialsByName() {
  yield takeLatest(APP.GET_MATERIALS_BY_NAME, function* (action) {
    yield put(appActions.setLoader('get-material-by-name', true));
    const response = yield post('api/v1/quotes/get-materials-by-name', action.payload);
    if (!response.error) {
      yield put(apiSuccess(APP.GET_MATERIALS_BY_NAME_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.GET_MATERIALS_BY_NAME_FAIL));
    }
    yield put(appActions.setLoader('get-material-by-name', false));
  });
}

export default function* root() {
  yield spawn(getUser);
  yield spawn(checkUser);
  yield spawn(getMaterialsByName);
}
