import React from 'react';
import { MenuItem, Select } from '@mui/material';

const SelectCurrency = function (props) {
  const { currencyCode, setCurrencyCode } = props;
  const handleChangeCurrency = (e) => {
    setCurrencyCode(e.target.value);
  };
  return (
    <Select
      id="currency"
      name="currency"
      value={currencyCode}
      sx={{ width: '100px' }}
      onChange={handleChangeCurrency}>
      <MenuItem value="USD">USD</MenuItem>
      <MenuItem value="CLP">CLP</MenuItem>
    </Select>
  );
};

export default SelectCurrency;
