import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead = React.memo((customProps) => {
  const { classes, headCells, kardexHeader, onRequestSort, orderX, orderByX, zcotHeader } =
    customProps;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let headerClass = '';
  if (kardexHeader) {
    headerClass = classes.kardexHeader;
  } else if (zcotHeader) {
    headerClass = classes.zcotHeader;
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <Tooltip key={headCell.id} title={headCell.tooltip} placement="top-start">
            <TableCell
              align={headCell.numeric ? 'right' : 'left'}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderByX === headCell.id ? orderX : false}
              className={headerClass}>
              <TableSortLabel
                active={orderByX === headCell.id}
                direction={orderByX === headCell.id ? orderX : 'asc'}
                // direction="desc"
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderByX === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {orderX === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
  );
});

const FormulaResultsTable = function (props) {
  const { controls, actions, classes, convertKardexValue, convertZcotValue } = props;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('incidencePercent');
  const materialsList = useMemo(() => Object.values(controls.materials), [controls.materials]);
  const headCells = [
    { id: 'component', numeric: false, label: 'Código mat.', tooltip: '' },
    { id: 'name', numeric: false, label: 'Nombre', tooltip: '' },
    {
      id: 'subQuantity',
      numeric: false,
      label: 'Aporte',
      tooltip: 'Aporte en kg del material asociado a cantidad base',
    },
    { id: 'base_unit', numeric: false, label: 'UMB', tooltip: 'Unidad de medida base' },
    {
      id: 'unit',
      numeric: false,
      label: 'UM',
      tooltip: 'Unidad de medida del material en la formula',
    },
    {
      id: 'quant_base',
      numeric: false,
      label: 'Cant. Base',
      tooltip: 'Cantidad que se encuentra formulada la premezcla',
    },
    {
      id: 'costDollar',
      numeric: false,
      label: 'Kardex Unit.',
      kardexHeader: true,
      tooltip: 'Costo de 1 unidad actual en SAP',
    },
    {
      id: 'costPerFormulaDollar',
      numeric: false,
      label: 'Kardex',
      kardexHeader: true,
      tooltip: 'Costo unitario kardex por el aporte dividido la cantidad base',
    },
    {
      id: 'zcot',
      numeric: false,
      label: 'ZCOT unit.',
      zcotHeader: true,
      tooltip: 'Costo proyectado',
    },
    {
      id: 'zcotPerFormula',
      numeric: false,
      label: 'ZCOT',
      zcotHeader: true,
      tooltip: 'Costo de 1 unidad proyectado',
    },
    {
      id: 'incidencePercent',
      numeric: false,
      label: '% Incidencia',
      kardexHeader: true,
      tooltip: 'Incidencia porcentual de costo Kardex sobre el costo Kardex total',
    },
  ];
  const lsParams = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderByX) {
    const aParsed = a[orderByX] || '';
    const bParsed = b[orderByX] || '';
    if (!Number.isNaN(Number(aParsed))) {
      if (bParsed < aParsed) {
        return -1;
      }
      if (bParsed > aParsed) {
        return 1;
      }
      return 0;
    }
    return bParsed.localeCompare(aParsed, 'en', { sensitivity: 'base' });
  }

  function getComparator(orderX, orderByX) {
    return orderX === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByX)
      : (a, b) => -descendingComparator(a, b, orderByX);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderX = comparator(a[0], b[0]);
      if (orderX !== 0) {
        return orderX;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const getComparatorSymbol = (kardex, zcot) => {
    const parsedKardex = kardex?.toFixed(2) || 0;
    const parsedZcot = zcot?.toFixed(2) || 0;
    if (Number(parsedKardex) > Number(parsedZcot)) return '↓';
    if (Number(parsedKardex) < Number(parsedZcot)) return '↑';
    return '';
  };

  const getComparatorClass = (kardex, zcot) => {
    const parsedKardex = kardex?.toFixed(2) || 0;
    const parsedZcot = zcot?.toFixed(2) || 0;
    if (Number(parsedKardex) > Number(parsedZcot)) return classes.textGreen;
    if (Number(parsedKardex) < Number(parsedZcot)) return classes.textRed;
    return '';
  };

  const getComparatorClassOrSymbol = (type, kardexValue, zcotValue) => {
    const kardex = convertKardexValue(kardexValue || 0);
    const zcot = convertZcotValue(zcotValue || 0);
    if (type === 'class') {
      return getComparatorClass(kardex, zcot);
    }
    if (type === 'symbol') {
      return getComparatorSymbol(kardex, zcot);
    }
    return '';
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <Table
        sx={{ minWidth: 650 }}
        stickyHeader
        size="small"
        aria-label="detalle del cálculo de la fórmula">
        <EnhancedTableHead
          headCells={headCells}
          classes={classes}
          orderX={order}
          orderByX={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(materialsList, getComparator(order, orderBy)).map((row) => (
            <TableRow
              className={classes.zebra}
              key={row.component}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row?.component}</TableCell>
              <TableCell align="left">{row?.name}</TableCell>
              <TableCell align="right">
                {row?.subQuantity?.toLocaleString('es-CL', lsParams)}
              </TableCell>
              <TableCell align="right">{row?.base_unit}</TableCell>
              <TableCell align="right">{row?.unit}</TableCell>
              <TableCell align="right">{row?.quant_base}</TableCell>
              {/* KARDEX unit */}
              <TableCell align="right" className={classes.zebraKardex}>
                {convertKardexValue(row?.costDollar || 0).toLocaleString('es-CL', lsParams)}
              </TableCell>
              {/* KARDEX */}
              <TableCell align="right" className={classes.zebraKardex}>
                {convertKardexValue(row?.costPerFormulaDollar || 0).toLocaleString(
                  'es-CL',
                  lsParams,
                )}
              </TableCell>
              {/* ZCOT unit. */}
              <TableCell
                align="right"
                className={getComparatorClassOrSymbol('class', row?.costDollar, row?.zcot)}>
                {`${getComparatorClassOrSymbol(
                  'symbol',
                  row?.costDollar,
                  row?.zcot,
                )} ${convertZcotValue(row?.zcot).toLocaleString('es-CL', lsParams)}`}
              </TableCell>
              {/* ZCOT */}
              <TableCell
                align="right"
                className={getComparatorClassOrSymbol(
                  'class',
                  row?.costPerFormulaDollar,
                  row?.zcotPerFormula,
                )}>
                {`${getComparatorClassOrSymbol(
                  'symbol',
                  row?.costPerFormulaDollar,
                  row?.zcotPerFormula,
                )} ${convertZcotValue(row?.zcotPerFormula).toLocaleString('es-CL', lsParams)}`}
              </TableCell>
              <TableCell align="right" className={classes.zebraKardex}>
                {row?.incidencePercent?.toFixed(2)}%
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.stickyRow} sx={{ bottom: '114px' }}>
            <TableCell rowSpan={5} />
            <TableCell colSpan={1}>Total</TableCell>
            <TableCell align="right">
              {controls.totals?.totalQuantity?.toLocaleString('es-CL', lsParams)}
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right">
              {convertKardexValue(controls.totals?.totalCostDolar || 0).toLocaleString(
                'es-CL',
                lsParams,
              )}
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right">
              {convertZcotValue(controls.totals?.totalZcot).toLocaleString('es-CL', lsParams)}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
          <TableRow className={classes.stickyRow} sx={{ bottom: '70px' }}>
            <TableCell colSpan={5}>
              Costos variables{' '}
              <Button
                variant="outlined"
                size="small"
                onClick={() => actions.changeModals('variableCosts')}>
                Editar costos
              </Button>
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">
              {convertKardexValue(controls.totals?.totalVariableCosts).toLocaleString(
                'es-CL',
                lsParams,
              )}
            </TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
          <TableRow className={classes.stickyRow} sx={{ bottom: '37px' }}>
            <TableCell colSpan={5}>Costo Estándar</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">
              {convertKardexValue(controls.totals?.kardexDolar).toLocaleString('es-CL', lsParams)}
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
          <TableRow className={classes.stickyRow} sx={{ bottom: '0px' }}>
            <TableCell colSpan={5}>Costo Proyectado (Total ZCOT + Costos Variables)</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">
              <Typography fontWeight="bold">
                {convertZcotValue(controls.totals?.totalProjectedCost).toLocaleString(
                  'es-CL',
                  lsParams,
                )}
              </Typography>
            </TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormulaResultsTable;
