import React, { useCallback, useMemo } from 'react';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { simulateEvent } from 'utils/functions';

const FormBelow = function (props) {
  const { controls, onChange, errors, handleNext } = props;

  const paymentConditions = [
    { id: 1, description: 'Al contado' },
    { id: 2, description: '15 días' },
    { id: 3, description: '30 días' },
    { id: 4, description: '45 días' },
    { id: 5, description: '60 días' },
    { id: 6, description: '75 días' },
    { id: 7, description: '90 días' },
    { id: 8, description: '120 días' },
  ];

  const paymentConditionValue = useMemo(
    () => paymentConditions.find((p) => p.id === controls.paymentCondition) ?? null,
    [paymentConditions, controls.paymentCondition],
  );
  const handleChange = useCallback(
    (e, newValue) => onChange(simulateEvent('paymentCondition', 'string', newValue?.id ?? '')),
    [onChange, simulateEvent],
  );

  return (
    <>
      <Grid container sx={{ maxWidth: '400px' }}>
        <Grid item mt="20px" sm={12}>
          <TextField
            id="atn"
            label="Nombre cliente"
            variant="outlined"
            value={controls.atn}
            onChange={onChange}
            sx={{ mr: '10px' }}
            inputProps={{ maxLength: 255 }}
            error={errors.atn?.length > 0}
            helperText={errors.atn ? errors?.atn[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mt="20px" sm={12}>
          <TextField
            id="delivery"
            label="Entrega"
            variant="outlined"
            value={controls.delivery}
            inputProps={{ maxLength: 255 }}
            onChange={onChange}
            error={errors.delivery?.length > 0}
            helperText={errors.delivery ? errors?.delivery[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mt="20px" sm={12}>
          <TextField
            id="validity"
            label="Validez de la cotización"
            variant="outlined"
            value={controls.validity}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.validity?.length > 0}
            helperText={errors.validity ? errors?.validity[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mt="20px" sm={12}>
          <TextField
            id="sender"
            label="Ejecutivo"
            variant="outlined"
            value={controls.sender}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.sender?.length > 0}
            helperText={errors.sender ? errors?.sender[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mt="20px" sm={12}>
          <TextField
            id="phone"
            label="Teléfono"
            variant="outlined"
            value={controls.phone}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.phone?.length > 0}
            helperText={errors.phone ? errors?.phone[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mt="20px" mb="20px" sm={12}>
          <TextField
            id="email"
            label="e-mail"
            variant="outlined"
            value={controls.email}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.email?.length > 0}
            helperText={errors.email ? errors?.email[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item mb="20px" sm={12}>
          <Autocomplete
            disablePortal
            id="paymentCondition"
            value={paymentConditionValue}
            options={paymentConditions}
            getOptionLabel={(opt) => opt.description}
            sx={{ width: 230, mr: 1 }}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                required
                label="Condición de pago"
                error={errors.paymentCondition?.length > 0}
                helperText={errors.paymentCondition ? errors?.paymentCondition[0] : null}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ maxWidth: '800px' }}>
        <Grid item mb="10px" sm={12}>
          <TextField
            id="comments"
            label="Observaciones"
            variant="outlined"
            value={controls.comments}
            onChange={onChange}
            error={errors.comments?.length > 0}
            helperText={errors.comments ? errors?.comments[0] : null}
            fullWidth
            rows={5}
            multiline
          />
        </Grid>
        <Grid item sm={12} mb="60px">
          <Grid container justifyContent="flex-end" spacing={1}>
            {/* {Object.entries(errors).length > 0 && (
              <Grid item>
                <FormHelperText error>Solucione los problemas antes de continuar. </FormHelperText>
              </Grid>
            )} */}
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Previsualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormBelow;
