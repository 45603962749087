import React from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';

const QuoteStepper = function (props) {
  const { step } = props;
  const steps = ['Resumen', 'Cotización', 'Generar PDF'];
  return (
    <Box sx={{ width: '100%', mb: '30px' }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default QuoteStepper;
