import React from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead = React.memo((customProps) => {
  const { headCells, orderX, orderByX, onRequestSort } = customProps;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderByX === headCell.id ? orderX : false}>
            <TableSortLabel
              active={orderByX === headCell.id}
              direction={orderByX === headCell.id ? orderX : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderByX === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {orderX === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const MaintainersUsersTable = function (props) {
  const { users, actions, disabledTable } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('incidencePercent');
  // const materialsList = useMemo(() => Object.values(controls.materials), [controls.materials]);
  const headCells = [
    { id: 'id', numeric: true, label: 'id' },
    { id: 'name', numeric: false, label: 'Nombre' },
    { id: 'position', numeric: false, label: 'Cargo' },
    { id: 'phone', numeric: false, label: 'Teléfono' },
    { id: 'email', numeric: false, label: 'Email' },
    { id: 'species', numeric: false, label: 'Especies' },
    { id: 'type', numeric: false, label: 'Rol' },
    { id: '', numeric: false, label: 'Acciones' },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderByX) {
    const aParsed = a[orderByX] || '';
    const bParsed = b[orderByX] || '';
    if (bParsed < aParsed) {
      return -1;
    }
    if (bParsed > aParsed) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderX, orderByX) {
    return orderX === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByX)
      : (a, b) => -descendingComparator(a, b, orderByX);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderX = comparator(a[0], b[0]);
      if (orderX !== 0) {
        return orderX;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenUserForm = (data) => {
    actions.changeControls({ userForm: data });
    actions.toggleModals('userForm');
  };

  const handleDisableUser = (id) => {
    actions.disableUser({
      id,
    });
  };

  const handleEnableUser = (id) => {
    actions.enableUser({
      id,
    });
  };

  return (
    <>
      <Grid container mb="20px" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Usuarios</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              handleOpenUserForm({
                id: '',
                name: '',
                email: '',
                position: '',
                type: 'user',
                password: '',
                phone: '',
                species: [],
              })
            }>
            Nuevo
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {disabledTable && (
          <>
            <Backdrop
              sx={{ position: 'absolute', zIndex: 1000, background: 'white' }}
              style={{ opacity: 0.7 }}
              open>
              <CircularProgress color="primary" />
            </Backdrop>
            <LinearProgress style={{ zIndex: 1001 }} />
          </>
        )}
        <Table aria-label="tabla de usuarios" sx={{ width: '100%' }}>
          <EnhancedTableHead
            headCells={headCells}
            orderX={order}
            orderByX={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rowsPerPage > 0 &&
              stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row?.id}</TableCell>
                    <TableCell align="left">{row?.name}</TableCell>
                    <TableCell align="left">{row?.position}</TableCell>
                    <TableCell align="left">{row?.phone}</TableCell>
                    <TableCell align="left">{row?.email}</TableCell>
                    <TableCell align="left">{row?.speciesNames()}</TableCell>
                    <TableCell align="left">{row?.type}</TableCell>
                    <TableCell align="left" width="130px">
                      <IconButton
                        onClick={() =>
                          handleOpenUserForm({
                            id: row?.id || '',
                            name: row?.name || '',
                            email: row?.email || '',
                            position: row?.position || '',
                            type: row?.type || '',
                            password: '',
                            phone: row?.phone || '',
                            species: row?.speciesArr(),
                          })
                        }>
                        <EditIcon />
                      </IconButton>
                      {row?.disabled ? (
                        <IconButton onClick={() => handleEnableUser(row?.id)}>
                          <PersonAddAltIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleDisableUser(row?.id)}>
                          <PersonRemoveIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={13}
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count: total }) => `${from}-${to} de ${total}`}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaintainersUsersTable;
