import React from 'react';
import { useForm } from 'utils/hooks/useForm';
// import moment from 'moment';
import 'moment/locale/es';
import { numberBetween, valueHasData } from 'utils/validationsHelper';
import { isNumber } from 'utils/math';
import { validateEmail } from 'utils/functions';
import moment from 'moment';
import FormAbove from './FormAbove';
import MaterialLines from './MaterialLines';
import FormBelow from './FormBelow';

const Form = function (props) {
  const {
    controls,
    setNextStep,
    actions,
    classes,
    handleChange,
    handleRemoveMaterial,
    linesHasChanged,
    calculatedValues,
    currencyCode,
    setCurrencyCode,
    convertKardexValue,
    convertZcotValue,
  } = props;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: handleChange,
    validations: {
      clientCode: [
        {
          check: (value) => valueHasData(value) || valueHasData(controls.clientName),
          message: 'Debe ingresar un código de cliente si no ingresa la empresa. ',
        },
        {
          check: (value) => !Number.isNaN(Number(value.trim())),
          message: 'El código debe ser numérico. ',
        },
      ],
      clientName: [
        {
          check: (value) => valueHasData(value) || valueHasData(controls.clientCode),
          message: 'Debe ingresar un nombre de empresa si no ingresa el código de cliente. ',
        },
      ],
      startDate: [
        {
          check: (value) => value != null,
          message: 'Debe ingresar fecha inicial. ',
        },
        {
          check: (value) =>
            moment(value).isSame(controls.endDate || value, 'day') ||
            moment(value).isBefore(controls.endDate),
          message: 'Fecha de inicio no debe ser mayor a la final. ',
        },
      ],
      endDate: [
        {
          check: (value) => value != null,
          message: 'Debe ingresar fecha final. ',
        },
        {
          check: (value) =>
            moment(value).isSame(controls.startDate || value, 'day') ||
            moment(value).isAfter(controls.startDate),
          message: 'Fecha final no debe ser menor a la inicial. ',
        },
      ],
      materialLines: [
        {
          check: (value) => value?.length,
          message: 'Debe ingresar al menos un producto. ',
        },
        {
          check: (value) => value.every((row) => row?.name && row?.mgPerc && row?.volumeKg),
          message: 'Debe completar todos los campos de productos. ',
        },
        {
          check: (value) => value.every((row) => numberBetween(row?.mgPerc || 0, 1, 99)),
          message: 'MG% debe ser entre 1 y 99 ',
        },
      ],
      atn: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar nombre cliente. ',
        },
      ],
      paymentCondition: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar la condición de pago. ',
        },
        {
          check: (value) => isNumber(value),
          message: 'Error inesperado en la opción seleccionada. Contacte al administrador. ',
        },
      ],
      email: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un correo. ',
        },
        {
          check: (value) => validateEmail(value),
          message: 'Correo inválido ',
        },
      ],
    },
  });

  const handleNext = () => {
    onSubmit(setNextStep);
  };

  return (
    <>
      <FormAbove
        controls={controls}
        actions={actions}
        classes={classes}
        onChange={onChange}
        errors={errors}
      />
      <MaterialLines
        linesHasChanged={linesHasChanged}
        controls={controls}
        classes={classes}
        actions={actions}
        onChangeMain={onChange}
        handleRemoveMaterial={handleRemoveMaterial}
        calculatedValues={calculatedValues}
        errorsMain={errors}
        currencyCode={currencyCode}
        setCurrencyCode={setCurrencyCode}
        convertKardexValue={convertKardexValue}
        convertZcotValue={convertZcotValue}
      />
      <FormBelow
        controls={controls}
        actions={actions}
        setNextStep={setNextStep}
        classes={classes}
        onChange={onChange}
        errors={errors}
        handleNext={handleNext}
      />
    </>
  );
};

export default Form;
