import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  root: {
    marginBottom: '50px',
  },
  zebra: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  stickyRow: {
    position: 'sticky',
    backgroundColor: 'white',
  },
  button: {
    height: '55px',
  },
  ...commonStyles,
});

export default styles;
