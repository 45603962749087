import React, { useEffect, useMemo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { appActions } from 'commons/reducer';
import { ReactReduxContext } from 'config/configureStore';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { clone } from 'utils/functions';
import { actionProps, selectState } from 'utils/reduxActions';
import { valueHasData } from 'utils/validationsHelper';

const SearchMaterialByName = function (props) {
  const {
    foundMaterials,
    actions,
    materialId,
    callback,
    loaders,
    sx = {},
    allMaterials = false,
    internal = false,
    historic = false,
    disabled = false,
  } = props;

  const loading = loaders['get-material-by-name'];

  const foundMaterialValue = useMemo(
    () => foundMaterials.find((material) => material?.c_material === Number(materialId)) ?? null,
    [foundMaterials, materialId],
  );

  useEffect(() => {
    // we use this to clear the suggestions from the materials input
    actions.getMaterialsByName({});
  }, []);

  const handleChange = (e, val) => {
    callback(`${val?.c_material || ''}`);
  };

  const handleInputChange = (e, value) => {
    if (valueHasData(value)) {
      actions.getMaterialsByName({ materialName: value, allMaterials, internal, historic });
    }
  };

  const handleDebounce = (e, value, reason) => {
    if (reason !== 'reset') {
      const interval = setTimeout(() => {
        handleInputChange(e, value);
      }, 1000);
      return () => clearTimeout(interval);
    }
    return null;
  };

  return (
    <Autocomplete
      disablePortal
      id="materialName"
      loading={loading}
      autoComplete
      filterOptions={(x) => x}
      value={foundMaterialValue}
      options={foundMaterials}
      getOptionLabel={(opt) => `${opt.description}`}
      sx={sx}
      onInputChange={handleDebounce}
      onChange={handleChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Buscar por nombre material"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      noOptionsText="Sin opciones"
    />
  );
};

const withConnect = connect(
  selectState('app.foundMaterials', 'app.loaders'),
  actionProps(clone(clone.OBJECT, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(withConnect)(SearchMaterialByName);
