// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
// import {
//     takeLatest,
//     spawn,
//     put,
//   } from 'redux-saga/effects';

// import apiRequest, { apiSuccess } from 'utils/api';

export default function* saga() {
  yield null;
}
