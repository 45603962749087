import React, { useCallback, useEffect, useMemo } from 'react';

// Material
import { withStyles } from '@mui/styles';

// Project
import { composedComponent } from 'utils/functions';
import { Typography } from '@mui/material';
import useStep from 'utils/hooks/useStep';
import { getMarginPerUnit, getPrice, getTotalMargin, getTotalSale } from 'utils/quoteCalculations';
import { sumByKey } from 'utils/math';
import { getDollarKardex, getDollarZcot } from 'utils/currencyHelpers';
// Hooks
import useCurrency from 'utils/hooks/useCurrency';
// Saga
import { quoteActions } from './reducer';
import saga from './saga';

// Components
import Form from './components/Form';
import QuoteStepper from './components/QuoteStepper';
import Preview from './components/Preview';
import FinalStep from './components/FinalStep';

// Utils

// Styles
import styles from './styles';

const Quote = function (props) {
  const { user, objects, classes, controls, actions, step, linesHasChanged, newQuoteId } = props;

  const dollarKardex = getDollarKardex(objects.currencies);
  const dollarZcot = getDollarZcot(objects.currencies);
  const { currencyCode, setCurrencyCode, convertKardexValue, convertZcotValue } = useCurrency({
    dollarKardex,
    dollarZcot,
  });

  useEffect(() => {
    switch (currencyCode) {
      case 'USD':
        actions.changeControls2('currencyId', '1');
        break;
      case 'CLP':
        actions.changeControls2('currencyId', '4');
        break;
      default:
        actions.changeControls2('currencyId', '1');
        break;
    }
  }, [currencyCode, actions]);

  useEffect(() => {
    actions.changeControls2('sender', user.user.name || '');
    actions.changeControls2('email', user.user.email || '');
    actions.changeControls2('phone', user.user.phone || '');
  }, []);

  const calculatedValues = useMemo(() => {
    const calculations = controls.materialLines.map((row) => ({
      price: getPrice(row?.projectedCost, row?.mgPerc),
      marginPerUnit: getMarginPerUnit(row?.kardex, row?.projectedCost, row?.mgPerc),
      totalMargin: getTotalMargin(row?.projectedCost, row?.mgPerc, row?.volumeKg),
      totalSale: getTotalSale(row?.projectedCost, row?.mgPerc, row?.volumeKg),
    }));
    const totals = {
      // price: sumByKey(calculations, 'price'),
      totalMargin: sumByKey(calculations, 'totalMargin'),
      totalSale: sumByKey(calculations, 'totalSale'),
    };
    return { calculations, totals };
  }, [controls.materialLines]);

  const changeStep = (value) => {
    actions.changeState({ step: value });
  };

  const resetForm = useCallback(() => {
    actions.reset();
  }, [actions]);

  const [setPrevStep, setNextStep] = useStep(step, { steps: 3 }, changeStep);

  const handleRemoveMaterial = (index) => {
    const lines = controls.materialLines;
    const newLines = lines.filter((line, idx) => idx !== index);
    actions.changeControls({ materialLines: newLines });
    actions.changeState({ linesHasChanged: true });
  };

  const handleChange = useCallback(
    (key, value) => {
      actions.changeControls2(key, value);
    },
    [actions],
  );

  const processedQuoteData = useMemo(() => {
    const mergedLines = controls.materialLines.map((row, idx) => {
      const { price, totalMargin, totalSale } = calculatedValues.calculations[idx];
      return {
        ...row,
        kardex: convertKardexValue(row?.kardex),
        projectedCost: convertZcotValue(row?.projectedCost),
        zcot: convertZcotValue(row?.zcot),
        calculatedPrice: convertKardexValue(row?.calculatedPrice),
        // price: convertKardexValue(price), // this values is the worng one, but before it was being used
        price: convertZcotValue(price),
        totalMargin: convertKardexValue(totalMargin),
        totalSale: convertKardexValue(totalSale),
      };
    });
    const result = {
      ...controls,
      materialLines: mergedLines,
    };
    return result;
  }, [controls, calculatedValues]);

  const handleSubmitQuote = useCallback(() => {
    const newData = processedQuoteData;
    actions.submitQuote(newData);
  }, [processedQuoteData, actions]);

  return (
    <>
      <Typography variant="h3" mb="30px">
        Resumen
      </Typography>
      <QuoteStepper step={step} />
      {step === 0 && (
        <Form
          linesHasChanged={linesHasChanged}
          controls={controls}
          actions={actions}
          setNextStep={setNextStep}
          classes={classes}
          calculatedValues={calculatedValues}
          handleChange={handleChange}
          handleRemoveMaterial={handleRemoveMaterial}
          currencyCode={currencyCode}
          setCurrencyCode={setCurrencyCode}
          convertKardexValue={convertKardexValue}
          convertZcotValue={convertZcotValue}
        />
      )}
      {step === 1 && (
        <Preview
          setPrevStep={setPrevStep}
          setNextStep={setNextStep}
          actions={actions}
          calculatedValues={calculatedValues}
          data={processedQuoteData}
          handleSubmitQuote={handleSubmitQuote}
        />
      )}
      {step === 2 && (
        <FinalStep
          setPrevStep={setPrevStep}
          resetForm={resetForm}
          actions={actions}
          newQuoteId={newQuoteId}
        />
      )}
    </>
  );
};

export default composedComponent(Quote, saga, {
  saga: 'sagaQuote',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'quote.controls',
    'quote.invalidControls',
    'quote.step',
    'quote.linesHasChanged',
    'quote.newQuoteId',
  ],
  actions: [quoteActions],
});
