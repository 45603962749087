import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  root: {
    background: 'white',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  paper: {
    outline: 'none',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    fontFamily: 'Montserrat',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '450px',
    },
  },
  ...commonStyles,
});

export default styles;
