import { createStore, compose, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';

// project
// import { clone } from '../utils/functions';
import enhancers, { sagaMiddleware } from './middleware';

// reducers
import app from '../commons/reducer';
import login from '../screens/Login/reducer';
import home from '../screens/Home/reducer';
import files from '../screens/Upload/reducer/uploadFile.reducer';
import recoveryPassword from '../screens/RecoveryPassword/reducer';
import todo from '../screens/Todo/reducer';
import formulaCalculation from '../screens/FormulaCalculation/reducer';
import costs from '../screens/Costs/reducer';
import historic from '../screens/Historic/reducer';
import quote from '../screens/Quote/reducer';
import maintainers from '../screens/Maintainers/reducer';

export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  // create reducer

  const initState = {
    home,
    login,
    app,
    files,
    recoveryPassword,
    todo,
    formulaCalculation,
    costs,
    historic,
    quote,
    maintainers,
  };
  const appReducer = combineReducers(initState);

  const createReducer = () => (state, action) => {
    if (action.type === 'app/RESET') {
      // eslint-disable-next-line no-param-reassign
      state = { app: state.app };
    }
    return appReducer(state, action);
  };

  const pReducer = persistReducer(persistConfig, createReducer());

  const store = createStore(
    pReducer,
    initialState,
    compose(
      enhancers,
      /* eslint-disable no-underscore-dangle */
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
      /* eslint-enable */
    ),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedSagas = {}; // Saga registry

  return store;
}
