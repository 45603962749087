import camelize from 'camelcase-keys-deep';

export default class Material {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  cost() {
    return this.cMaterial ? this.env().Cost.findBy('material_id', this.cMaterial) : null;
  }

  variableCosts() {
    // console.log('VARIABLE COSTS LOG');
    return this.cMaterial ? this.env().VariableCost.findAllBy('material_id', this.cMaterial) : [];
  }
}
