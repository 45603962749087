import React from 'react';
import { CircularProgress, Fab } from '@mui/material';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { ReactReduxContext } from 'config/configureStore';
import { selectState } from 'utils/reduxActions';

const FabWithLoader = function (props) {
  const { loaders, buttonId, color, variant, disabled, component, children } = props;
  const loading = loaders[buttonId];
  return (
    <Fab
      // {...props}
      component={component}
      color={color || 'primary'}
      variant={variant || 'round'}
      disabled={disabled || loading}>
      {loading ? <CircularProgress size={15} /> : children}
    </Fab>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withConnect = connect(selectState('app.loaders'), null, null, { context: ReactReduxContext });

export default compose(withConnect)(FabWithLoader);
