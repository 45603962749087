import React, { useCallback } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// Icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Utils
import { downloadFormula } from 'utils/xlsx';
import { simulateEvent, truthty } from 'utils/functions';
import CustomMenu from 'commons/components/CustomMenu';
import SearchMaterialByName from 'commons/components/SearchMaterialByName';

const FormulaMenu = function (props) {
  const {
    classes,
    controls,
    code,
    // startDate,
    // endDate,
    onChange,
    // handleChangeDate,
    handleCalculate,
    handleToggleModal,
    // handleToggleAddMaterialModal,
    // handleToggleEditCurrencies,
    errors,
    state,
  } = props;
  const { loading } = controls;

  const handleChangeMaterialName = useCallback(
    (value) => {
      // actions.changeControlsByKey('code', value);
      onChange(simulateEvent('code', 'string', value));
    },
    [onChange],
  );

  return (
    <form onSubmit={handleCalculate}>
      {/* Columna completa */}
      <Grid container mt="20px" mb="30px" spacing={1} justifyContent="space-between">
        {/* Lado izq */}
        <Grid item container className={`${classes.flex1} ${classes.calcForm}`} wrap="nowrap">
          {/* Campos de texto */}
          <Grid container spacing={1} wrap="nowrap">
            <Grid item sm={4}>
              <TextField
                id="code"
                label="Código material"
                variant="outlined"
                disabled={loading}
                value={code}
                onChange={onChange}
                sx={{ mr: '10px' }}
                error={truthty(errors.code)}
                helperText={errors.code ? errors?.code[0] : null}
                inputProps={{ maxLength: 8 }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <SearchMaterialByName materialId={state.code} callback={handleChangeMaterialName} />
            </Grid>
            {/* <Grid item sm={4}>
              <LocalizationProvider dateAdapter={DateAdapter} locale="esES">
                <DesktopDatePicker
                  label="Fecha inicial"
                  inputFormat="DD/MM/yyyy"
                  value={startDate}
                  onChange={(date) => handleChangeDate(date, 'startDate')}
                  clearable
                  InputProps={{ id: 'startDate', className: classes.mr10 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="startDate"
                      error={truthty(errors.startDate)}
                      fullWidth
                      helperText={errors.startDate ? errors?.startDate[0] : null}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={4}>
              <LocalizationProvider dateAdapter={DateAdapter} locale="esES">
                <DesktopDatePicker
                  label="Fecha final"
                  inputFormat="DD/MM/yyyy"
                  value={endDate}
                  onChange={(date) => handleChangeDate(date, 'endDate')}
                  InputProps={{
                    id: 'endDate',
                    className: classes.mr10,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="endDate"
                      error={truthty(errors.endDate)}
                      fullWidth
                      helperText={errors.endDate ? errors?.endDate[0] : null}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid> */}

            <Grid item>
              <ButtonWithLoader
                type="submit"
                buttonId="calculate-formula"
                variant="contained"
                color="primary"
                className={classes.button}>
                Calcular
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            {truthty(controls.materials) && truthty(controls.mainMaterial) && (
              <Button
                variant="outlined"
                onClick={() => downloadFormula(controls.mainMaterial, controls.materials)}
                startIcon={<FileDownloadIcon />}>
                Descargar
              </Button>
            )}
            <CustomMenu sx={{ ml: '10px', height: '55px' }}>
              {(close) => [
                <CustomMenu.MenuItem key={1} onClick={() => close(handleToggleModal)}>
                  <FileUploadIcon />
                  Cargar fórmula
                </CustomMenu.MenuItem>,
                // <CustomMenu.MenuItem key={2} onClick={() => close(handleToggleAddMaterialModal)}>
                //   <AddIcon />
                //   Agregar material
                // </CustomMenu.MenuItem>,
              ]}
            </CustomMenu>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormulaMenu;
