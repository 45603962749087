// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React from 'react';
import { ReactReduxContext } from 'config/configureStore';
import { clone, falsy } from 'utils/functions';

const connectSaga =
  ({ key, saga }) =>
  (WrappedComponent) =>
  // eslint-disable-next-line react/function-component-definition
  (props) =>
    (
      <ReactReduxContext.Consumer>
        {(value) => {
          // Inject the new saga
          if (falsy(value.store.injectedSagas[key])) {
            // eslint-disable-next-line no-param-reassign
            value.store.injectedSagas = clone(clone.OBJECT, value.store.injectedSagas, {
              [key]: value.store.runSaga(saga),
            });
          }

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} />;
        }}
      </ReactReduxContext.Consumer>
    );

export default connectSaga;
