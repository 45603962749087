export const CURRENCIES = {
  USD: 1,
  USD_KARDEX: 2,
  USD_ZCOT: 3,
};

export const QUOTE_STATE = {
  PENDING: 1,
  AWARDED: 2, // Adjudicado.
  LOST: 3,
};

export const lsParams = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};
