import React from 'react';
import { Grid, Typography } from '@mui/material';
import ModalLayout from 'commons/components/ModalLayout';
import { getQuoteStateLabel } from 'utils/quoteCalculations';
import { lsParams } from 'utils/enums';

const HistoricViewDetailsModal = function (props) {
  const { open, toggleModalFnc, data } = props;
  const {
    name,
    cMaterial,
    quoteId,
    cost,
    margin,
    details,
    subtotal,
    // quoteState,
    quoteMaterialState,
  } = data;
  return (
    <ModalLayout
      width="800px"
      maxWidth="800px"
      minHeight="400px"
      open={open}
      toggleModalFnc={toggleModalFnc}>
      <Grid container mb="40px">
        <Typography variant="h4">{`${cMaterial} - ${name}`}</Typography>
      </Grid>

      <Grid container mb="20px">
        <Grid item sm={3}>
          <Typography fontWeight="bold">Id Cotización:</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>{quoteId}</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography fontWeight="bold">Margen:</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>{margin}%</Typography>
        </Grid>
      </Grid>
      <Grid container mb="20px">
        <Grid item sm={3}>
          <Typography fontWeight="bold">Costo:</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>{cost ? cost.toLocaleString('es-CL', lsParams) : '-'}</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography fontWeight="bold">Total Venta:</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography>{subtotal ? subtotal?.toLocaleString('es-CL', lsParams) : '-'}</Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={1} mb="20px">
        <Grid item>
          <Typography fontWeight="bold" variant="h6">
            Adjudicado:
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{getQuoteStateLabel(quoteMaterialState)}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography fontWeight="bold" variant="h6">
            Comentarios
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{`${details}` || '-'}</Typography>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

export default HistoricViewDetailsModal;
