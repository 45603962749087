import React, { useEffect } from 'react';
import ModalLayout from 'commons/components/ModalLayout';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { valueHasData } from 'utils/validationsHelper';
import { useForm } from 'utils/hooks/useForm';
import { simulateEvent } from 'utils/functions';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import NumberField from 'commons/components/NumberField';
import { formatDateWithHour } from 'utils/dateHelpers';
import colors from 'utils/colors';

const MaintainersMaterialFormModal = function (props) {
  const { open, toggleModalFnc, data, actions, handleSearch } = props;
  const { id, code, name, umb, cost, deleted, updatedAt } = data;

  const handleChange = (key, value) => {
    actions.changeMaterialFormData(key, value);
  };

  const { onChange, onSubmit, errors, resetErrors } = useForm(data, null, {
    onChange: handleChange,
    validations: {
      name: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un nombre',
        },
      ],
      umb: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar la unidad de medida base',
        },
      ],
      cost: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar el costo',
        },
      ],
    },
  });

  useEffect(() => {
    if (!open) resetErrors();
  }, [open, resetErrors]);

  const handleChangeUmb = (e, newValue) => onChange(simulateEvent('umb', 'string', newValue));

  const handleChangeDeleted = (e) => {
    const { value } = e.target;
    onChange(simulateEvent('deleted', 'string', value));
  };

  const handleCreateMaterial = () => {
    onSubmit(() => actions.createMaterial(data, handleSearch));
  };
  const handleUpdateMaterial = () => {
    onSubmit(() => actions.updateMaterial(data, handleSearch));
  };

  return (
    <ModalLayout
      width="800px"
      maxWidth="800px"
      minHeight="400px"
      open={open}
      toggleModalFnc={toggleModalFnc}>
      <Grid container flexDirection="column" justifyContent="space-between" height="400px">
        <Grid item sx={{ flex: 1 }}>
          <Grid container>
            <Typography variant="h4">{`${id ? 'Editar' : 'Crear nuevo'} material ${
              code || ''
            }`}</Typography>
          </Grid>
          {id && (
            <Grid container>
              <Typography sx={{ color: colors.grey600, fontStyle: 'italic', fontSize: '14px' }}>
                Última modificación: {formatDateWithHour(updatedAt)}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} mt="40px" mb="16px">
            <Grid item xs={12} md={6}>
              <TextField
                id="name"
                label="Nombre"
                variant="outlined"
                value={name}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={errors.name?.length > 0}
                helperText={errors.name ? errors?.name[0] : null}
                fullWidth
                // disabled={!apiMsg}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                disablePortal
                id="umb"
                disabled={valueHasData(id)}
                options={['KG', 'G', 'L', 'ST']}
                // sx={{ width: 230 }}
                // disabled={!apiMsg}
                value={umb}
                onChange={handleChangeUmb}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Unidad base *"
                    error={errors.umb?.length > 0}
                    helperText={errors.umb ? errors?.umb[0] : null}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb="16px">
            <Grid item xs={12} md={6}>
              <NumberField
                id="cost"
                label="Costo unitario USD"
                variant="outlined"
                value={cost}
                onChange={onChange}
                inputProps={{ maxLength: 10 }}
                error={errors.cost?.length > 0}
                helperText={errors.cost ? errors?.cost[0] : null}
                autoComplete="off"
                fullWidth
              />
            </Grid>
            {id && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    id="deleted"
                    name="deleted"
                    label="Estado"
                    value={deleted}
                    fullWidth
                    onChange={handleChangeDeleted}>
                    <MenuItem value="0">Vigente</MenuItem>
                    <MenuItem value="1">Bloqueado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            {id ? (
              <ButtonWithLoader
                buttonId="update-material"
                size="large"
                fullWidth
                onClick={handleUpdateMaterial}>
                Editar material
              </ButtonWithLoader>
            ) : (
              <ButtonWithLoader
                buttonId="create-material"
                size="large"
                fullWidth
                onClick={handleCreateMaterial}>
                Crear material
              </ButtonWithLoader>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

export default MaintainersMaterialFormModal;
