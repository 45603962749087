import React from 'react';
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateSlash } from 'utils/dateHelpers';
import { Edit, FileDownload, Visibility } from '@mui/icons-material';
import { getQuoteStateLabel } from 'utils/quoteCalculations';
import useQuotePdf from 'utils/hooks/useQuotePdf';

const HistoricTable = function (props) {
  const {
    rows,
    classes,
    handleChangePage,
    handleChangeRowsPerPage,
    pagination,
    count,
    loading,
    actions,
  } = props;
  const { rowsPerPage, page } = pagination;
  const { getPdf } = useQuotePdf();

  const handleToggleViewDetailsModal = (data) => {
    actions.changeControls({ quoteDetails: data });
    actions.changeModals('viewDetails');
  };
  const handleToggleEditDetailsModal = (data) => {
    actions.changeControls({ quoteDetails: data });
    actions.changeModals('editDetails');
  };
  const lsParams = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  return (
    <>
      <Grid container mb="20px">
        <Typography variant="h4">Histórico</Typography>
      </Grid>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {loading && (
          <>
            <Backdrop
              sx={{ position: 'absolute', zIndex: 1000, background: 'white' }}
              style={{ opacity: 0.7 }}
              open>
              <CircularProgress color="primary" />
            </Backdrop>
            <LinearProgress style={{ zIndex: 1001 }} />
          </>
        )}
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {/* <TableCell>id</TableCell> */}
              {/* <TableCell>ATN</TableCell> */}
              <TableCell>id cot.</TableCell>
              <TableCell>Generador cot.</TableCell>
              <TableCell>cod. mat.</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Costo</TableCell>
              <TableCell>MG%</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Volumen</TableCell>
              <TableCell>Total Venta</TableCell>
              <TableCell>Adjudicado</TableCell>
              <TableCell align="right">Porc. Adj.</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 && rows).map((row) => {
              const name = row?.name;
              return (
                <TableRow key={row.id}>
                  <TableCell align="left">{row?.quoteId}</TableCell>
                  <TableCell align="left">{row?.quote()?.user()?.name}</TableCell>
                  <TableCell align="left">{row?.cMaterial}</TableCell>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="right">
                    {row?.cost?.toLocaleString('es-CL', lsParams)}
                  </TableCell>
                  <TableCell align="right">{row?.margin}%</TableCell>
                  <TableCell align="right">
                    {row?.price?.toLocaleString('es-CL', lsParams)}
                  </TableCell>
                  <TableCell align="right">{row?.volume}</TableCell>
                  <TableCell align="right">{`${row?.subtotal?.toLocaleString('es-CL', lsParams)} ${
                    row?.quote()?.currency()?.code
                  }`}</TableCell>
                  <TableCell align="left">{getQuoteStateLabel(row?.state || 1)}</TableCell>
                  <TableCell align="right">{row?.adjudicationPerc || 0}%</TableCell>
                  <TableCell align="left">{row.quote()?.clientName}</TableCell>
                  <TableCell align="left">{formatDateSlash(row.createdAt)}</TableCell>
                  <TableCell align="left" width="160px">
                    <IconButton
                      onClick={() =>
                        handleToggleViewDetailsModal({
                          quoteId: row?.quote()?.id,
                          name,
                          cMaterial: row?.cMaterial,
                          cost: row?.cost,
                          margin: row?.margin,
                          subtotal: row?.subtotal,
                          details: row?.quote()?.details,
                          quoteState: row?.quote()?.state,
                          quoteMaterialState: row?.state,
                        })
                      }>
                      <Visibility />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleToggleEditDetailsModal({
                          quoteId: row?.quote()?.id,
                          quoteMaterialId: row?.id,
                          name,
                          cMaterial: row?.cMaterial,
                          details: row?.quote()?.details,
                          quoteMaterialState: row?.state,
                          adjudicator: row?.adjudicator || '',
                          adjudicationPerc: row?.adjudicationPerc,
                        })
                      }>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => getPdf(row?.quote()?.id)}>
                      <FileDownload />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={13}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count: total }) => `${from}-${to} de ${total}`}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default HistoricTable;
