import React, { useCallback } from 'react';
// Material
import { Grid, TextField } from '@mui/material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// Icons
// Utils
import { useForm } from 'utils/hooks/useForm';
import { isNumber } from 'utils/math';
import { numberBetween, valueHasData } from 'utils/validationsHelper';
import SearchMaterialByName from 'commons/components/SearchMaterialByName';

const MaintainersInternalMaterialFilters = function (props) {
  const { controls, classes, handleChange, handleSearch, loading } = props;
  const { materialId } = controls.materialSearch;

  const { onChange, onSubmit, errors } = useForm(controls.materialSearch, null, {
    onChange: handleChange,
    validations: {
      materialId: [
        {
          check: (value) => !valueHasData(value) || isNumber(value),
          message: 'El código debe ser numérico ',
        },
        {
          check: (value) => !valueHasData(value) || numberBetween(value, 10_000_000, 19_999_999),
          message: 'El código debe ser de 8 dígitos empezando en 1',
        },
      ],
    },
  });

  const handleFilter = (e) => {
    e.preventDefault();
    onSubmit(() => {
      handleSearch();
    });
  };

  const handleChangeMaterialName = useCallback(
    (value) => {
      handleChange('materialId', value);
    },
    [handleChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <form onSubmit={handleFilter}>
          <Grid container spacing={1} wrap="nowrap" mb="20px">
            <Grid item>
              <TextField
                id="materialId"
                label="Filtrar por código material"
                variant="outlined"
                disabled={loading}
                value={materialId}
                onChange={onChange}
                error={errors.materialId?.length > 0}
                helperText={errors.materialId ? errors?.materialId[0] : null}
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
            <Grid item mb="20px">
              <SearchMaterialByName
                materialId={controls.materialSearch.materialId}
                callback={handleChangeMaterialName}
                internal
                sx={{ width: 260 }}
              />
            </Grid>
            <Grid item>
              <ButtonWithLoader
                type="submit"
                buttonId="search-quotes"
                variant="contained"
                color="primary"
                className={classes.button}>
                Filtrar
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default MaintainersInternalMaterialFilters;
