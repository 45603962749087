// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  container: {
    height: 'calc(100vh - 120px)',
  },
  logo: {
    maxWidth: '50%',
  },
});

export default styles;
