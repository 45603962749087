import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ModalLayout from 'commons/components/ModalLayout';
import { useForm } from 'utils/hooks/useForm';
import { valueHasData } from 'utils/validationsHelper';
import { simulateEvent, validateEmail } from 'utils/functions';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const MaintainersCreateOrUpdateUserModal = function (props) {
  const { open, toggleModalFnc, data, actions } = props;
  const { id, name, email, password, position, phone, type, species } = data;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const speciesOpts = [
    { id: 110, label: 'Broiler' },
    { id: 120, label: 'Ponedoras' },
    { id: 130, label: 'Pavos' },
    { id: 200, label: 'Agrosuper' },
    { id: 210, label: 'Cerdos' },
    { id: 310, label: 'Ganado Leche' },
    { id: 320, label: 'Ganado Carne' },
    { id: 410, label: 'Peces' },
    { id: 510, label: 'Perros' },
    { id: 520, label: 'Gatos' },
    { id: 997, label: 'FL' },
    { id: 998, label: 'Otros' },
    { id: 999, label: 'Sin Especie' },
  ];

  const handleChange = (key, value) => {
    let str = value;
    if (key === 'email') {
      str = value.replace(/ /g, '');
    }
    actions.changeUserFormData(key, str);
  };

  const { onChange, onSubmit, errors, resetErrors } = useForm(data, null, {
    onChange: handleChange,
    validations: {
      name: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un nombre',
        },
      ],
      email: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un email',
        },
        {
          check: (value) => validateEmail(value),
          message: 'Email no válido',
        },
      ],
      password: [
        {
          check: (value) => data.id || valueHasData(value),
          message: 'Debe ingresar una contraseña',
        },
      ],
      position: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un cargo',
        },
      ],
      species: [
        {
          check: (value) => data.type === 'admin' || value.length > 0,
          message: 'Debe agregar al menos una especie',
        },
      ],
    },
  });

  useEffect(() => {
    if (!open) resetErrors();
  }, [open, resetErrors]);

  const handleChangeType = (e) => {
    const { value } = e.target;
    if (value === 'admin') {
      onChange(simulateEvent('species', 'object', []));
    }
    onChange(simulateEvent('type', 'string', e.target.value));
  };

  const handleChangeSpecies = (e) => {
    const { value } = e.target;
    onChange(simulateEvent('species', 'object', value));
  };

  const handleCreateUser = () => {
    onSubmit(() => actions.createUser(data));
  };
  const handleUpdateUser = () => {
    onSubmit(() => actions.updateUser(data));
  };

  return (
    <ModalLayout
      width="800px"
      maxWidth="800px"
      minHeight="400px"
      open={open}
      toggleModalFnc={toggleModalFnc}>
      <Grid container mb="40px">
        <Typography variant="h4">{`${id ? 'Editar' : 'Crear nuevo'} usuario`}</Typography>
      </Grid>
      <Grid container spacing={2} mb="16px">
        <Grid item xs={12} md={6}>
          <TextField
            id="name"
            label="Nombre"
            variant="outlined"
            value={name}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.name?.length > 0}
            helperText={errors.name ? errors?.name[0] : null}
            fullWidth
            // disabled={!apiMsg}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.email?.length > 0}
            helperText={errors.email ? errors?.email[0] : null}
            fullWidth
            // disabled={!apiMsg}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb="16px">
        <Grid item xs={12} md={6}>
          <TextField
            id="position"
            label="Cargo"
            variant="outlined"
            value={position}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.position?.length > 0}
            helperText={errors.position ? errors?.position[0] : null}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="phone"
            label="Teléfono"
            variant="outlined"
            value={phone}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.phone?.length > 0}
            helperText={errors.phone ? errors?.phone[0] : null}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb="16px">
        <Grid item xs={12} md={6}>
          <TextField
            id="password"
            label={id ? 'Contraseña (no rellenar si no la desea cambiar)' : 'Contraseña'}
            variant="outlined"
            value={password}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            error={errors.password?.length > 0}
            helperText={errors.password ? errors?.password[0] : null}
            autoComplete="off"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Rol</InputLabel>
            <Select
              id="type"
              name="type"
              label="Rol"
              value={type}
              fullWidth
              onChange={handleChangeType}>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">Usuario</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {type !== 'admin' && (
        <Grid container spacing={2} mb="36px">
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Especies</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={species}
                fullWidth
                error={errors.species?.length > 0}
                onChange={handleChangeSpecies}
                input={<OutlinedInput label="Especies" />}
                renderValue={(selected) =>
                  selected
                    .map((sel) => speciesOpts.find((opts) => opts.id === sel).label)
                    .join(', ')
                }
                MenuProps={MenuProps}>
                {speciesOpts.map((specie) => (
                  <MenuItem key={specie.id} value={specie.id}>
                    <Checkbox checked={species?.indexOf(specie.id) > -1} />
                    <ListItemText primary={specie.label} />
                  </MenuItem>
                ))}
              </Select>
              {errors.species?.length > 0 && (
                <FormHelperText error>{errors?.species[0]}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container>
        {id ? (
          <ButtonWithLoader
            buttonId="create-user"
            size="large"
            fullWidth
            onClick={handleUpdateUser}>
            Editar usuario
          </ButtonWithLoader>
        ) : (
          <ButtonWithLoader
            buttonId="update-user"
            size="large"
            fullWidth
            onClick={handleCreateUser}>
            Crear usuario
          </ButtonWithLoader>
        )}
      </Grid>
    </ModalLayout>
  );
};

export default MaintainersCreateOrUpdateUserModal;
