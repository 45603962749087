import short from 'short-uuid';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { composedComponent } from 'utils/functions';
import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { todoActions } from './reducer';
import saga from './saga';
import styles from './styles';

const Todo = function (props) {
  const { actions } = props;
  const {
    controls: { todos },
  } = props;

  const [description, setDescription] = useState('');

  const [error, setError] = useState(false);

  useEffect(() => {
    actions.getTodos();
  }, []);

  const check = (todoId) => {
    actions.checkTodo(todoId);
  };

  const deleteTodo = (todoId) => {
    actions.destroyTodo(todoId);
  };

  const addTodo = () => {
    if (description === '') {
      setError(true);
      return;
    }
    actions.createTodo({ description });
    setDescription('');
  };

  return (
    <Grid container direction="column" spacing={3} alignItems="center" textAlign="center">
      <Grid item>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 2, width: '50ch' },
          }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            addTodo();
          }}
          autoComplete="off">
          <div>
            <TextField
              required
              id="outlined-required"
              label="Añadir tarea: "
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setError(false);
              }}
              error={error}
              placeholder="Descripción de tarea"
            />
          </div>
          <br />
          <Button variant="outlined" onClick={() => addTodo()}>
            Añadir
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body1" component="div">
          <strong>Lista de Tareas</strong>
        </Typography>
        <List sx={{ width: '100%' }}>
          {Object.entries(todos ?? [])
            .reduce((a, b) => [...a, b[1]], [])
            .map((todo) => {
              const labelId = `checkbox-list-label-${todo}`;

              return (
                <ListItem
                  alignItems="center"
                  key={short.generate()}
                  secondaryAction={
                    <IconButton
                      onClick={() => deleteTodo(todo.id)}
                      edge="end"
                      aria-label="comments">
                      <DeleteOutline />
                    </IconButton>
                  }
                  disablePadding>
                  <ListItemButton dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={todo.done}
                        tabIndex={-1}
                        disableRipple
                        onClick={() => check(todo.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`Line item ${todo.description}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Grid>
    </Grid>
  );
};

export default composedComponent(Todo, saga, {
  saga: 'sagaTodo',
  middleware: [withStyles(styles)],
  states: ['todo.controls'],
  actions: [todoActions],
});
