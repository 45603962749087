import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getLastMonthsLabels } from 'utils/dateHelpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CostsChart = function (props) {
  const { costs } = props;

  const options = {
    // responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Costos',
      },
    },
    maintainAspectRatio: true,
    resposive: true,
    aspectRatio: 3,
  };

  const labels = getLastMonthsLabels(10);
  const dataSets = costs.map((cost) => ({ ...cost, data: [...cost.data, cost.zcot] }));
  const data = {
    labels: [...labels, 'Zcot'],
    datasets: dataSets,
  };

  if (!costs.length) return <div />;

  return <Line options={options} data={data} />;
};

export default CostsChart;
