import React, { useCallback } from 'react';
import {
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
// Components
import { useForm } from 'utils/hooks/useForm';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import NumberField from 'commons/components/NumberField';
import SelectCurrency from 'commons/components/SelectCurrency';
import SearchMaterialByName from 'commons/components/SearchMaterialByName';
// Utils
import { numberBetween, valueHasData } from 'utils/validationsHelper';
import { simulateEvent } from 'utils/functions';
import { isNumber, sumByKey } from 'utils/math';

const TotalSum = React.memo(({ lines, keyValue, prefix = '' }) => (
  <Typography sx={{ fontWeight: 'bold' }}>
    {`${prefix}${sumByKey(lines, keyValue).toLocaleString('es-CL', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`}
  </Typography>
));

const MaterialLines = function (props) {
  const {
    controls,
    classes,
    actions,
    handleRemoveMaterial,
    onChangeMain,
    // linesHasChanged,
    calculatedValues,
    errorsMain,
    currencyCode,
    setCurrencyCode,
    convertKardexValue,
    convertZcotValue,
  } = props;

  const { code, materialLines } = controls;
  const { calculations } = calculatedValues;
  const lsParams = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  // Para actualizar el m % ppto.
  // useEffect(() => {
  //   if (!controls.materialLines.length || !linesHasChanged) return;
  //   actions.changeState({ linesHasChanged: false });
  //   const newLines = controls.materialLines.map((line) => ({
  //     ...line,
  //     mPercPpto: getMPercPpto(controls.materialLines, line.kardex),
  //   }));
  //   actions.changeControls2('materialLines', newLines);
  // }, [controls.materialLines, actions, getMPercPpto, linesHasChanged]);

  const handleChangeCode = useCallback(
    (key, val) => {
      actions.changeControls2(key, val);
    },
    [actions],
  );

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: handleChangeCode,
    validations: {
      code: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un código de material ',
        },
        {
          check: (value) => isNumber(value),
          message: 'El código debe ser numérico ',
        },
        {
          check: (value) =>
            numberBetween(value, 400_000, 499_999) || numberBetween(value, 40_000_000, 49_999_999),
          message: 'Solo se permiten códigos de 6 u 8 dígitos que empiecen en 4',
        },
        {
          check: (value) => materialLines.every((line) => line.m_code !== Number(value)),
          message: 'Ya existe el material en la lista',
        },
      ],
    },
  });

  const handleAddMaterial = useCallback(() => {
    onSubmit(() => actions.getMaterial({ code }));
  }, [actions, code]);

  const handleChangeMaterial = useCallback(
    (i, e) => {
      const { id, value } = e.target;
      const parsedValue = isNumber(value) ? Number(value.trim()) : value;
      const newArr = controls.materialLines.map((line, idx) =>
        idx === i ? { ...line, [id]: parsedValue } : line,
      );
      const obj = simulateEvent('materialLines', 'object', newArr);
      onChangeMain(obj);
    },
    [controls, onChangeMain, simulateEvent],
  );

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item sm={6}>
          <Grid container mb="20px" spacing={1} wrap="nowrap" justifyContent="flex-start">
            <Grid item className="">
              <TextField
                id="code"
                label="Código material"
                variant="outlined"
                value={controls.code}
                onChange={onChange}
                sx={{ width: '196px' }}
                error={errors.code?.length > 0}
                helperText={errors.code ? errors?.code[0] : null}
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
            <Grid item>
              <SearchMaterialByName
                materialId={controls.code}
                callback={(val) => handleChangeCode('code', val)}
                sx={{ width: '196px' }}
              />
            </Grid>
            <Grid item className={classes.fullHeight}>
              <ButtonWithLoader
                buttonId="get-material"
                className={classes.button}
                onClick={() => handleAddMaterial(controls.code)}>
                Agregar
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid container justifyContent="flex-end">
            <SelectCurrency currencyCode={currencyCode} setCurrencyCode={setCurrencyCode} />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Detalle de materiales">
          <TableHead>
            <TableRow>
              <TableCell align="left">Código</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="right">Dosis</TableCell>
              <TableCell align="right">{`Costo Kardex ${currencyCode}`}</TableCell>
              <TableCell align="right">{`Costo Proy. ${currencyCode}`}</TableCell>
              <TableCell align="right">dif. Costo</TableCell>
              <TableCell align="right">UMB</TableCell>
              <TableCell align="right">MG% (val. entero)</TableCell>
              <TableCell align="right">MG x unid.</TableCell>
              <TableCell align="right">{`Precio ${currencyCode}`}</TableCell>
              <TableCell align="right">Volumen en Kg</TableCell>
              <TableCell align="right">{`Total margen ${currencyCode}`}</TableCell>
              <TableCell align="right">{`Total venta ${currencyCode}`}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(controls.materialLines).map((row, idx) => (
              <TableRow
                className={classes.zebra}
                key={`${row.m_code}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row?.m_code}</TableCell>
                <TableCell align="left">
                  <TextField
                    id="name"
                    label=""
                    variant="outlined"
                    value={row?.name}
                    onChange={(e) => handleChangeMaterial(idx, e)}
                    sx={{ minWidth: '100px' }}
                    error={!row?.name}
                  />
                </TableCell>
                <TableCell align="right">
                  {row?.dosis ? row?.dosis.toLocaleString('es-CL', lsParams) : 0}
                </TableCell>
                <TableCell align="right">
                  {convertKardexValue(row?.kardex).toLocaleString('es-CL', lsParams)}
                </TableCell>
                <TableCell align="right">
                  {convertZcotValue(row?.projectedCost).toLocaleString('es-CL', lsParams)}
                </TableCell>
                <TableCell align="right">
                  {((row?.difCost || 0) * 100).toLocaleString('es-CL', lsParams)}%
                </TableCell>
                {/* <TableCell align="right">{row?.mPercPpto.toFixed(2)}%</TableCell> */}
                <TableCell align="right">{row?.umb || '--'}</TableCell>
                <TableCell align="right">
                  <NumberField
                    id="mgPerc"
                    label=""
                    variant="outlined"
                    value={row?.mgPerc}
                    onChange={(e) => handleChangeMaterial(idx, e)}
                    sx={{ width: '88px' }}
                    error={!row?.mgPerc}
                    inputProps={{ maxLength: 3 }}
                    symbol="%"
                    symbolPosition="end"
                    fullWidth
                    maxValue={9999}
                  />
                </TableCell>
                <TableCell align="right">{`$${convertZcotValue(
                  calculations[idx]?.marginPerUnit,
                ).toLocaleString('es-CL', lsParams)}`}</TableCell>
                <TableCell align="right">{`$${convertZcotValue(
                  calculations[idx]?.price,
                ).toLocaleString('es-CL', lsParams)}`}</TableCell>
                <TableCell align="right">
                  <NumberField
                    id="volumeKg"
                    label=""
                    variant="outlined"
                    value={row?.volumeKg}
                    onChange={(e) => handleChangeMaterial(idx, e)}
                    sx={{ width: '80px' }}
                    error={!row?.volumeKg}
                    fullWidth
                  />
                </TableCell>
                <TableCell align="right">{`$${convertZcotValue(
                  calculatedValues?.calculations[idx]?.totalMargin,
                ).toLocaleString('es-CL', lsParams)}`}</TableCell>
                <TableCell align="right">{`$${convertZcotValue(
                  calculatedValues?.calculations[idx]?.totalSale,
                ).toLocaleString('es-CL', lsParams)}`}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleRemoveMaterial(idx)}>
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow className={classes.stickyRow} sx={{ bottom: '0px' }}>
              <TableCell rowSpan={1} />
              <TableCell colSpan={10} align="right">
                <TotalSum lines={controls.materialLines} keyValue="volumeKg" />
              </TableCell>
              <TableCell align="right">
                <Typography>{`$${convertZcotValue(
                  sumByKey(calculatedValues.calculations, 'totalMargin'),
                ).toLocaleString('es-CL', lsParams)}`}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{`$${convertZcotValue(
                  sumByKey(calculatedValues.calculations, 'totalSale'),
                ).toLocaleString('es-CL', lsParams)}`}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {errorsMain.materialLines?.length > 0 && (
        <Grid item>
          <FormHelperText error>{errorsMain.materialLines[0]}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

export default MaterialLines;
