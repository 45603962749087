import React from 'react';
import { Fade, Grid, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Styles
import styles from './styles';

const useStyles = makeStyles(styles);

const ModalLayout = function (props) {
  const { open, children, toggleModalFnc, width, maxWidth, overflowY, minHeight, position } = props;
  const classes = useStyles();
  return (
    <Modal open={open} className={classes.modal} onClose={toggleModalFnc}>
      <Fade in={open}>
        <Grid
          className={classes.paper}
          sx={{
            width: width || 'initial',
            maxWidth: maxWidth || 'initial',
            overflowY: overflowY || 'none',
            minHeight: minHeight || 'initial',
            position: position || 'initial',
          }}>
          {children}
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ModalLayout;
