import React from 'react';
import ModalLayout from '../ModalLayout';

const EditCurrenciesModal = function (props) {
  const { open, toggleModalFnc } = props;
  return (
    <ModalLayout open={open} toggleModalFnc={toggleModalFnc} width="500px">
      Edit currencies!
    </ModalLayout>
  );
};

export default EditCurrenciesModal;
