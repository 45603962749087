import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import useQuotePdf from 'utils/hooks/useQuotePdf';

const Preview = function (props) {
  const { setPrevStep, handleSubmitQuote, data } = props;

  const { pdf, getTempPdf } = useQuotePdf();

  useEffect(() => {
    getTempPdf(data);
  }, []);

  return (
    <>
      <Grid container mb="10px">
        <Grid item xs={12}>
          <Typography variant="h4">Previsualización</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body">
            Este archivo es temporal. Puede volver y modificar la cotización.
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb="20px">
        <iframe
          id="fred"
          title="PDF in an i-Frame"
          src={pdf}
          frameBorder="1"
          scrolling="auto"
          height="1100"
          width="100%"
          style={{ height: '500px' }}
        />
      </Grid>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={setPrevStep}>
            Volver al resumen
          </Button>
        </Grid>
        <Grid item>
          <ButtonWithLoader buttonId="submit-quote" variant="contained" onClick={handleSubmitQuote}>
            Generar cotización
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  );
};

export default Preview;
