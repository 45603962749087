import React, { useCallback } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { formatDateWithHour } from 'utils/dateHelpers';
import colors from 'utils/colors';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import { useForm } from 'utils/hooks/useForm';
import { numberBetween, valueHasData } from 'utils/validationsHelper';
import { isNumber } from 'utils/math';

const MaintainersCurrencyForm = function (props) {
  const { controls, actions } = props;
  const { currencies } = controls;
  const { dollarKardex, dollarZcot, lastUpdate } = currencies;

  const handleChange = useCallback(
    (key, value) => {
      actions.changeCurrenciesData(key, value);
    },
    [actions],
  );

  const { onChange, onSubmit, errors } = useForm(currencies, null, {
    onChange: handleChange,
    validations: {
      dollarKardex: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe haber un valor ',
        },
        {
          check: (value) => isNumber(value),
          message: 'Valor debe ser numérico ',
        },
        {
          check: (value) => numberBetween(value, 1, 2000_000_000),
          message: 'Rango incorrecto de valor ',
        },
      ],
      dollarZcot: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe haber un valor ',
        },
        {
          check: (value) => isNumber(value),
          message: 'Valor debe ser numérico ',
        },
        {
          check: (value) => numberBetween(value, 1, 2000_000_000),
          message: 'Rango incorrecto de valor ',
        },
      ],
    },
  });

  const handleSubmit = useCallback(() => {
    onSubmit(() => actions.updateCurrencies(currencies));
  }, [actions, currencies]);

  return (
    <Grid container>
      <Grid item sm={12} mb="20px">
        <Typography sx={{ color: colors.grey600, fontStyle: 'italic', fontSize: '14px' }}>
          Última modificación: {formatDateWithHour(lastUpdate)}
        </Typography>
      </Grid>
      <Grid item sm={12} mb="20px">
        <TextField
          id="dollarKardex"
          label="Dólar Kardex"
          variant="outlined"
          value={dollarKardex}
          onChange={onChange}
          sx={{ mr: '10px' }}
          error={errors.dollarKardex?.length > 0}
          helperText={errors.dollarKardex ? errors?.dollarKardex[0] : null}
        />
      </Grid>
      <Grid item sm={12} mb="20px">
        <TextField
          id="dollarZcot"
          label="Dólar ZCOT"
          variant="outlined"
          value={dollarZcot}
          onChange={onChange}
          sx={{ mr: '10px' }}
          error={errors.dollarZcot?.length > 0}
          helperText={errors.dollarZcot ? errors?.dollarZcot[0] : null}
        />
      </Grid>
      <ButtonWithLoader buttonId="update-currencies" onClick={handleSubmit}>
        Actualizar valores
      </ButtonWithLoader>
    </Grid>
  );
};

export default MaintainersCurrencyForm;
