import { takeLatest, spawn, put } from 'redux-saga/effects';
import { get, post } from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';

// Reducer
import { appActions } from 'commons/reducer';
import { QUOTE, quoteActions } from '../reducer';

function* getPdfFile() {
  yield takeLatest(QUOTE.GET_PDF_FILE, function* (action) {
    yield put(appActions.setLoader('get-pdf', true));
    yield controlledCall(
      get,
      `api/v1/quotes/pdf`,
      action.payload,
      quoteActions.getPdfFileSuccess,
      quoteActions.getPdfFileFail,
    );
    yield put(appActions.setLoader('get-pdf', false));
  });
}

function* getMaterial() {
  yield takeLatest(QUOTE.GET_MATERIAL, function* (action) {
    yield put(appActions.setLoader('get-material', true));
    const result = yield controlledCall(
      post,
      'api/v1/quotes/get-material/',
      action.data,
      quoteActions.getMaterialSuccess,
      // quoteActions.getMaterialFail
    );
    if (!result.error) {
      // yield put(appActions.setSuccess('Fórmula agregada'));
    }
    yield put(appActions.setLoader('get-material', false));
  });
}

function* submitQuote() {
  yield takeLatest(QUOTE.SUBMIT_QUOTE, function* (action) {
    yield put(appActions.setLoader('submit-quote', true));
    const result = yield controlledCall(
      post,
      'api/v1/quotes/create/',
      action.data,
      quoteActions.submitQuoteSuccess,
      // quoteActions.submitQuoteFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Cotización ingresada!'));
    }
    yield put(appActions.setLoader('submit-quote', false));
  });
}

export default function* root() {
  yield spawn(getMaterial);
  yield spawn(submitQuote);
  yield spawn(getPdfFile);
}
