const { useState, useCallback } = require('react');

const useCurrency = (currencies) => {
  const [currencyCode, setCurrencyCode] = useState('USD');
  const { dollarZcot, dollarKardex } = currencies;
  const lsParams = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const convertKardexValue = useCallback(
    (value = 0) => (currencyCode === 'USD' ? Number(value) : Number(value) * Number(dollarKardex)),
    [currencyCode, dollarKardex, lsParams],
  );

  const convertZcotValue = useCallback(
    (value = 0) => (currencyCode === 'USD' ? Number(value) : Number(value) * Number(dollarZcot)),
    [currencyCode, dollarZcot, lsParams],
  );

  return { currencyCode, setCurrencyCode, convertKardexValue, convertZcotValue };
};

export default useCurrency;
