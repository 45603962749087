import React from 'react';

// Material
import { Container } from '@mui/material';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LogoVertSvg from 'commons/assets/images/logo-vtq-vert-grande.svg';

// Project
import { composedComponent } from '../../utils/functions';
import styles from './styles';
import saga from './saga';

const Home = function (props) {
  const { classes } = props;

  return (
    <Container maxWidth={false}>
      <Grid container className={classes.container} justifyContent="center" alignItems="center">
        <img src={LogoVertSvg} alt="Veterquimica" className={classes.logo} />
      </Grid>
    </Container>
  );
};

export default composedComponent(Home, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
});
