import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// material
// import MuiAlert from '@mui/material/Alert';

// Project
import SideBar from 'commons/components/SideBar';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import { composedComponent } from '../../../utils/functions';
import saga from '../../saga';
import NavBar from '../../components/NavBar';
import { appActions } from '../../reducer';

// const Alert = function () {
//   // return <MuiAlert elevation={6} variant="filled" {...props} />;
//   return <MuiAlert elevation={6} variant="filled" />;
// };
// const Alert = React.forwardRef((props, ref) => (
//   <MuiAlert elevation={6} variant="filled" ref={ref} />
// ));

const Layout = function (props) {
  const { user, actions, children, drawerIsOpen } = props;
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    actions.setUser(null);
    actions.reset();
  };

  const toggleDrawer = () => {
    actions.toggleDrawer();
  };

  // const toggleError = () => {
  //   actions.toggleError();
  // };

  // const toggleSuccess = () => {
  //   actions.toggleSuccess();
  // };

  const handleItemClick = useCallback(
    (module) => () => {
      navigate(module);
      // props.actions.toggleDrawer();
    },
    [module],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar logOut={logOut} toggleDrawer={toggleDrawer} user={user} />
      <SideBar
        user={user}
        isOpen={drawerIsOpen}
        toggleDrawer={toggleDrawer}
        handleItemClick={handleItemClick}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default composedComponent(Layout, saga, {
  saga: 'sagaLayout',
  states: [
    'app.drawerIsOpen',
    'app.deviceError',
    'app.device',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
  ],
  actions: [appActions],
});
