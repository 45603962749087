import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  root: {
    marginBottom: '50px',
  },
  button: {
    height: '55px',
  },
  zebra: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(odd) td:nth-of-type(13)': { background: '#e1e9ef' },
    '&:nth-of-type(even) td:nth-of-type(13)': { background: '#ebf2f8' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  ...commonStyles,
});

export default styles;
