import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import SelectCurrency from 'commons/components/SelectCurrency';

const CardCustomItem = React.memo((itemProps) => (
  <Grid item xs={12} mb="10px">
    <Grid container justifyContent="space-between">
      <Grid item xs={3}>
        <Typography>{itemProps.label}:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{itemProps.value}</Typography>
      </Grid>
    </Grid>
  </Grid>
));

const FormulaResultsCard = function (props) {
  const { controls, currencyCode, setCurrencyCode } = props;
  return (
    <Grid container mb="20px" justifyContent="space-between">
      <Card sx={{ width: '400px', mr: '10px' }}>
        <CardContent>
          <Grid container>
            <CardCustomItem label="Código" value={controls.foundCode} />
            <CardCustomItem label="Nombre" value={controls.foundName} />
          </Grid>
        </CardContent>
      </Card>

      <Grid item>
        <SelectCurrency currencyCode={currencyCode} setCurrencyCode={setCurrencyCode} />
      </Grid>
    </Grid>
  );
};

export default FormulaResultsCard;
