import commonStyles from 'utils/commonStyles';

const styles = () => ({
  root: {
    marginBottom: '50px',
  },
  button: {
    height: '55px',
  },
  ...commonStyles,
});

export default styles;
