import React from 'react';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';

const CustomDatePicker = function (props) {
  const {
    id,
    label,
    value,
    error,
    onChange,
    className,
    inputFormat = 'DD/MM/yyyy',
    helperText,
  } = props;

  const handleChange = (date) => {
    const obj = {
      currentTarget: {
        id,
        type: 'MomentDate',
        value: date,
      },
      target: {
        id,
        type: 'MomentDate',
        value: date,
      },
      persist: () => {},
    };
    onChange(obj);
  };
  return (
    <DesktopDatePicker
      label={label}
      inputFormat={inputFormat}
      value={value}
      onChange={handleChange}
      clearable
      InputProps={{ id, className }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          id={id}
          error={error}
          fullWidth
          helperText={helperText}
          autoComplete="off"
        />
      )}
    />
  );
};

export default CustomDatePicker;
