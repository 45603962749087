import colors from 'utils/colors';
import commonStyles from 'utils/commonStyles';

const styles = (theme) => ({
  root: {
    marginBottom: '50px',
  },
  zebra: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(odd) td:nth-of-type(7)': { background: '#e1e9ef' },
    '&:nth-of-type(even) td:nth-of-type(7)': { background: '#ebf2f8' },
    '&:nth-of-type(odd) td:nth-of-type(8)': { background: '#e1e9ef' },
    '&:nth-of-type(even) td:nth-of-type(8)': { background: '#ebf2f8' },
    '&:nth-of-type(odd) td:nth-of-type(9)': { background: '#f6edd9' },
    '&:nth-of-type(even) td:nth-of-type(9)': { background: '#fff7e3' },
    '&:nth-of-type(odd) td:nth-of-type(10)': { background: '#f6edd9' },
    '&:nth-of-type(even) td:nth-of-type(10)': { background: '#fff7e3' },
    '&:nth-of-type(odd) td:nth-of-type(11)': { background: '#e1e9ef' },
    '&:nth-of-type(even) td:nth-of-type(11)': { background: '#ebf2f8' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  calcForm: {
    maxWidth: '800px',
  },
  kardexHeader: {
    background: '#ebf2f8',
  },
  zcotHeader: {
    background: '#fff7e3',
  },
  stickyFooter: {
    bottom: 0,
    position: 'fixed',
    backgroundColor: '#ffffff',
  },
  stickyRow: {
    position: 'sticky',
    backgroundColor: 'white',
  },
  button: {
    height: '55px',
  },
  textRed: {
    color: 'red !important',
  },
  textGreen: {
    color: `${colors.green} !important`,
  },
  ...commonStyles,
});

export default styles;
