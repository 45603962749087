/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'costs/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_CONTROLS_BY_KEY = `${PATH}CHANGE_CONTROLS_BY_KEY`;

// Global constants for saga
export const COSTS = {
  GET_MATERIAL_COSTS: `${PATH}GET_MATERIAL_COSTS`,
  GET_MATERIAL_COSTS_SUCCESS: `${PATH}GET_MATERIAL_COSTS_SUCCESS`,
  GET_MATERIAL_COSTS_FAIL: `${PATH}GET_MATERIAL_COSTS_FAIL`,
};

// actions
export const costsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
  changeControlsByKey: createAction(CHANGE_CONTROLS_BY_KEY, 'key', 'value'),
  // api
  getMaterialCosts: createAction(COSTS.GET_MATERIAL_COSTS, 'payload'),
  getMaterialCostsSuccess: createAction(COSTS.GET_MATERIAL_COSTS_SUCCESS, 'payload'),
  getMaterialCostsFail: createAction(COSTS.GET_MATERIAL_COSTS_FAIL, 'payload'),
};

const defaultControls = {
  code: '',
  materials: {},
};

const initialState = {
  error: false,
  errorMsg: '',
  loading: false,
  controls: {
    ...defaultControls,
  },
};

const costs = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
    state.errorMsg = '';
  },
  [RESET](state) {
    state.controls = { ...defaultControls };
  },
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [CHANGE_CONTROLS_BY_KEY]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [COSTS.GET_MATERIAL_COSTS]: (state) => ({
    ...state,
    loading: true,
  }),
  [COSTS.GET_MATERIAL_COSTS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      code: '',
      materials: {
        ...state.controls.materials,
        [action.payload.code]: {
          costs: action.payload.costs,
          name: action.payload.name,
          zcot: action.payload.zcot,
        },
      },
    },
    loading: false,
  }),
  [COSTS.GET_MATERIAL_COSTS_FAIL]: (state) => ({
    ...state,
    loading: false,
  }),
});

export default costs;
