import React, { useEffect } from 'react';
// Material
import { Button, FormHelperText, Grid, Typography } from '@mui/material';
// Components
import ModalLayout from 'commons/components/ModalLayout';
import UploadFile from 'commons/components/UploadFile';
import FabWithLoader from 'commons/containers/FabWithLoader';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// Icons
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// Utils
import { downloadBaseTemplate, processFormulaToBeSent } from 'utils/xlsx';
import { useForm } from 'utils/hooks/useForm';

const ModalUploadFormula = function (props) {
  const { open, controls, changeControls, toggleModalFnc, actions } = props;

  const { onSubmit, errors, resetErrors } = useForm(controls, null, {
    onChange: changeControls,
    validations: {
      file: [
        {
          check: (value) => typeof value === 'object' && value != null,
          message: 'Debe ingresar un archivo. ',
        },
        {
          check: (value) => !Array.isArray(value),
          message: 'Debe ingresar un archivo. ',
        },
        {
          check: (value) => ['.xlsx', '.xls', '.csv'].some((ext) => value?.name.endsWith(ext)),
          message: 'El archivo debe ser excel. ',
        },
      ],
      validFormat: [
        {
          check: (value) => value,
          message: 'Formato de columnas incorrecto. ',
        },
      ],
    },
  });

  useEffect(() => {
    if (!open) resetErrors();
  }, [open, resetErrors]);

  const handleSubmit = async () => {
    onSubmit(async () => {
      const data = await processFormulaToBeSent(controls.file);
      if (!data) return;
      // console.log('data submit', data);
      actions.submit(data, actions.calculateFormula);
    });
  };

  return (
    <ModalLayout open={open} toggleModalFnc={toggleModalFnc} width="500px">
      <Typography variant="h3" mb="30px" align="center">
        Cargar fórmula
      </Typography>
      <Grid container mt="20px" mb="20px" justifyContent="center">
        <Button onClick={downloadBaseTemplate}>Descarga archivo base</Button>
      </Grid>
      <form>
        <Grid container mb="20px" justifyContent="center">
          <UploadFile accept=".xlsx, .xls, .csv" id="formulaFile" onChange={changeControls}>
            {/* IMPORTANT: Always set component="span" in your button */}
            <FabWithLoader
              buttonId="select-formula"
              color="lala"
              variant="extended"
              component="span">
              <InsertDriveFileOutlinedIcon sx={{ mr: 1 }} />
              Seleccionar archivo
            </FabWithLoader>
          </UploadFile>
        </Grid>
        <Typography variant="h6" align="center" mb="30px">
          {controls.file?.name}
        </Typography>
        {errors.file && (
          <FormHelperText error align="center">
            {errors.file[0]}
          </FormHelperText>
        )}
        {errors.validFormat && (
          <FormHelperText error align="center">
            {errors.validFormat[0]}
          </FormHelperText>
        )}
        <ButtonWithLoader buttonId="submit" fullWidth onClick={handleSubmit}>
          Cargar fórmula
        </ButtonWithLoader>
      </form>
    </ModalLayout>
  );
};

export default ModalUploadFormula;
