import camelize from 'camelcase-keys-deep';

export default class Todo {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.entityId ? this.env().Entity.findBy('id', this.entityId) : null;
  }
}
