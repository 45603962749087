import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// Icons
import Clear from '@mui/icons-material/Clear';
// import IconButtonWithLoader from 'commons/containers/IconButtonWithLoader';
import { sumByKey } from 'utils/math';
import IconButtonWithConfirm from 'commons/containers/IconButtonWithConfirm';

const FormulaVariableCostsTable = function (props) {
  const { classes, tableData, actions } = props;

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 322 }}>
      <Table
        sx={{ minWidth: 650 }}
        stickyHeader
        // size="small"
        aria-label="detalle del cálculo de la fórmula">
        <TableHead>
          <TableRow>
            <TableCell align="left">id</TableCell>
            <TableCell align="left">Nombre</TableCell>
            <TableCell align="right">Costo</TableCell>
            <TableCell align="right">Eliminar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow
              className={classes.zebra}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row?.id}</TableCell>
              <TableCell align="left">{row?.name}</TableCell>
              <TableCell align="right">{row?.cost.toFixed(2)}</TableCell>
              <TableCell align="right">
                {/* <IconButtonWithLoader color="secondary">
                  <Clear />
                </IconButtonWithLoader> */}
                <IconButtonWithConfirm
                  buttonId="delete-variable-cost"
                  title="¿Desea eliminar este costo?"
                  text={row?.name}
                  confirmLabel="Eliminar"
                  callback={() =>
                    actions.deleteVariableCost({
                      id: row?.id,
                      materialId: tableData[0].material_id,
                    })
                  }>
                  <Clear />
                </IconButtonWithConfirm>
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.stickyRow} sx={{ bottom: '0px' }}>
            <TableCell colSpan={1}>Total costos</TableCell>
            <TableCell />
            <TableCell align="right">{`$${sumByKey(tableData, 'cost').toFixed(2)}`}</TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormulaVariableCostsTable;
