import React from 'react';
import { Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import ModalLayout from 'commons/components/ModalLayout';
import NumberField from 'commons/components/NumberField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';

const HistoricEditDetailsModal = function (props) {
  const { open, toggleModalFnc, data, actions } = props;
  const { name, cMaterial, details, quoteMaterialState, adjudicator, adjudicationPerc } = data;

  // console.log('quoteMaterialState', quoteMaterialState);

  const handleChange = (e) => {
    const { value } = e.target;
    const id = e?.target?.id ?? e?.target?.name;
    if (e?.target?.name === 'quoteMaterialState' && value !== 2) {
      actions.changeControls({
        quoteDetails: {
          ...data,
          adjudicator: '',
          adjudicationPerc: '',
        },
      });
    }
    actions.changeEditDetails(id, value);
  };

  const handleSubmit = () => {
    actions.updateQuoteMaterial(data);
  };

  return (
    <ModalLayout
      width="800px"
      maxWidth="800px"
      minHeight="400px"
      open={open}
      toggleModalFnc={toggleModalFnc}>
      <Grid container mb="40px">
        <Typography variant="h4">{`${cMaterial} - ${name}`}</Typography>
      </Grid>
      <Grid container mb="20px">
        <Select
          id="quoteMaterialState"
          name="quoteMaterialState"
          value={quoteMaterialState}
          fullWidth
          onChange={handleChange}>
          <MenuItem value={1}>Pendiente</MenuItem>
          <MenuItem value={2}>Adjudicado</MenuItem>
          <MenuItem value={3}>Perdido</MenuItem>
        </Select>
      </Grid>

      <Grid container spacing={2} mb="20px">
        <Grid item sm={6}>
          <TextField
            id="adjudicator"
            label="Adjudicante"
            variant="outlined"
            value={adjudicator}
            onChange={handleChange}
            inputProps={{ maxLength: 255 }}
            fullWidth
            disabled={quoteMaterialState !== 2}
          />
        </Grid>
        <Grid item sm={6}>
          <NumberField
            id="adjudicationPerc"
            label="% adjudicación"
            variant="outlined"
            value={adjudicationPerc}
            onChange={handleChange}
            disabled={quoteMaterialState !== 2}
            // error={!adjudicationPerc}
            // error={truthty(errors.adjudicationPerc)}
            // helperText={errors.adjudicationPerc ? errors?.adjudicationPerc[0] : null}
            inputProps={{ maxLength: 3 }}
            InputLabelProps={{ shrink: true }}
            symbol="%"
            symbolPosition="end"
            fullWidth
            maxValue={100}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="details"
            label="Comentarios"
            variant="outlined"
            value={details}
            onChange={handleChange}
            sx={{ mb: '20px' }}
            inputProps={{ maxLength: 10000 }}
            fullWidth
            multiline
            rows={5}
            // disabled={!apiMsg}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <Button variant="outlined" onClick={toggleModalFnc}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <ButtonWithLoader buttonId="update-quote-material" onClick={handleSubmit}>
            Actualizar
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

export default HistoricEditDetailsModal;
