export const sum = (arr) => arr.reduce((acc, elem) => acc + elem, 0);

export const sumByKey = (arr, key) => sum(arr.map((obj) => obj[key]));

/** Valida si un valor es numérico o si es un string parseable a número. */
export const isNumber = (value) =>
  value !== null &&
  value !== undefined &&
  value.toString().trim() !== '' &&
  !Number.isNaN(Number(value.toString().trim()));

export function unformatNumber(value) {
  if (value == null) {
    return null;
  }
  const unformattedValue = value.toString().replace(/[.]/g, '').replace(',', '.');
  return unformattedValue;
}
