import React, { useCallback } from 'react';
// Material
import { Grid, TextField } from '@mui/material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import SearchMaterialByName from 'commons/components/SearchMaterialByName';
// Utils
import { valueHasData } from 'utils/validationsHelper';
import { useForm } from 'utils/hooks/useForm';
import { isNumber } from 'utils/math';
import SelectCurrency from 'commons/components/SelectCurrency';

const CostsMenu = function (props) {
  const { controls, classes, handleChange, handleSearch, loading, currencyCode, setCurrencyCode } =
    props;
  const { code } = controls;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: handleChange,
    validations: {
      code: [
        {
          check: (value) => !valueHasData(value) || isNumber(value),
          message: 'El código debe ser numérico ',
        },
      ],
    },
  });

  const handleFilter = (e) => {
    e.preventDefault();
    onSubmit(() => {
      handleSearch();
    });
  };

  const handleChangeMaterialName = useCallback(
    (value) => {
      handleChange('code', value);
    },
    [handleChange],
  );
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <form onSubmit={handleFilter}>
          <Grid container mb="20px" wrap="nowrap">
            <Grid item>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <TextField
                    id="code"
                    label="Código material"
                    variant="outlined"
                    disabled={loading}
                    value={code}
                    onChange={onChange}
                    error={errors.code?.length > 0}
                    helperText={errors.code ? errors?.code[0] : null}
                    inputProps={{ maxLength: 6 }}
                  />
                </Grid>
                <Grid item mb="20px">
                  <SearchMaterialByName
                    materialId={controls.code}
                    disabled={loading}
                    callback={handleChangeMaterialName}
                    allMaterials
                    sx={{ width: 300 }}
                  />
                </Grid>
                <Grid item>
                  <ButtonWithLoader
                    type="submit"
                    buttonId="get-material-costs"
                    variant="contained"
                    color="primary"
                    className={classes.button}>
                    Consultar
                  </ButtonWithLoader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item sm={3}>
        <Grid container justifyContent="flex-end">
          <SelectCurrency currencyCode={currencyCode} setCurrencyCode={setCurrencyCode} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CostsMenu;
