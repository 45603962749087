import { createReducer, createAction } from 'utils/reducer';

const PATH = 'todo/';

export const TODO = {
  CREATE_TODO_SUCCESS: `${PATH}CREATE_TODO_SUCCESS`,
  CREATE_TODO_FAIL: `${PATH}CREATE_TODO_FAIL`,
  CREATE_TODO: `${PATH}CREATE_TODO`,
  CHECK_TODO_SUCCESS: `${PATH}CHECK_TODO_SUCCESS`,
  CHECK_TODO_FAIL: `${PATH}CHECK_TODO_FAIL`,
  CHECK_TODO: `${PATH}CHECK_TODO`,
  GET_TODOS_SUCCESS: `${PATH}GET_TODOS_SUCCESS`,
  GET_TODOS_FAIL: `${PATH}GET_TODOS_FAIL`,
  GET_TODOS: `${PATH}GET_TODOS`,
  DESTROY_TODO_SUCCESS: `${PATH}DESTROY_TODO_SUCCESS`,
  DESTROY_TODO_FAIL: `${PATH}DESTROY_TODO_FAIL`,
  DESTROY_TODO: `${PATH}DESTROY_TODO`,
};

export const todoActions = {
  // api
  getTodos: createAction(TODO.GET_TODOS),
  createTodo: createAction(TODO.CREATE_TODO, 'controls'),
  checkTodo: createAction(TODO.CHECK_TODO, 'controls'),
  destroyTodo: createAction(TODO.DESTROY_TODO, 'controls'),
};

const initialState = {
  controls: {
    todos: {},
    error: null,
    loading: false,
  },
};

const todo = createReducer(initialState, {
  // [TODO.CREATE_TODO]: (state) => {
  //   state.controls.error = null;
  //   state.controls.loading = true;
  // },
  [TODO.CREATE_TODO]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: true,
    },
  }),
  // [TODO.CREATE_TODO_FAIL]: (state, action) => {
  //   state.controls.error = action.payload;
  //   state.controls.loading = false;
  // },
  [TODO.CREATE_TODO_FAIL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: action.payload,
      loading: false,
    },
  }),
  // [TODO.CREATE_TODO_SUCCESS]: (state, action) => {
  //   state.controls.error = null;
  //   state.controls.loading = false;
  //   state.controls.todos = { ...state.controls.todos, ...action.payload.todo };
  // },
  [TODO.CREATE_TODO_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: false,
      todos: { ...state.controls.todos, ...action.payload.todo },
    },
  }),
  // [TODO.CHECK_TODO]: (state) => {
  //   state.controls.error = null;
  //   state.controls.loading = true;
  // },
  [TODO.CHECK_TODO]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: true,
    },
  }),
  // [TODO.CHECK_TODO_FAIL]: (state, action) => {
  //   state.controls.error = action.payload;
  //   state.controls.loading = false;
  // },
  [TODO.CHECK_TODO_FAIL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: action.payload,
      loading: false,
    },
  }),
  // [TODO.CHECK_TODO_SUCCESS]: (state, action) => {
  //   state.controls.error = null;
  //   state.controls.loading = false;
  //   state.controls.todos = { ...state.controls.todos, ...action.payload.todo };
  // },
  [TODO.CHECK_TODO_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: false,
      todos: { ...state.controls.todos, ...action.payload.todo },
    },
  }),
  // [TODO.GET_TODOS]: (state) => {
  //   state.controls.error = null;
  //   state.controls.loading = true;
  // },
  [TODO.GET_TODOS]: (state) => ({
    ...state,
    controls: {
      error: null,
      loading: true,
    },
  }),
  // [TODO.GET_TODOS_FAIL]: (state, action) => {
  //   state.controls.error = action.payload;
  //   state.controls.loading = false;
  // },
  [TODO.GET_TODOS_FAIL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: action.payload,
      loading: false,
    },
  }),
  // [TODO.GET_TODOS_SUCCESS]: (state, action) => {
  //   state.controls.error = null;
  //   state.controls.loading = false;
  //   state.controls.todos = action.payload.todos;
  // },
  [TODO.GET_TODOS_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: false,
      todos: action.payload.todos,
    },
  }),
  // [TODO.DESTROY_TODO]: (state) => {
  //   state.controls.error = null;
  //   state.controls.loading = true;
  // },
  [TODO.DESTROY_TODO]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: true,
    },
  }),
  // [TODO.DESTROY_TODO_FAIL]: (state, action) => {
  //   state.controls.error = action.payload;
  //   state.controls.loading = false;
  // },
  [TODO.DESTROY_TODO_FAIL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: action.payload,
      loading: false,
    },
  }),
  // [TODO.DESTROY_TODO_SUCCESS]: (state, action) => {
  //   state.controls.error = null;
  //   state.controls.loading = false;
  //   state.controls.todos = Object.entries(state.controls.todos).reduce(
  //     (acc, item) =>
  //       // console.log(action.todoId, item[1]);
  //       parseInt(action.payload.todoId, 10) === item[1].id
  //         ? { ...acc }
  //         : { ...acc, [item[0]]: item[1] },
  //     {},
  //   );
  // },
  [TODO.DESTROY_TODO_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      error: null,
      loading: false,
      todos: Object.entries(state.controls.todos).reduce(
        (acc, item) =>
          // console.log(action.todoId, item[1]);
          parseInt(action.payload.todoId, 10) === item[1].id
            ? { ...acc }
            : { ...acc, [item[0]]: item[1] },
        {},
      ),
    },
  }),
});

export default todo;
