import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'quote/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_CONTROLS_2 = `${PATH}CHANGE_CONTROLS_2`;
const CHANGE_STATE = `${PATH}CHANGE_STATE`;

// Global constants for saga
export const QUOTE = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  GET_MATERIAL: `${PATH}GET_MATERIAL`,
  GET_MATERIAL_SUCCESS: `${PATH}GET_MATERIAL_SUCCESS`,
  GET_MATERIAL_FAIL: `${PATH}GET_MATERIAL_FAIL`,
  SUBMIT_QUOTE: `${PATH}SUBMIT_QUOTE`,
  SUBMIT_QUOTE_SUCCESS: `${PATH}SUBMIT_QUOTE_SUCCESS`,
  SUBMIT_QUOTE_FAIL: `${PATH}SUBMIT_QUOTE_FAIL`,
  GET_PDF_FILE: `${PATH}GET_PDF_FILE`,
  GET_PDF_FILE_SUCCESS: `${PATH}GET_PDF_FILE_SUCCESS`,
  GET_PDF_FILE_FAIL: `${PATH}GET_PDF_FILE_FAIL`,
};

// actions
export const quoteActions = {
  reset: createAction(RESET),
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  changeControls2: createAction(CHANGE_CONTROLS_2, 'key', 'value'),
  changeState: createAction(CHANGE_STATE, 'value'),
  // api
  getStateFromApi: createAction(QUOTE.GET_STATE_FROM_API),
  getStateFromApiSuccess: createAction(QUOTE.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getStateFromApiFail: createAction(QUOTE.GET_STATE_FROM_API_FAIL, 'payload'),
  getMaterial: createAction(QUOTE.GET_MATERIAL, 'data'),
  getMaterialSuccess: createAction(QUOTE.GET_MATERIAL_SUCCESS, 'payload'),
  getMaterialFail: createAction(QUOTE.GET_MATERIAL_FAIL, 'payload'),
  submitQuote: createAction(QUOTE.SUBMIT_QUOTE, 'data'),
  submitQuoteSuccess: createAction(QUOTE.SUBMIT_QUOTE_SUCCESS, 'payload'),
  submitQuoteFail: createAction(QUOTE.SUBMIT_QUOTE_FAIL, 'payload'),
  getPdfFile: createAction(QUOTE.GET_PDF_FILE, 'payload'),
  getPdfFileSuccess: createAction(QUOTE.GET_PDF_FILE_SUCCESS, 'payload'),
  getPdfFileFail: createAction(QUOTE.GET_PDF_FILE_FAIL, 'payload'),
};

const defaultControls = {
  title: '',
  clientCode: '',
  clientName: '',
  code: '',
  startDate: null,
  endDate: null,
  materialLines: [],
  atn: '',
  paymentCondition: '',
  comments: '',
  currencyId: '1',
  delivery: '',
  validity: '',
  sender: '',
  phone: '',
  email: '',
};

const defaultInvalidControls = {
  name: false,
};

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    ...defaultControls,
  },
  invalidControls: {
    ...defaultInvalidControls,
  },
  step: 0,
  linesHasChanged: false,
  newQuoteId: '',
};

const quote = createReducer(initialState, {
  [DISABLE_ERRORS]: (state) => ({
    ...state,
    error: false,
    errorMsg: '',
  }),
  [RESET]: (state) => ({
    ...initialState,
    controls: {
      ...defaultControls,
      currencyId: state.controls.currencyId,
    },
  }),
  [CHANGE_STATE]: (state, action) => ({
    ...state,
    ...action.value,
  }),
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
    invalidControls: {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    },
  }),
  [CHANGE_CONTROLS_2]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [QUOTE.GET_MATERIAL_SUCCESS]: (state, action) => {
    const lines = state.controls.materialLines;
    const { mainMaterial, totals } = action.payload;
    const newLines = [
      ...lines,
      {
        material_id: mainMaterial.id,
        m_code: mainMaterial.c_material,
        name: mainMaterial.description,
        dosis: mainMaterial.dosis,
        kardex: totals.kardexDolar, // TODO: cambiar de manera condicional si se elije pesos o dólares.
        projectedCost: totals.totalProjectedCost,
        zcot: totals.zcotDolar,
        difCost: totals.diffCost,
        calculatedPrice: totals.totalCostDolar, // Kardex calculado
        umb: mainMaterial.umb,
        // mPercPpto: getMPercPpto(lines, totals.kardexDolar),
        mPercPpto: 0,
        mgPerc: null,
        price: 0,
        volumeKg: null,
        totalMargin: 0,
        totalSale: 0,
      },
    ];
    return {
      ...state,
      linesHasChanged: true,
      controls: {
        ...state.controls,
        materialLines: newLines,
        code: '',
      },
    };
  },
  [QUOTE.SUBMIT_QUOTE_SUCCESS]: (state, action) => ({
    ...state,
    step: 2,
    newQuoteId: action.payload.quoteId,
  }),
});

export default quote;
