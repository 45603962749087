/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

import * as APP from 'commons/reducer';

// Local constants
const PATH = 'formulaCalculation/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_CONTROLS_BY_KEY = `${PATH}CHANGE_CONTROLS_BY_KEY`;
const CHANGE_MODALS = `${PATH}CHANGE_MODALS`;
const CHANGE_ADD_MATERIAL_DATA = `${PATH}CHANGE_ADD_MATERIAL_DATA`;
const CHANGE_VARIABLE_COSTS_DATA = `${PATH}CHANGE_VARIABLE_COSTS_DATA`;

// Global constants for saga
export const FORMULA_CALCULATION = {
  CALCULATE_FORMULA: `${PATH}CALCULATE_FORMULA`,
  CALCULATE_FORMULA_SUCCESS: `${PATH}CALCULATE_FORMULA_SUCCESS`,
  CALCULATE_FORMULA_FAIL: `${PATH}CALCULATE_FORMULA_FAIL`,
  SUBMIT: `${PATH}SUBMIT`,
  SUBMIT_SUCCESS: `${PATH}SUBMIT_SUCCESS`,
  SUBMIT_FAIL: `${PATH}SUBMIT_FAIL`,
  SEARCH_MATERIAL: `${PATH}SEARCH_MATERIAL`,
  SEARCH_MATERIAL_SUCCESS: `${PATH}SEARCH_MATERIAL_SUCCESS`,
  SEARCH_MATERIAL_FAIL: `${PATH}SEARCH_MATERIAL_FAIL`,
  ADD_MATERIAL_FORMULA: `${PATH}ADD_MATERIAL_FORMULA`,
  ADD_MATERIAL_FORMULA_SUCCESS: `${PATH}ADD_MATERIAL_FORMULA_SUCCESS`,
  ADD_MATERIAL_FORMULA_FAIL: `${PATH}ADD_MATERIAL_FORMULA_FAIL`,
  ADD_VARIABLE_COST: `${PATH}ADD_VARIABLE_COST`,
  ADD_VARIABLE_COST_SUCCESS: `${PATH}ADD_VARIABLE_COST_SUCCESS`,
  ADD_VARIABLE_COST_FAIL: `${PATH}ADD_VARIABLE_COST_FAIL`,
  DELETE_VARIABLE_COST: `${PATH}DELETE_VARIABLE_COST`,
  DELETE_VARIABLE_COST_SUCCESS: `${PATH}DELETE_VARIABLE_COST_SUCCESS`,
  DELETE_VARIABLE_COST_FAIL: `${PATH}DELETE_VARIABLE_COST_FAIL`,
};

// actions
export const formulaCalculationActions = {
  reset: createAction(RESET),
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  changeControlsByKey: createAction(CHANGE_CONTROLS_BY_KEY, 'key', 'value'),
  changeModals: createAction(CHANGE_MODALS, 'module'),
  changeAddMaterialData: createAction(CHANGE_ADD_MATERIAL_DATA, 'key', 'value'),
  changeVariableCostsData: createAction(CHANGE_VARIABLE_COSTS_DATA, 'key', 'value'),
  // api
  calculateFormula: createAction(FORMULA_CALCULATION.CALCULATE_FORMULA, 'data'),
  calculateFormulaSuccess: createAction(FORMULA_CALCULATION.CALCULATE_FORMULA_SUCCESS, 'payload'),
  calculateFormulaFail: createAction(FORMULA_CALCULATION.CALCULATE_FORMULA_FAIL, 'payload'),
  submit: createAction(FORMULA_CALCULATION.SUBMIT, 'controls', 'callback'),
  submitSuccess: createAction(FORMULA_CALCULATION.SUBMIT_SUCCESS, 'payload'),
  submitFail: createAction(FORMULA_CALCULATION.SUBMIT_FAIL, 'payload'),
  searchMaterial: createAction(FORMULA_CALCULATION.SEARCH_MATERIAL, 'data'),
  searchMaterialSuccess: createAction(FORMULA_CALCULATION.SEARCH_MATERIAL_SUCCESS, 'payload'),
  searchMaterialFail: createAction(FORMULA_CALCULATION.SEARCH_MATERIAL_FAIL, 'payload'),
  addMaterialFormula: createAction(FORMULA_CALCULATION.ADD_MATERIAL_FORMULA, 'data'),
  addMaterialFormulaSuccess: createAction(
    FORMULA_CALCULATION.ADD_MATERIAL_FORMULA_SUCCESS,
    'payload',
  ),
  addMaterialFormulaFail: createAction(FORMULA_CALCULATION.ADD_MATERIAL_FORMULA_FAIL, 'payload'),
  addVariableCost: createAction(FORMULA_CALCULATION.ADD_VARIABLE_COST, 'data'),
  addVariableCostSuccess: createAction(FORMULA_CALCULATION.ADD_VARIABLE_COST_SUCCESS, 'payload'),
  addVariableCostFail: createAction(FORMULA_CALCULATION.ADD_VARIABLE_COST_FAIL, 'payload'),
  deleteVariableCost: createAction(FORMULA_CALCULATION.DELETE_VARIABLE_COST, 'payload'),
  deleteVariableCostSuccess: createAction(
    FORMULA_CALCULATION.DELETE_VARIABLE_COST_SUCCESS,
    'payload',
  ),
  deleteVariableCostFail: createAction(FORMULA_CALCULATION.DELETE_VARIABLE_COST_FAIL, 'payload'),
};

const defaultAddMaterial = {
  codeToSearch: '',
  exists: true,
  apiMsg: '',
  description: '',
  umb: '',
  level: '',
  sector: '',
  artGroup: '',
  gross: '',
  net: '',
  unit: '',
  artGroupExt: '',
  blocked: '',
  services: false,
  cost: '',
  zcot: '',
  // Formula
  fMaterialId: '',
  quantBase: '',
  baseUnit: '',
  fUnit: '',
  quantity: '',
  // rCtrP: '',
  // batch: '',
  // fixedQ: '',
  // type: '',
  // specie: '',
  // phase: '',
  // kgTonDosage: '',
};

const defaultVariableCosts = {
  materialId: '',
  matListAlt: '',
  name: '',
  cost: '',
};

const defaultControls = {
  code: '',
  name: '',
  foundName: '',
  foundCode: '',
  date: null,
  loading: false,
  mainMaterial: {},
  materials: {},
  kardex: '',
  file: null,
  validFormat: false,
  uploadSuccess: false,
  uploadCode: '',
  addMaterial: defaultAddMaterial,
  variableCosts: defaultVariableCosts,
  foundVariableCosts: [],
  deleteApiResponse: false,
};

const defaultInvalidControls = {
  code: false,
};

const defaultModals = {
  uploadFile: false,
  addMaterial: false,
  variableCosts: false,
  editCurrencies: false,
};

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    ...defaultControls,
  },
  invalidControls: {
    ...defaultInvalidControls,
  },
  modals: { ...defaultModals },
};

const formulaCalculation = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
    state.errorMsg = '';
  },
  [RESET]: () => ({
    ...initialState,
  }),
  [CHANGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [CHANGE_CONTROLS_BY_KEY]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [CHANGE_ADD_MATERIAL_DATA]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      addMaterial: {
        ...state.controls.addMaterial,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_VARIABLE_COSTS_DATA]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      variableCosts: {
        ...state.controls.variableCosts,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_MODALS]: (state, action) => {
    const { modals, controls } = state;
    const { module } = action;
    return {
      ...state,
      modals: {
        ...modals,
        [module]: !modals[module],
      },
      controls: {
        ...controls,
        file: null,
        validFormat: false,
        addMaterial: defaultAddMaterial,
        variableCosts: {
          ...controls.variableCosts,
          name: '',
          cost: '',
        },
      },
    };
  },
  [FORMULA_CALCULATION.CALCULATE_FORMULA]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      materials: {},
      loading: true,
    },
  }),
  [FORMULA_CALCULATION.CALCULATE_FORMULA_SUCCESS](state, action) {
    // console.log('PAYLOAD', action.payload);
    // state.controls.materials = action.payload.objects.materials;
    state.controls.materials = action.payload.calculatedMaterials;
    state.controls.totals = action.payload.totals;
    state.controls.code = action.payload.mainMaterial.c_material;
    state.controls.name = action.payload.mainMaterial.description;
    state.controls.foundCode = action.payload.mainMaterial.c_material;
    state.controls.foundName = action.payload.mainMaterial.description;
    state.controls.date = action.payload.mainMaterial.o_created_at;
    state.controls.mainMaterial = action.payload.mainMaterial;
    state.controls.loading = false;
    state.controls.uploadSuccess = false;
    state.controls.uploadCode = '';
    state.controls.variableCosts.materialId = action.payload.mainMaterial.c_material;
    state.controls.variableCosts.matListAlt = action.payload.mainFormulaVersion;
    state.controls.foundVariableCosts = Object.values(action.payload.objects.variableCosts);
  },
  [FORMULA_CALCULATION.CALCULATE_FORMULA_FAIL](state) {
    // console.log('PAYLOAD', action.payload);
    state.controls.loading = false;
    state.controls.uploadSuccess = false;
  },
  [FORMULA_CALCULATION.SUBMIT_SUCCESS](state, action) {
    state.modals.uploadFile = false;
    state.controls.file = [];
    state.controls.uploadCode = action.payload.code;
    state.controls.uploadSuccess = true;
  },
  [FORMULA_CALCULATION.SUBMIT_FAIL](state) {
    state.modals.uploadFile = false;
    state.controls.file = [];
  },
  [APP.GET_MATERIALS_BY_NAME]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      code: '',
    },
  }),
  [FORMULA_CALCULATION.SEARCH_MATERIAL_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      addMaterial: {
        ...state.controls.addMaterial,
        exists: action.payload.exists,
        apiMsg: action.payload.msg,
      },
    },
  }),
  [FORMULA_CALCULATION.ADD_MATERIAL_FORMULA_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      addMaterial: defaultAddMaterial,
    },
    modals: {
      ...state.modals,
      addMaterial: false,
    },
  }),
  [FORMULA_CALCULATION.ADD_VARIABLE_COST_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      variableCosts: defaultVariableCosts,
    },
    modals: {
      ...state.modals,
      // variableCosts: false,
    },
  }),
  [FORMULA_CALCULATION.DELETE_VARIABLE_COST_FAIL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      deleteApiResponse: true,
    },
  }),
  [FORMULA_CALCULATION.DELETE_VARIABLE_COST_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      deleteApiResponse: true,
    },
  }),
});

export default formulaCalculation;
