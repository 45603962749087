import colors from 'utils/colors';

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: 300,
    background: 'blue',
  },
  listItemRoot: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
      borderRight: `6px solid ${colors.primary}`,
    },
  },
});

export default styles;
