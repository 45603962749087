import React, { useCallback, useEffect, useState } from 'react';

// Material
import { withStyles } from '@mui/styles';
// Icons
// Material daterpicker
import moment from 'moment';
import 'moment/locale/es';

// Project
import { composedComponent, truthty, unnacent } from 'utils/functions';
import { useForm } from 'utils/hooks/useForm';
import produce from 'immer';
import { xlsxToJson } from 'utils/xlsx';
import EditCurrenciesModal from 'commons/components/EditCurrenciesModal';
import useCurrency from 'utils/hooks/useCurrency';
import { getDollarKardex, getDollarZcot } from 'utils/currencyHelpers';
import { numberBetween } from 'utils/validationsHelper';
import { formulaCalculationActions } from './reducer';
import saga from './saga';

// Components
import ModalUploadFormula from './components/ModalUploadFormula';
import FormulaResultsTable from './components/FormulaResultsTable';
import FormulaResultsCard from './components/FormulaResultsCard';
import FormulaMenu from './components/FormulaMenu';
import ModalVariableCosts from './components/ModalVariableCosts';
// import ModalAddMaterial from './components/ModalAddMaterial';

// Utils

// Styles
import styles from './styles';

const FormulaCalculation = function (props) {
  const { classes, objects, controls, actions, modals } = props;
  const dollarKardex = getDollarKardex(objects.currencies);
  const dollarZcot = getDollarZcot(objects.currencies);
  const { currencyCode, setCurrencyCode, convertKardexValue, convertZcotValue } = useCurrency({
    dollarKardex,
    dollarZcot,
  });

  const [state, setState] = useState({
    code: '',
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (!controls.loading && truthty(controls.materials)) {
      setState((old) => ({ ...old, code: '' }));
    }
  }, [controls.loading, controls.materials]);

  // old
  // const variableCosts = Object.values(objects.variableCosts);
  // const variableCostsArr = variableCosts.length ? variableCosts : [];
  // test1
  // const variableCostsArr = Object.keys(controls.mainMaterial).length
  //   ? controls.mainMaterial?.variableCosts()
  //   : [];
  // test2
  const variableCostsArr = controls.foundVariableCosts;
  // console.log('variableCostsArr', variableCostsArr);

  const onChangeText = useCallback((id, value) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[id] = value?.trim();
      }),
    );
  }, []);

  const handleChangeDate = useCallback(
    (date, id) => {
      // eslint-disable-next-line no-underscore-dangle
      const m = moment(date?._d);
      if (!m.isValid()) {
        return;
      }

      if (!date) {
        onChangeText(id, null);
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      onChangeText(id, new Date(date?._d).toISOString());
    },
    [onChangeText],
  );

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      code: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Debe ingresar un código ',
        },
        {
          check: (value) => !Number.isNaN(Number(value.trim())),
          message: 'El código debe ser numérico ',
        },
        {
          check: (value) =>
            numberBetween(value, 400_000, 499_999) || numberBetween(value, 40_000_000, 49_999_999),
          message: 'Sólo se permiten código de prod. terminados',
        },
      ],
      startDate: [
        {
          check: (value) => (!value && !state.endDate) || (value && state.endDate),
          message: 'Deben ir ambas fechas o ninguna ',
        },
        {
          check: (value) =>
            (!value && !state.endDate) ||
            moment(value).isSame(state.endDate, 'month') ||
            moment(value).isBefore(state.endDate),
          message: 'Fecha de inicio no debe ser mayor a la final ',
        },
      ],
      endDate: [
        {
          check: (value) => (!value && !state.endDate) || (value && state.endDate),
          message: 'Deben ir ambas fechas o ninguna ',
        },
        {
          check: (value) =>
            (!value && !state.startDate) ||
            moment(value).isSame(state.startDate, 'month') ||
            moment(value).isAfter(state.startDate),
          message: 'Fecha final no debe ser menor a la inicial ',
        },
      ],
    },
  });

  const handleCalculate = useCallback(
    (event) => {
      event.preventDefault();
      onSubmit(() => actions.calculateFormula({ ...state }));
    },
    [onSubmit, actions, state],
  );

  const handleToggleModal = useCallback(() => {
    actions.changeModals('uploadFile');
  }, [actions]);

  const handleToggleAddMaterialModal = useCallback(() => {
    actions.changeModals('addMaterial');
  }, [actions]);

  const handleToggleVariableCosts = useCallback(() => {
    actions.changeModals('variableCosts');
  }, [actions]);

  const handleToggleEditCurrencies = useCallback(() => {
    actions.changeModals('editCurrencies');
  }, [actions]);

  const handleChangeFile = useCallback(
    async (event) => {
      // console.log(event);
      const files = Array.from(event.target.files);
      const isExcel = ['.xlsx', '.xls', '.csv'].some((ext) => files[0]?.name.endsWith(ext));
      if (!isExcel) {
        actions.changeControls({ file: files[0], validFormat: false });
        return;
      }
      const json = await xlsxToJson(files[0]);
      // console.log('json :>> ', json);
      const validFormat =
        json !== null &&
        // Title
        unnacent(json['0']?.D).toUpperCase() === 'PLANTILLA DE FORMULA NUTRICIONAL' &&
        // General info
        unnacent(json['1']?.C).toUpperCase() === 'NOMBRE' &&
        unnacent(json['2']?.C).toUpperCase() === 'CANT. BASE' &&
        unnacent(json['3']?.C).toUpperCase() === 'UMB' &&
        unnacent(json['4']?.C).toUpperCase() === 'ESPECIE' &&
        // Material rows
        unnacent(json['8']?.C).toUpperCase() === 'CODIGO' &&
        unnacent(json['8']?.D).toUpperCase() === 'CANTIDAD' &&
        unnacent(json['8']?.E).toUpperCase() === 'UN';
      actions.changeControls({ file: files[0], validFormat });
    },
    [xlsxToJson, unnacent, actions],
  );

  return (
    <>
      <FormulaMenu
        classes={classes}
        controls={controls}
        code={state.code}
        startDate={state.startDate}
        endDate={state.endDate}
        onChange={onChange}
        handleChangeDate={handleChangeDate}
        handleCalculate={handleCalculate}
        handleToggleModal={handleToggleModal}
        handleToggleAddMaterialModal={handleToggleAddMaterialModal}
        handleToggleEditCurrencies={handleToggleEditCurrencies}
        errors={errors}
        state={state}
      />

      {!controls.loading && truthty(controls.materials) && (
        <>
          <FormulaResultsCard
            controls={controls}
            currencyCode={currencyCode}
            setCurrencyCode={setCurrencyCode}
          />
          <FormulaResultsTable
            controls={controls}
            actions={actions}
            classes={classes}
            convertKardexValue={convertKardexValue}
            convertZcotValue={convertZcotValue}
          />
        </>
      )}
      <ModalUploadFormula
        open={modals.uploadFile}
        toggleModalFnc={() => handleToggleModal()}
        controls={controls}
        changeControls={handleChangeFile}
        actions={actions}
      />
      {/* <ModalAddMaterial
        classes={classes}
        open={modals.addMaterial}
        toggleModalFnc={() => handleToggleAddMaterialModal()}
        addMaterial={controls.addMaterial}
        // changeControls={handleChangeFile}
        actions={actions}
      /> */}
      <ModalVariableCosts
        classes={classes}
        open={modals.variableCosts}
        toggleModalFnc={() => handleToggleVariableCosts()}
        variableCosts={controls.variableCosts}
        tableData={variableCostsArr}
        // changeControls={handleChangeFile}
        actions={actions}
        deleteApiResponse={controls.deleteApiResponse}
      />
      <EditCurrenciesModal
        open={modals.editCurrencies}
        toggleModalFnc={() => handleToggleEditCurrencies()}
      />
    </>
  );
};

export default composedComponent(FormulaCalculation, saga, {
  saga: 'sagaFormulaCalculation',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'formulaCalculation.controls',
    'formulaCalculation.invalidControls',
    'formulaCalculation.modals',
  ],
  actions: [formulaCalculationActions],
});
