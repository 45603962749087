// const gray = 'D9D9D9';
// const darkBlue = '002060';
// const lightBlue = 'DAE3F3';
// const yellow = 'FFFF99';
// const black = '000000';

// const baseBorder = {
//   style: 'thin',
//   color: { rgb: gray },
// };
// const fullBorder = {
//   top: baseBorder,
//   bottom: baseBorder,
//   left: baseBorder,
//   right: baseBorder,
// };

const baseHistoric = () => ({
  base: [
    [
      'id cot.',
      'Generador cot.',
      'cod. Mat.',
      'Material',
      'Costo',
      'Moneda',
      'MG%',
      'Precio',
      'Volumen',
      'Total Venta',
      'Porc. Adj.',
      'Cliente',
      'Fecha',
    ],
  ],
  wscols: [
    { wch: 6 },
    { wch: 8 },
    { wch: 40 },
    { wch: 6 },
    { wch: 6 },
    { wch: 4 },
    { wch: 10 },
    { wch: 8 },
    { wch: 10 },
    { wch: 8 },
    { wch: 40 },
    { wch: 10 },
  ],
  // wsrows: [{ hpx: 20 }],
  // wsmerges: [
  //   { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
  //   { s: { r: 2, c: 5 }, e: { r: 2, c: 6 } },
  //   { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },
  //   { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },
  //   { s: { r: 5, c: 5 }, e: { r: 5, c: 6 } },
  //   { s: { r: 6, c: 5 }, e: { r: 6, c: 6 } },
  // ],
});

export default baseHistoric;
