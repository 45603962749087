import { grey } from '@mui/material/colors';

const huesAndShades = [
  50,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  'A100',
  'A200',
  'A400',
  'A700',
];
const greyPalette = huesAndShades.reduce((acc, hue) => {
  acc[`grey${hue}`] = grey[hue];
  return acc;
}, {});
const colors = {
  red: '#e53935',
  green: '#4CAF50',
  grey: '#babdbe',
  primary: '#FA7624',
  secondary: '#F0AB0A',
  ...greyPalette,
};

export default colors;
