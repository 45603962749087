import { takeLatest, spawn, put } from 'redux-saga/effects';
import { post, apiSuccess } from 'utils/api';

// Reducer
import { appActions, GET_STATE_FROM_API_SUCCESS } from 'commons/reducer';
import { COSTS } from '../reducer';

function* getMaterialCosts() {
  yield takeLatest(COSTS.GET_MATERIAL_COSTS, function* (action) {
    yield put(appActions.setLoader('get-material-costs', true));
    const response = yield post(`api/v1/costs`, action.payload);
    if (response.error) {
      yield put(apiSuccess(COSTS.GET_MATERIAL_COSTS_FAIL, response.error));
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(COSTS.GET_MATERIAL_COSTS_SUCCESS, response));
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
    }
    yield put(appActions.setLoader('get-material-costs', false));
  });
}

export default function* root() {
  yield spawn(getMaterialCosts);
}
