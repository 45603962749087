const useStep = (currentStep, options, changeStep) => {
  const { steps, initialStepIndex = 0 } = options;

  const setPrevStep = () => {
    changeStep(currentStep === initialStepIndex ? currentStep : currentStep - 1);
  };

  const setNextStep = () => {
    changeStep(currentStep === steps - 1 + initialStepIndex ? currentStep : currentStep + 1);
  };

  const resetStep = () => {
    changeStep(initialStepIndex);
  };

  return [setPrevStep, setNextStep, resetStep];
};

export default useStep;
