/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'maintainers/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_CURRENCIES_DATA = `${PATH}CHANGE_CURRENCIES_DATA`;
const TOGGLE_MODALS = `${PATH}TOGGLE_MODALS`;
const CHANGE_USER_FORM_DATA = `${PATH}CHANGE_USER_FORM_DATA`;
const CHANGE_MATERIAL_FORM_DATA = `${PATH}CHANGE_MATERIAL_FORM_DATA`;
const CHANGE_MATERIAL_SEARCH = `${PATH}CHANGE_MATERIAL_SEARCH`;

// Global constants for saga
export const MAINTAINERS = {
  FETCH_DATA: `${PATH}FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  FETCH_DATA_FAIL: `${PATH}FETCH_DATA_FAIL`,
  UPDATE_CURRENCIES: `${PATH}UPDATE_CURRENCIES`,
  UPDATE_CURRENCIES_SUCCESS: `${PATH}UPDATE_CURRENCIES_SUCCESS`,
  UPDATE_CURRENCIES_FAIL: `${PATH}UPDATE_CURRENCIES_FAIL`,
  CREATE_USER: `${PATH}CREATE_USER`,
  CREATE_USER_SUCCESS: `${PATH}CREATE_USER_SUCCESS`,
  CREATE_USER_FAIL: `${PATH}CREATE_USER_FAIL`,
  UPDATE_USER: `${PATH}UPDATE_USER`,
  UPDATE_USER_SUCCESS: `${PATH}UPDATE_USER_SUCCESS`,
  UPDATE_USER_FAIL: `${PATH}UPDATE_USER_FAIL`,
  DISABLE_USER: `${PATH}DISABLE_USER`,
  DISABLE_USER_SUCCESS: `${PATH}DISABLE_USER_SUCCESS`,
  DISABLE_USER_FAIL: `${PATH}DISABLE_USER_FAIL`,
  ENABLE_USER: `${PATH}ENABLE_USER`,
  ENABLE_USER_SUCCESS: `${PATH}ENABLE_USER_SUCCESS`,
  ENABLE_USER_FAIL: `${PATH}ENABLE_USER_FAIL`,
  GET_INTERNAL_MATERIALS: `${PATH}GET_INTERNAL_MATERIALS`,
  GET_INTERNAL_MATERIALS_SUCCESS: `${PATH}GET_INTERNAL_MATERIALS_SUCCESS`,
  GET_INTERNAL_MATERIALS_FAIL: `${PATH}GET_INTERNAL_MATERIALS_FAIL`,
  CREATE_MATERIAL: `${PATH}CREATE_MATERIAL`,
  CREATE_MATERIAL_SUCCESS: `${PATH}CREATE_MATERIAL_SUCCESS`,
  CREATE_MATERIAL_FAIL: `${PATH}CREATE_MATERIAL_FAIL`,
  UPDATE_MATERIAL: `${PATH}UPDATE_MATERIAL`,
  UPDATE_MATERIAL_SUCCESS: `${PATH}UPDATE_MATERIAL_SUCCESS`,
  UPDATE_MATERIAL_FAIL: `${PATH}UPDATE_MATERIAL_FAIL`,
  GET_INTERNAL_FORMULAS: `${PATH}GET_INTERNAL_FORMULAS`,
  GET_INTERNAL_FORMULAS_SUCCESS: `${PATH}GET_INTERNAL_FORMULAS_SUCCESS`,
  GET_INTERNAL_FORMULAS_FAIL: `${PATH}GET_INTERNAL_FORMULAS_FAIL`,
};

// actions
export const maintainersActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  changeCurrenciesData: createAction(CHANGE_CURRENCIES_DATA, 'key', 'value'),
  toggleModals: createAction(TOGGLE_MODALS, 'module'),
  changeUserFormData: createAction(CHANGE_USER_FORM_DATA, 'key', 'value'),
  changeMaterialFormData: createAction(CHANGE_MATERIAL_FORM_DATA, 'key', 'value'),
  changeMaterialSearch: createAction(CHANGE_MATERIAL_SEARCH, 'key', 'value'),
  // api
  fetchData: createAction(MAINTAINERS.FETCH_DATA),
  fetchDataSuccess: createAction(MAINTAINERS.FETCH_DATA_SUCCESS, 'payload'),
  fetchDataFail: createAction(MAINTAINERS.FETCH_DATA_FAIL, 'payload'),
  updateCurrencies: createAction(MAINTAINERS.UPDATE_CURRENCIES, 'payload'),
  updateCurrenciesSuccess: createAction(MAINTAINERS.UPDATE_CURRENCIES_SUCCESS, 'payload'),
  updateCurrenciesFail: createAction(MAINTAINERS.UPDATE_CURRENCIES_FAIL, 'payload'),
  createUser: createAction(MAINTAINERS.CREATE_USER, 'payload'),
  createUserSuccess: createAction(MAINTAINERS.CREATE_USER_SUCCESS, 'payload'),
  createUserFail: createAction(MAINTAINERS.CREATE_USER_FAIL, 'payload'),
  updateUser: createAction(MAINTAINERS.UPDATE_USER, 'payload'),
  updateUserSuccess: createAction(MAINTAINERS.UPDATE_USER_SUCCESS, 'payload'),
  updateUserFail: createAction(MAINTAINERS.UPDATE_USER_FAIL, 'payload'),
  disableUser: createAction(MAINTAINERS.DISABLE_USER, 'payload'),
  disableUserSuccess: createAction(MAINTAINERS.DISABLE_USER_SUCCESS, 'payload'),
  disableUserFail: createAction(MAINTAINERS.DISABLE_USER_FAIL, 'payload'),
  enableUser: createAction(MAINTAINERS.ENABLE_USER, 'payload'),
  enableUserSuccess: createAction(MAINTAINERS.ENABLE_USER_SUCCESS, 'payload'),
  enableUserFail: createAction(MAINTAINERS.ENABLE_USER_FAIL, 'payload'),
  getInternalMaterials: createAction(MAINTAINERS.GET_INTERNAL_MATERIALS, 'payload'),
  getInternalMaterialsSuccess: createAction(MAINTAINERS.GET_INTERNAL_MATERIALS_SUCCESS, 'payload'),
  getInternalMaterialsFail: createAction(MAINTAINERS.GET_INTERNAL_MATERIALS_FAIL, 'payload'),
  createMaterial: createAction(MAINTAINERS.CREATE_MATERIAL, 'payload', 'callback'),
  createMaterialSuccess: createAction(MAINTAINERS.CREATE_MATERIAL_SUCCESS, 'payload'),
  createMaterialFail: createAction(MAINTAINERS.CREATE_MATERIAL_FAIL, 'payload'),
  updateMaterial: createAction(MAINTAINERS.UPDATE_MATERIAL, 'payload', 'callback'),
  updateMaterialSuccess: createAction(MAINTAINERS.UPDATE_CURRENCIES_SUCCESS, 'payload'),
  updateMaterialFail: createAction(MAINTAINERS.UPDATE_CURRENCIES_FAIL, 'payload'),
  getInternalFormulas: createAction(MAINTAINERS.GET_INTERNAL_FORMULAS, 'payload'),
  getInternalFormulasSuccess: createAction(MAINTAINERS.GET_INTERNAL_FORMULAS_SUCCESS, 'payload'),
  getInternalFormulasFail: createAction(MAINTAINERS.GET_INTERNAL_FORMULAS_FAIL, 'payload'),
};

const initialUserForm = {
  id: '',
  name: '',
  email: '',
  type: 'user',
  position: '',
  phone: '',
  password: '',
  species: [],
};

const initialMaterialForm = {
  id: '',
  code: '',
  name: '',
  umb: null,
  cost: '',
  deleted: '',
  updatedAt: '',
};

const defaultControls = {
  currencies: {
    dollarKardex: '',
    dollarZcot: '',
    lastUpdate: '',
  },
  materialSearch: {
    materialId: '',
  },
  userForm: {
    ...initialUserForm,
  },
  materialForm: {
    ...initialMaterialForm,
  },
};

const defaultModals = {
  userForm: false,
  materialForm: false,
};

const initialState = {
  error: false,
  errorMsg: '',
  loading: false,
  fetchSuccess: false,
  fetchError: false,
  disabledTable: false,
  filteredIds: {
    materialIds: [],
    costIds: [],
    formulaIds: [],
  },
  count: 0,
  controls: {
    ...defaultControls,
  },
  modals: { ...defaultModals },
};

const maintainers = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
    state.errorMsg = '';
  },
  [RESET]: (state) => ({
    ...state,
    controls: defaultControls,
  }),
  [TOGGLE_MODALS]: (state, action) => ({
    ...state,
    modals: {
      ...state.modals,
      [action.module]: !state.modals[action.module],
    },
  }),
  [CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [CHANGE_USER_FORM_DATA]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      userForm: {
        ...state.controls.userForm,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_MATERIAL_SEARCH]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      materialSearch: {
        ...state.controls.materialSearch,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_MATERIAL_FORM_DATA]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      materialForm: {
        ...state.controls.materialForm,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_CURRENCIES_DATA]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      currencies: {
        ...state.controls.currencies,
        [action.key]: action.value,
      },
    },
  }),
  [MAINTAINERS.FETCH_DATA]: (state) => ({
    ...state,
    loading: true,
  }),
  [MAINTAINERS.FETCH_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    fetchSuccess: true,
    controls: {
      ...state.controls,
      currencies: {
        ...state.controls.currencies,
        dollarKardex: `${action.payload.objects.currencies['2'].exchange_rate}`,
        dollarZcot: `${action.payload.objects.currencies['3'].exchange_rate}`,
        lastUpdate: action.payload.objects.currencies['2'].updatedAt,
      },
    },
  }),
  [MAINTAINERS.FETCH_DATA_FAIL]: (state) => ({
    ...state,
    loading: false,
    fetchError: true,
  }),
  [MAINTAINERS.UPDATE_CURRENCIES_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      currencies: {
        ...state.controls.currencies,
        lastUpdate: action.payload.objects.currencies['2'].updatedAt,
      },
    },
  }),
  [MAINTAINERS.CREATE_USER_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      userForm: {
        ...initialUserForm,
      },
    },
    modals: {
      ...state.modals,
      userForm: false,
    },
  }),
  [MAINTAINERS.UPDATE_USER_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      userForm: {
        ...initialUserForm,
      },
    },
    modals: {
      ...state.modals,
      userForm: false,
    },
  }),
  [MAINTAINERS.DISABLE_USER]: (state) => ({
    ...state,
    disabledTable: true,
  }),
  [MAINTAINERS.DISABLE_USER_SUCCESS]: (state) => ({
    ...state,
    disabledTable: false,
  }),
  [MAINTAINERS.DISABLE_USER_FAIL]: (state) => ({
    ...state,
    disabledTable: false,
  }),
  [MAINTAINERS.ENABLE_USER]: (state) => ({
    ...state,
    disabledTable: true,
  }),
  [MAINTAINERS.ENABLE_USER_SUCCESS]: (state) => ({
    ...state,
    disabledTable: false,
  }),
  [MAINTAINERS.ENABLE_USER_FAIL]: (state) => ({
    ...state,
    disabledTable: false,
  }),
  // Internal Materials
  [MAINTAINERS.GET_INTERNAL_MATERIALS]: (state) => ({
    ...state,
    disabledTable: true,
  }),
  [MAINTAINERS.GET_INTERNAL_MATERIALS_SUCCESS]: (state, action) => ({
    ...state,
    fetchSuccess: true,
    disabledTable: false,
    filteredIds: {
      ...state.filteredIds,
      ...action.payload.filteredIds,
    },
    count: action.payload.count,
    controls: {
      ...state.controls,
    },
  }),
  [MAINTAINERS.GET_INTERNAL_MATERIALS_FAIL]: (state) => ({
    ...state,
    // loading: false,
    disabledTable: false,
    // fetchError: true,
  }),
  [MAINTAINERS.CREATE_MATERIAL_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      materialForm: {
        ...initialMaterialForm,
      },
    },
    modals: {
      ...state.modals,
      materialForm: false,
    },
  }),
  [MAINTAINERS.UPDATE_MATERIAL_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      materialForm: {
        ...initialMaterialForm,
      },
    },
    modals: {
      ...state.modals,
      materialForm: false,
    },
  }),
  [MAINTAINERS.GET_INTERNAL_FORMULAS]: (state) => ({
    ...state,
    disabledTable: true,
  }),
  [MAINTAINERS.GET_INTERNAL_FORMULAS_SUCCESS]: (state, action) => ({
    ...state,
    fetchSuccess: true,
    disabledTable: false,
    filteredIds: {
      ...state.filteredIds,
      ...action.payload.filteredIds,
    },
    count: action.payload.count,
    controls: {
      ...state.controls,
    },
  }),
});

export default maintainers;
