import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import ButtonWithLoader from '../ButtonWithLoader';

const IconButtonWithConfirm = function (props) {
  const {
    loaders,
    children,
    callback,
    buttonId,
    title = 'Confirmar',
    text = '',
    confirmLabel = 'Aceptar',
    cancelLabel = 'Cancelar',
  } = props;
  const [open, setOpen] = useState(false);
  const [calling, setCalling] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const loading = loaders[buttonId];

  const toggleOpen = (e) => {
    if (loading) return;
    setAnchorEl(e.currentTarget);
    setOpen((old) => !old);
  };

  useEffect(() => {
    if (loading) {
      setCalling(true);
    }
    if (calling && !loading) {
      setCalling(false);
      setOpen(false);
    }
  }, [loading, calling]);

  return (
    <>
      <IconButton onClick={toggleOpen}>{children}</IconButton>
      <Popover
        placement="top-start"
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1300 }}
        disablePortal
        // modifiers={modifiers}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        <Paper>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography textAlign="end">{text}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleOpen}>{cancelLabel}</Button>
            <ButtonWithLoader buttonId={buttonId} onClick={callback}>
              {confirmLabel}
            </ButtonWithLoader>
          </DialogActions>
        </Paper>
      </Popover>
    </>
  );
};

const withConnect = connect(selectState('app.loaders'), null, null, { context: ReactReduxContext });

export default compose(withConnect)(IconButtonWithConfirm);
