import { Grid, TextField } from '@mui/material';
import NumberField from 'commons/components/NumberField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import React, { useCallback } from 'react';
import { useForm } from 'utils/hooks/useForm';
import { isNumber } from 'utils/math';
import { valueHasData } from 'utils/validationsHelper';

const FormulaVariableCostsForm = function ({ variableCosts, actions }) {
  const { name, cost } = variableCosts;
  const { onChange, onSubmit, errors } = useForm(variableCosts, null, {
    onChange: actions.changeVariableCostsData,
    validations: {
      name: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un nombre. ',
        },
      ],
      cost: [
        {
          check: (value) => valueHasData(value),
          message: 'Debe ingresar un valor. ',
        },
        {
          check: (value) => isNumber(value),
          message: 'El valor debe ser numérico. ',
        },
      ],
    },
  });

  const handleAddVariableCost = useCallback(() => {
    onSubmit(() => actions.addVariableCost(variableCosts));
  }, [actions, variableCosts]);

  return (
    <Grid container spacing={2} sx={{ mb: '20px' }}>
      <Grid item xs={12}>
        <TextField
          id="name"
          label="Nombre / descripción"
          variant="outlined"
          value={name}
          onChange={onChange}
          error={errors.name?.length > 0}
          helperText={errors.name ? errors?.name[0] : null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberField
          id="cost"
          label="Costo (dólares)"
          variant="outlined"
          value={cost}
          onChange={onChange}
          error={errors.cost?.length > 0}
          helperText={errors.cost ? errors?.cost[0] : null}
          fullWidth
        />
      </Grid>
      <Grid item>
        <ButtonWithLoader
          buttonId="add-variable-cost"
          sx={{ height: '55px' }}
          onClick={handleAddVariableCost}>
          Agregar Costo
        </ButtonWithLoader>
      </Grid>
    </Grid>
  );
};

export default FormulaVariableCostsForm;
