import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import produce from 'immer';

// Material
import { CardContent, CircularProgress, Typography, Snackbar, CardMedia } from '@mui/material';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

// Project
import { getParameterByName, composedComponent } from '../../utils/functions';
import { useForm } from '../../utils/hooks/useForm';
import { appActions } from '../../commons/reducer';
import styles from './styles';
import saga from './saga';
import { loginActions } from './reducer';
// import TcitLogo from '../../commons/assets/images/TcitLogo.png';
import VTQLogo from '../../commons/assets/images/logo-veterquimica.png';

const Login = function (props) {
  const { classes, controls, user, actions, error, errorMsg, success, successMsg } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  const onChangeText = useCallback((id, value) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[id] = value?.trim();
      }),
    );
  }, []);

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Contraseña obligatoria',
        },
      ],
      email: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Email obligatorio',
        },
      ],
    },
  });

  function onClickLogin(event) {
    event.preventDefault();
    onSubmit(() => actions.login(state, controls.redirectUrl));
  }

  useEffect(() => {
    const token = getParameterByName('token', location.search);
    if (token) {
      actions.verify(token);
    }
  }, [actions, location.seaprops, location.search]);

  return (
    <div>
      <div>
        <Grid container justifyContent="center" alignItems="center" className={classes.content}>
          <Grid item md={4} lg={4} xs={12}>
            <Card>
              <CardMedia image={VTQLogo} />
              <CardContent>
                <form onSubmit={onClickLogin}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={10} textAlign="center">
                      <img style={{ height: 90 }} src={VTQLogo} alt="" />
                    </Grid>
                    <Grid item xs={10} textAlign="center">
                      <Typography variant="caption">
                        <p style={{ fontSize: '1rem' }}>Inicia sesión</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={10} textAlign="center">
                      <TextField
                        className={classes.field}
                        autoFocus
                        label="Correo electrónico"
                        id="email"
                        value={state.email}
                        onChange={onChange}
                        style={{ paddingBottom: '8px' }}
                        fullWidth
                        error={errors?.email?.length > 0}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={10} textAlign="center">
                      <TextField
                        className={classes.field}
                        label="Contraseña"
                        onChange={onChange}
                        value={state.password}
                        id="password"
                        fullWidth
                        type="password"
                        error={errors?.password?.length > 0}
                        helperText={errors.password}
                      />
                    </Grid>
                    {controls.error && (
                      <Grid item xs={10} textAlign="center">
                        <Typography style={{ color: 'red' }} align="center" variant="caption">
                          Credenciales inválidas
                        </Typography>
                      </Grid>
                    )}
                    {/*
                    <Grid item xs={10} className={classes.alignRight} style={{ marginTop: '24px' }}>
                      <Typography variant="caption" color="primary">
                        <Link
                          to="/sign_up"
                          style={{ textDecoration: 'none', color: 'black', fontSize: '0,7rem' }}
                          variant="inherit"
                          color="primary"
                        >
                          ¿No tienes cuenta?
                          {' '}
                          <strong>Regístrate</strong>
                        </Link>
                      </Typography>
                    </Grid> */}
                    <Grid item xs={10} textAlign="center">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        style={{ marginTop: '5px' }}>
                        {controls.loading ? <CircularProgress /> : 'Ingresar'}
                      </Button>
                    </Grid>
                    <Grid item xs={10} textAlign="center">
                      <Typography variant="caption" color="primary">
                        <Link to="/recovery" style={{ textDecoration: 'none' }}>
                          <Button variant="outlined">Recuperar contraseña</Button>
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={actions.toggleError}>
        <Alert onClose={actions.toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={actions.toggleSuccess}>
        <Alert onClose={actions.toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default composedComponent(Login, saga, {
  saga: 'sagaLogin',
  middlewares: [withStyles(styles)],
  states: [
    'login.controls',
    'app.user',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
  ],
  actions: [loginActions, appActions],
});
