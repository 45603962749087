import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const PageLoading = function () {
  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 120px)' }}>
      <Backdrop open invisible sx={{ position: 'absolute', zIndex: 1000, opacity: 0.1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default PageLoading;
