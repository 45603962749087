import { Typography } from '@mui/material';
import ModalLayout from 'commons/components/ModalLayout';
import React from 'react';
import FormulaVariableCostsForm from '../FormulaVariableCostsForm';
import FormulaVariableCostsTable from '../FormulaVariableCostsTable';

const ModalVariableCosts = function (props) {
  const { classes, open, actions, variableCosts, toggleModalFnc, tableData, deleteApiResponse } =
    props;
  return (
    <ModalLayout
      open={open}
      toggleModalFnc={toggleModalFnc}
      width="80%"
      maxWidth="800px"
      minHeight="400px"
      overflowY="scroll">
      <Typography variant="h3" mb="20px">
        Agregar nuevo costo variable
      </Typography>
      <FormulaVariableCostsForm actions={actions} variableCosts={variableCosts} />
      <Typography variant="h5" mb="20px">
        Detalle costos variables
      </Typography>
      <FormulaVariableCostsTable
        classes={classes}
        actions={actions}
        tableData={tableData}
        deleteApiResponse={deleteApiResponse}
      />
    </ModalLayout>
  );
};

export default ModalVariableCosts;
