/* eslint-disable no-param-reassign */

import XLSX from 'xlsx';
import * as XLSX_STYLE from 'xlsx-js-style';
import { formatDateSlash } from './dateHelpers';
import baseFormula from './excelTemplates/formula';
import baseHistoric from './excelTemplates/historic';
import baseUpload from './excelTemplates/upload';
import { numberBetween } from './validationsHelper';

// Descargar fórmula
export const setData = (data, callback, base = []) => {
  const processedData = data.map(callback);
  return [...base, ...processedData];
};

const addStyleWorksheet = (ws, callback) => callback(ws);

export const generateXLSX = (template, fileName, styleCallback) => {
  /* convert state to workbook */
  const { base: data } = template;
  const ws = XLSX.utils.aoa_to_sheet(data);

  // Adding styles, col/row sizes, etc.
  const { wsmerges, wscols, wsrows } = template;
  if (wsmerges) ws['!merges'] = wsmerges;
  const styledWs = template?.style && styleCallback ? addStyleWorksheet(ws, styleCallback) : ws;
  if (wscols) ws['!cols'] = wscols;
  if (wsrows) ws['!rows'] = wsrows;

  const wb = XLSX_STYLE.utils.book_new();
  XLSX_STYLE.utils.book_append_sheet(wb, styledWs, 'SheetJS');
  /* generate XLSX file and send to client */
  XLSX_STYLE.writeFile(wb, `${fileName}.xlsx`);
};

const addStyleToTemplateUpload = (ws) => {
  const { style } = baseFormula();
  const { title, labelData, valueData, tableHeader } = style;
  ws.D1.s = title;
  for (let i = 2; i <= 7; i += 1) {
    ws[`C${i}`].s = labelData;
    ws[`D${i}`].s = valueData;
    ws[`E${i}`].s = labelData;
    ws[`F${i}`].s = valueData;
  }
  const HEADER_TABLE_COLS = 3;
  for (let i = 0; i < HEADER_TABLE_COLS; i += 1) {
    const character = (i + 12).toString(36).toUpperCase();
    ws[`${character}9`].s = tableHeader;
  }
  return ws;
};

const addStyleToTemplate = (ws) => {
  const { style } = baseFormula();
  const { title, labelData, valueData, tableHeader } = style;
  ws.D1.s = title;
  for (let i = 2; i <= 7; i += 1) {
    ws[`C${i}`].s = labelData;
    ws[`D${i}`].s = valueData;
    ws[`E${i}`].s = labelData;
    ws[`F${i}`].s = valueData;
  }
  const HEADER_TABLE_COLS = 8;
  for (let i = 0; i < HEADER_TABLE_COLS; i += 1) {
    const character = (i + 11).toString(36).toUpperCase();
    ws[`${character}9`].s = tableHeader;
  }
  return ws;
};

// Descarga plantilla base
export const downloadBaseTemplate = () => {
  const template = baseUpload();
  generateXLSX(template, 'PLANTILLA_BASE', addStyleToTemplateUpload);
};

// Exportar fórmula a excel
export const downloadFormula = (info, data) => {
  const template = baseFormula(info.c_material, info.description, info.specie);

  const callback = (m, i) => [
    '',
    i + 1,
    m.component,
    m.name,
    Number(m.subQuantity.toFixed(2)),
    m.unit,
    m.quant_base,
    m.base_unit,
    '',
  ];
  const sortedData = [...data].sort((a, b) => a.component - b.component);
  const isPackageCallback = (d) => numberBetween(d.component, 200_000, 299_999);
  const isMaterialCallback = (d) => !numberBetween(d.component, 200_000, 299_999);
  const packages = sortedData.filter(isPackageCallback);
  const materials = sortedData.filter(isMaterialCallback);
  const sortedDataPackageAtEnd = [...(materials || []), ...(packages || [])];
  const settedData = setData(sortedDataPackageAtEnd, callback, template?.base);
  template.base = settedData;
  generateXLSX(template, `FORMULA-${info.c_material}`, addStyleToTemplate);
};

// Carga de fórmula
export const xlsxToJson = async (file) => {
  if (!file) return null;
  const fileReader = new FileReader(file);
  return new Promise((resolve, reject) => {
    fileReader.onload = (e) => {
      try {
        const data = e?.target.result;
        const workbook = XLSX.readFile(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 'A', blankrows: true });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    };
    fileReader.readAsArrayBuffer(file);
  });
};

const jsonToObject = (json) => {
  const START_OF_LIST_INDEX = 9;
  const array = Object.values(json)
    .map((item) => ({
      component_id: item?.C,
      quantity: item?.D,
      unit: item?.E,
    }))
    .slice(START_OF_LIST_INDEX)
    .filter((row) => row?.component_id || row?.quantity || row?.unit);
  const mainMaterial = {
    name: json['1']?.D || '',
    quant_base: json['2']?.D,
    umb: json['3']?.D,
    specie: json['4']?.D,
  };
  return {
    mainMaterial,
    array,
  };
};

export const processFormulaToBeSent = async (file) => {
  const json = await xlsxToJson(file);
  if (!json) return null;
  return jsonToObject(json);
};

// Export Historic Data

export const downloadHistoric = (data) => {
  const template = baseHistoric();

  const callback = (item) => [
    item.Quote.id,
    item.Quote.User.name,
    item.c_material,
    item.name,
    Number(item.cost?.toFixed(2)),
    item.Quote.Currency.code,
    item.margin,
    Number(item.price?.toFixed(2)),
    Number(item.volume?.toFixed(2)),
    Number(item.subtotal?.toFixed(2)),
    item.adjudication_perc,
    item.Quote.client_name,
    formatDateSlash(item.Quote.createdAt),
  ];
  const settedData = setData(data, callback, template?.base);
  template.base = settedData;
  generateXLSX(template, `HISTORICO`, null);
};
