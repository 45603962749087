// React imports
import React, { useCallback } from 'react';
// Material imports
import NumberFormat from 'react-number-format';
import { InputAdornment, TextField } from '@mui/material';
import { unformatNumber } from 'utils/math';

const FormatComponent = React.forwardRef((props, ref) => {
  const { integer, maxDecimals, maxValue, negative, onChange, ...rest } = props;
  const onChangeHandler = useCallback(
    (event) => {
      // eslint-disable-next-line no-param-reassign
      event.target.value = unformatNumber(event.target.value);
      event.persist();
      onChange(event);
    },
    [onChange],
  );

  return (
    <NumberFormat
      thousandSeparator="."
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={onChangeHandler}
      decimalSeparator=","
      getInputRef={ref}
      decimalScale={integer ? 0 : maxDecimals}
      allowNegative={negative}
      isNumericString
      allowEmptyFormatting
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= maxValue;
      }}
    />
  );
});

const NumberField = function ({
  value,
  id,
  onChange = null,
  label = null,
  integer = false,
  negative = false,
  maxDecimals = 2,
  maxValue = 2_147_483_647,
  symbol = null,
  symbolPosition = 'start',
  InputProps = null,
  fullWidth = false,
  autoComplete = 'off',
  thousandSeparator = '.',
  ...rest
}) {
  return (
    <TextField
      symbol={symbol}
      fullWidth={fullWidth}
      label={label}
      id={id}
      onChange={onChange}
      value={value}
      autoComplete={autoComplete}
      InputProps={{
        ...InputProps,
        [`${symbolPosition}Adornment`]: symbol ? (
          <InputAdornment position={symbolPosition}>{symbol}</InputAdornment>
        ) : undefined,
        inputComponent: FormatComponent,
        inputProps: { id, integer, negative, maxDecimals, maxValue, thousandSeparator },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default NumberField;
