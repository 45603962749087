import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Material
import { withStyles } from '@mui/styles';

// Project
import { composedComponent } from 'utils/functions';
// import FetchLoading from 'commons/components/FetchLoading';
import { sortByKeyDesc } from 'utils/sortHelpers';
import PageLoading from 'commons/components/PageLoading';
import { appActions } from 'commons/reducer';
import { historicActions } from './reducer';
import saga from './saga';

// Components

// Utils

// Styles
import styles from './styles';
import HistoricTable from './components/HistoricTable';
import HistoricFilters from './components/HistoricFilters';
import HistoricViewDetailsModal from './components/HistoricViewDetailsModal';
import HistoricEditDetailsModal from './components/HistoricEditDetailsModal';

const Historic = function (props) {
  const {
    classes,
    controls,
    actions,
    objects,
    loading,
    fetchSuccess,
    // fetchError,
    count,
    filteredIds,
    modals,
  } = props;
  const [pagination, setPagination] = useState({
    rowsPerPage: 5,
    page: 0,
  });
  useEffect(() => {
    actions.fetchData({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      ...(controls.materialId ? { materialId: controls.materialId } : {}),
    });
  }, [actions]);

  const handleSearch = useCallback(() => {
    setPagination((old) => ({
      ...old,
      page: 0,
    }));
  }, []);

  useEffect(() => {
    if (!fetchSuccess) return;
    actions.fetchData({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      ...(controls.materialId ? { materialId: controls.materialId } : {}),
    });
  }, [pagination]);

  const quoteMaterials = useMemo(
    () => Object.values(objects.quoteMaterials),
    [objects.quoteMaterials],
  );
  const filteredQuoteMaterials = useMemo(
    () =>
      quoteMaterials
        .filter((qm) => filteredIds.quoteMaterialIds.includes(qm.id))
        .sort(sortByKeyDesc('id')),
    [quoteMaterials, filteredIds],
  );
  // console.log('filteredQuotes', filteredQuotes);
  // console.log('filteredQuotesMaterials', filteredQuoteMaterials);
  // console.log('pagination', pagination);

  const handleChangePage = useCallback((event, newPage) => {
    setPagination((old) => ({
      ...old,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setPagination({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  }, []);

  const handleChange = useCallback(
    (key, value) => {
      actions.changeControls2(key, value);
    },
    [actions],
  );

  return (
    // <FetchLoading loading={loading} success={fetchSuccess} error={fetchError}>
    <div>
      {
        fetchSuccess ? (
          <>
            <HistoricFilters
              controls={controls}
              loading={loading}
              classes={classes}
              actions={actions}
              handleChange={handleChange}
              handleSearch={handleSearch}
            />
            <HistoricTable
              classes={classes}
              actions={actions}
              loading={loading}
              rows={filteredQuoteMaterials}
              count={count}
              pagination={pagination}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <HistoricViewDetailsModal
              open={modals.viewDetails}
              toggleModalFnc={() => actions.changeModals('viewDetails')}
              data={controls.quoteDetails}
            />
            <HistoricEditDetailsModal
              open={modals.editDetails}
              toggleModalFnc={() => actions.changeModals('editDetails')}
              actions={actions}
              data={controls.quoteDetails}
            />
          </>
        ) : (
          <PageLoading />
        )
        // </FetchLoading>
      }
    </div>
  );
};

export default composedComponent(Historic, saga, {
  saga: 'sagaHistoric',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'historic.controls',
    'historic.invalidControls',
    'historic.loading',
    'historic.fetchSuccess',
    'historic.fetchError',
    'historic.count',
    'historic.filteredIds',
    'historic.modals',
  ],
  actions: [historicActions, appActions],
});
