// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
// material
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const ButtonWithLoader = function (props) {
  const {
    loaders,
    buttonId,
    color,
    onClick,
    disabled,
    startIcon,
    autoFocus,
    fullWidth,
    className,
    size,
    children,
    type,
    variant,
    sx,
  } = props;
  const loading = loaders[buttonId];

  return (
    <Button
      type={type || 'button'}
      color={color || 'primary'}
      onClick={onClick}
      variant={variant || 'contained'}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={15} /> : startIcon}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      className={className}
      sx={sx}
      size={size}>
      {children}
    </Button>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withConnect = connect(selectState('app.loaders'), null, null, { context: ReactReduxContext });

export default compose(withConnect)(ButtonWithLoader);
