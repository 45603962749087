import React from 'react';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import Paths from 'utils/paths';
import Auth from './Auth';

const Routes = function ({ user, loading }) {
  const { shared } = Paths;
  const userRoute = Paths[user?.type];
  return (
    <Switch>
      {shared.map(({ path, exact, component: Component }) => (
        <Route key={path} exact={exact} path={path} element={<Component />} />
      ))}
      {/* <Auth user={user} loading={loading}> */}
      {userRoute?.map(({ path, exact, component: Component }) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          element={
            <Auth user={user} loading={loading}>
              <Component />
            </Auth>
          }
        />
      ))}
      {/* </Auth> */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Switch>
  );
};

export default Routes;
