import React from 'react';
import { useNavigate } from 'react-router-dom';

// material
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Project
import { Avatar, Grid, Tooltip } from '@mui/material';
import styles from './styles';
import VTQLogo from '../../assets/images/vtq-logo-horizontal.png';

const useStyles = makeStyles(styles);

const NavBar = function (props) {
  const { logOut, user } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  function sessionOption() {
    if (user) {
      return (
        <Grid item>
          <Grid container wrap="nowrap">
            <Tooltip title={user?.user?.name} placement="left">
              <Avatar sx={{ mr: '10px' }}>{user?.user?.name[0]}</Avatar>
            </Tooltip>
            <Button onClick={logOut} color="inherit">
              Cerrar sesión
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Button onClick={() => navigate('/login')} color="inherit">
        Iniciar sesión
      </Button>
    );
  }

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      color="inherit"
      style={{ marginBottom: '16px' }}>
      <Toolbar>
        <input
          type="image"
          style={{ height: '40px', cursor: 'pointer' }}
          src={VTQLogo}
          alt=""
          onClick={() => navigate('/')}
        />
        <Typography variant="h6" className={classes.title} />
        {sessionOption()}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
