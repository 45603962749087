import camelize from 'camelcase-keys-deep';

export default class Quote {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  client() {
    return this.clientId ? this.env().Client.findBy('id', this.clientId) : null;
  }

  currency() {
    return this.currencyId ? this.env().Currency.findBy('id', this.currencyId) : null;
  }

  user() {
    return this.userId ? this.env().User.findBy('id', this.userId) : null;
  }
}
