import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import { composedComponent } from 'utils/functions';

// commons
import { appActions } from '../commons/reducer';
import saga from '../commons/saga';

// Routes
import Routes from './components/Routes';

// Theme
import theme from '../utils/customTheme';

// const Alert = React.forwardRef((props, ref) => (
//   <MuiAlert elevation={6} variant="filled" ref={ref} />
// ));

const App = function (props) {
  const { actions, error, errorMsg, success, successMsg, loadingUser, user } = props;
  // console.log('mensaje de error', errorMsg);
  useEffect(() => {
    actions.getUser();
  }, []);

  const toggleError = () => {
    actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.toggleSuccess();
  };

  return loadingUser ? (
    <div />
  ) : (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={toggleSuccess}>
        <Alert onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <Routes user={user?.user} loading={loadingUser} />
    </ThemeProvider>
  );
};

export default composedComponent(App, saga, {
  saga: 'sagaApp',
  states: [
    'app.drawerIsOpen',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.loadingUser',
  ],
  actions: [appActions],
});
