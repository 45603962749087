import { takeLatest, spawn, put } from 'redux-saga/effects';
import apiRequest, { apiSuccess } from 'utils/api';

import { TODO } from '../reducer';

function* getAllTodos() {
  yield takeLatest(TODO.GET_TODOS, function* getAllTodosApi() {
    const response = yield apiRequest('api/v1/todos', { method: 'GET' });

    if (response.error) {
      yield put(apiSuccess(TODO.GET_TODOS_FAIL, response.error));
    }

    yield put(apiSuccess(TODO.GET_TODOS_SUCCESS, response));
  });
}

function* createTodo() {
  yield takeLatest(TODO.CREATE_TODO, function* createTodoApi(action) {
    // @ts-ignore
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('api/v1/todos', { method: 'POST', body });

    if (response.error) {
      yield put(apiSuccess(TODO.CREATE_TODO_FAIL, response.error));
    }
    yield put(apiSuccess(TODO.CREATE_TODO_SUCCESS, response));
  });
}

function* checkTodo() {
  yield takeLatest(TODO.CHECK_TODO, function* checkTodoApi(action) {
    // @ts-ignore
    const id = JSON.stringify(action.controls);
    const response = yield apiRequest(`api/v1/todos/${id}`, { method: 'PATCH' });

    if (response.error) {
      yield put(apiSuccess(TODO.CHECK_TODO_FAIL, response.error));
    }
    yield put(apiSuccess(TODO.CHECK_TODO_SUCCESS, response));
  });
}

function* deleteTodo() {
  yield takeLatest(TODO.DESTROY_TODO, function* checkTodoApi(action) {
    // @ts-ignore
    const id = JSON.stringify(action.controls);
    const response = yield apiRequest(`api/v1/todos/${id}`, { method: 'DELETE' });

    if (response.error) {
      yield put(apiSuccess(TODO.DESTROY_TODO_FAIL, response.error));
    }
    yield put(apiSuccess(TODO.DESTROY_TODO_SUCCESS, { ...response, todoId: id }));
  });
}

export default function* root() {
  yield spawn(getAllTodos);
  yield spawn(createTodo);
  yield spawn(checkTodo);
  yield spawn(deleteTodo);
}
