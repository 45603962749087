import camelize from 'camelcase-keys-deep';

export default class QuoteMaterial {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  quote() {
    return this.quoteId ? this.env().Quote.findBy('id', this.quoteId) : null;
  }

  material() {
    return this.materialId ? this.env().Material.findBy('id', this.materialId) : null;
  }
}
