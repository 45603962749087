import React from 'react';
import PageLoading from '../PageLoading';

const FetchLoading = function ({ loading, success, error, children }) {
  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <div>Error!</div>;
  }

  if (success) {
    return children;
  }

  return <div>Error inesperado</div>;
};

export default FetchLoading;
