import React from 'react';
import { CircularProgress, Typography, Button, Grid } from '@mui/material';
import { useForm } from '../../../../utils/hooks/useForm';
import CustomField from '../../../../commons/components/CustomField';

const UpdatePasswordForm = function (props) {
  const { classes, onChangeText, controls, actions, password, verifyPassword, loading } = props;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => !!value,
          message: 'La contraseña es requerida',
        },
        {
          check: (value) => value != null && value.trim().length >= 6 && value.trim().length < 20,
          message: 'La contraseña debe tener entre 6 y 20 caracteres',
        },
        {
          check: (value) =>
            value != null && /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(value) === true,
          message: 'La contraseña debe tener minimo una mayúscula y un número',
        },
      ],
      verifyPassword: [
        {
          check: (value) => !!value,
          message: 'La verificación de contraseña es requerida',
        },
        {
          check: (value) => value === password,
          message: 'Las contraseñas deben coincidir',
        },
      ],
    },
  });

  const onClickUpdatePassword = (event) => {
    event.preventDefault();
    onSubmit(() => actions.updatePassword(controls));
  };

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };

  return (
    <form onSubmit={onClickUpdatePassword}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10}>
          <Typography variant="body1" component="div">
            Por favor, ingrese su nueva contraseña.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <CustomField
            className={classes.field}
            label="Nueva contraseña"
            key="password"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            style={{ paddingBottom: '8px', marginBottom: '5%' }}
            fullWidth
            error={errors.password}
            helperText={errors.password && errors.password[0]}
          />
          <CustomField
            className={classes.field}
            label="Repita su nueva contraseña"
            key="verifyPassword"
            id="verifyPassword"
            name="verifyPassword"
            type="password"
            value={verifyPassword}
            onChange={handleChange}
            style={{ paddingBottom: '8px', marginBottom: '5%' }}
            fullWidth
            error={errors.verifyPassword}
            helperText={errors.verifyPassword && errors.verifyPassword[0]}
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justifyContent="center"
          alignItems="center"
          className={classes.textAlign}>
          <Button type="submit" variant="contained" color="primary" className={classes.button}>
            {loading ? <CircularProgress className={classes.progress} /> : 'Guardar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdatePasswordForm;
