/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';
import { get, post, apiSuccess, objectToQueryString } from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';

// Reducer
import { appActions, GET_STATE_FROM_API_SUCCESS } from 'commons/reducer';
import { HISTORIC, historicActions } from '../reducer';

function* fetchData() {
  yield takeLatest(HISTORIC.FETCH_DATA, function* (action) {
    yield put(appActions.setLoader('search-quotes', true));
    // console.log('FETCH_DATA_SAGA');
    // El backend recibe la página desde el índice 1, y el paginador empieza desde 0, por eso sumamos 1.
    const clonedPayload = {
      ...action.payload,
      page: Number(action.payload.page) + 1,
    };

    const response = yield controlledCall(
      get,
      `api/v1/historics/?${objectToQueryString(clonedPayload)}`,
      '',
      historicActions.fetchDataSuccess,
      // historicActions.fetchDataFail,
    );
    if (!response.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(HISTORIC.FETCH_DATA_FAIL, response));
    }
    yield put(appActions.setLoader('search-quotes', false));
  });
}
function* getExcelData() {
  yield takeLatest(HISTORIC.GET_EXCEL_DATA, function* (action) {
    yield put(appActions.setLoader('get-excel-data', true));
    // console.log('GET_EXCEL_DATA_SAGA');
    const response = yield controlledCall(
      get,
      `api/v1/historics/excel-data?${objectToQueryString(action.payload)}`,
      '',
      historicActions.getExcelDataSuccess,
      // historicActions.getExcelDataFail,
    );
    if (!response.error) {
      action.callback(response.data);
    }
    yield put(appActions.setLoader('get-excel-data', false));
  });
}

function* updateQuoteMaterial() {
  yield takeLatest(HISTORIC.UPDATE_QUOTE_MATERIAL, function* (action) {
    yield put(appActions.setLoader('update-quote-material', true));
    // console.log('SUBMIT_SAGA');
    const result = yield controlledCall(
      post,
      `api/v1/historics/update-quote-line/`,
      action.payload,
      historicActions.updateQuoteMaterialSuccess,
      // historicActions.updateQuoteMaterialFail
    );
    if (!result.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, result));
      yield put(appActions.setSuccess('Registro actualizado'));
    }
    yield put(appActions.setLoader('update-quote-material', false));
  });
}

export default function* root() {
  yield spawn(fetchData);
  yield spawn(getExcelData);
  yield spawn(updateQuoteMaterial);
}
