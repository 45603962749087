/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createReducer } from 'utils/reducer';

// Local constants
// Global constants for saga
export const HOME = {};

// actions
export const homeActions = {};

const initialState = {};

const home = createReducer(initialState, {});

export default home;
