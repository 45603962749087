import React from 'react';
import { Grid, TextField } from '@mui/material';
// Dates
// import DateAdapter from '@mui/lab/AdapterMoment';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'moment/locale/es';
import { truthty } from 'utils/functions';
import CustomDatePicker from './CustomDatePicker';

const FormAbove = function (props) {
  const { controls, classes, onChange, errors } = props;
  return (
    <Grid container sx={{ maxWidth: '400px' }}>
      <Grid item mb="20px" sm={12}>
        <TextField
          id="title"
          label="Descripción"
          variant="outlined"
          value={controls.title}
          onChange={onChange}
          error={truthty(errors.title)}
          helperText={errors.title ? errors?.title[0] : null}
          inputProps={{ maxLength: 255 }}
          fullWidth
        />
      </Grid>
      <Grid item mb="20px" sm={12}>
        <TextField
          id="clientCode"
          label="Código cliente"
          variant="outlined"
          value={controls.clientCode}
          onChange={onChange}
          // onBlur={() => console.log('Blur!')}
          error={truthty(errors.clientCode)}
          helperText={errors.clientCode ? errors?.clientCode[0] : null}
          inputProps={{ maxLength: 6 }}
          fullWidth
          disabled={controls.clientName.length > 0}
        />
      </Grid>
      <Grid item mb="20px" sm={12}>
        <TextField
          id="clientName"
          label="Empresa cliente"
          variant="outlined"
          value={controls.clientName}
          onChange={onChange}
          error={truthty(errors.clientName)}
          helperText={errors.clientName ? errors?.clientName[0] : null}
          inputProps={{ maxLength: 255 }}
          fullWidth
          disabled={controls.clientCode.length > 0}
        />
      </Grid>
      <Grid item container mb="20px" sm={12} spacing={1}>
        <Grid item sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="esES">
            <CustomDatePicker
              id="startDate"
              label="Fecha inicial"
              value={controls.startDate}
              className={classes.mr10}
              onChange={onChange}
              error={errors.startDate?.length > 0}
              helperText={errors.startDate?.length ? errors?.startDate[0] : null}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="esES">
            <CustomDatePicker
              id="endDate"
              label="Fecha final"
              value={controls.endDate}
              className={classes.mr10}
              onChange={onChange}
              error={errors.endDate?.length > 0}
              helperText={errors.endDate?.length ? errors?.endDate[0] : null}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormAbove;
