/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

import * as APP from 'commons/reducer';

// Local constants
const PATH = 'historic/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_CONTROLS_2 = `${PATH}CHANGE_CONTROLS_2`;
const CHANGE_EDIT_DETAILS = `${PATH}CHANGE_EDIT_DETAILS`;
const CHANGE_MODALS = `${PATH}CHANGE_MODALS`;

// Global constants for saga
export const HISTORIC = {
  FETCH_DATA: `${PATH}FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${PATH}FETCH_DATA_SUCCESS`,
  FETCH_DATA_FAIL: `${PATH}FETCH_DATA_FAIL`,
  UPDATE_QUOTE_MATERIAL: `${PATH}UPDATE_QUOTE_MATERIAL`,
  UPDATE_QUOTE_MATERIAL_SUCCESS: `${PATH}UPDATE_QUOTE_MATERIAL_SUCCESS`,
  UPDATE_QUOTE_MATERIAL_FAIL: `${PATH}UPDATE_QUOTE_MATERIAL_FAIL`,
  GET_EXCEL_DATA: `${PATH}GET_EXCEL_DATA`,
  GET_EXCEL_DATA_SUCCESS: `${PATH}GET_EXCEL_DATA_SUCCESS`,
  GET_EXCEL_DATA_FAIL: `${PATH}GET_EXCEL_DATA_FAIL`,
};

// actions
export const historicActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  changeControls2: createAction(CHANGE_CONTROLS_2, 'key', 'value'),
  changeEditDetails: createAction(CHANGE_EDIT_DETAILS, 'key', 'value'),
  changeModals: createAction(CHANGE_MODALS, 'module'),
  // api
  fetchData: createAction(HISTORIC.FETCH_DATA, 'payload'),
  fetchDataSuccess: createAction(HISTORIC.FETCH_DATA_SUCCESS, 'payload'),
  fetchDataFail: createAction(HISTORIC.FETCH_DATA_FAIL, 'payload'),
  updateQuoteMaterial: createAction(HISTORIC.UPDATE_QUOTE_MATERIAL, 'payload'),
  updateQuoteMaterialSuccess: createAction(HISTORIC.UPDATE_QUOTE_MATERIAL_SUCCESS, 'payload'),
  updateQuoteMaterialFail: createAction(HISTORIC.UPDATE_QUOTE_MATERIAL_FAIL, 'payload'),
  getExcelData: createAction(HISTORIC.GET_EXCEL_DATA, 'payload', 'callback'),
  getExcelDataSuccess: createAction(HISTORIC.GET_EXCEL_DATA_SUCCESS, 'payload'),
  getExcelDataFail: createAction(HISTORIC.GET_EXCEL_DATA_FAIL, 'payload'),
};

const defaultControls = {
  materialId: '',
  materialName: '',
  foundMaterials: [],
  quoteDetails: {
    quoteId: '',
    quoteMaterialId: '',
    name: '',
    cMaterial: '',
    cost: '',
    margin: '',
    details: '',
    quoteState: '',
    quoteMaterialState: '',
    adjudicator: '',
    subtotal: '',
  },
};

const initialState = {
  error: false,
  errorMsg: '',
  loading: false,
  fetchSuccess: false,
  fetchError: false,
  filteredIds: {
    quoteIds: [],
    quoteMaterialIds: [],
  },
  count: 0,
  controls: {
    ...defaultControls,
  },
  modals: {
    viewDetails: false,
    editDetails: false,
  },
};

const historic = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
    state.errorMsg = '';
  },
  [RESET](state) {
    state.controls = { ...defaultControls };
  },
  [CHANGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [CHANGE_CONTROLS_2]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.key]: action.value,
    },
  }),
  [CHANGE_EDIT_DETAILS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      quoteDetails: {
        ...state.controls.quoteDetails,
        [action.key]: action.value,
      },
    },
  }),
  [CHANGE_MODALS]: (state, action) => {
    const { modals, controls } = state;
    const { module } = action;
    return {
      ...state,
      modals: {
        ...modals,
        [module]: !modals[module],
      },
      controls: {
        ...controls,
      },
    };
  },
  [HISTORIC.FETCH_DATA]: (state) => ({
    ...state,
    loading: true,
  }),
  [HISTORIC.FETCH_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    fetchSuccess: true,
    filteredIds: action.payload.filteredIds,
    count: action.payload.count,
    controls: {
      ...state.controls,
    },
  }),
  [HISTORIC.FETCH_DATA_FAIL]: (state) => ({
    ...state,
    loading: false,
    fetchError: true,
  }),
  [APP.GET_MATERIALS_BY_NAME]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      materialId: '',
    },
  }),
  // [APP.GET_MATERIALS_BY_NAME_SUCCESS]: (state, action) => ({
  //   ...state,
  //   controls: {
  //     ...state.controls,
  //     foundMaterials: action.payload.materials,
  //   },
  // }),
  [HISTORIC.UPDATE_QUOTE_MATERIAL_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      quoteDetails: {
        quoteId: '',
        quoteMaterialId: '',
        name: '',
        cMaterial: '',
        cost: '',
        margin: '',
        details: '',
        quoteState: '',
        quoteMaterialState: '',
        adjudicator: '',
        subtotal: '',
      },
    },
    modals: {
      ...state.modals,
      editDetails: false,
    },
  }),
});

export default historic;
