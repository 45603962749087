import React from 'react';
// Material
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// Icons
import ClearIcon from '@mui/icons-material/Clear';

const CostsTable = function (props) {
  const { classes, data, handleRemoveMaterial, lastMonthsLabels } = props;
  const lsParams = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  if (!data.length) return <div />;

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Detalle de materiales" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Código</TableCell>
            <TableCell align="left">Nombre</TableCell>
            {lastMonthsLabels?.map((month) => (
              <TableCell key={month} align="left">
                {month}
              </TableCell>
            ))}
            <TableCell align="left">ZCOT</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(data).map((row) => (
            <TableRow
              className={classes.zebra}
              key={`${row?.label}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row?.label}</TableCell>
              <TableCell align="left">{row?.name}</TableCell>
              {row?.data?.map((value, i) => (
                // TODO: Es recomendable usar un objeto con el key de cada mes-año, en vez de un array.
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`${i}-${value}`} align="left">
                  {value.toLocaleString('es-CL', lsParams)}
                </TableCell>
              ))}
              <TableCell align="left">{row?.zcot.toLocaleString('es-CL', lsParams)}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleRemoveMaterial(row?.label)}>
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CostsTable;
