import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Material
import { withStyles } from '@mui/styles';

// Project
import { composedComponent } from 'utils/functions';
import { sortByDate } from 'utils/sortHelpers';
import { getLastMonthsLabels } from 'utils/dateHelpers';
import colorGen from 'utils/colorGen';
import useCurrency from 'utils/hooks/useCurrency';
import { getDollarKardex, getDollarZcot } from 'utils/currencyHelpers';
import { appActions } from 'commons/reducer';
// import { useLocation, useNavigate } from 'react-router-dom';
import { costsActions } from './reducer';
import saga from './saga';

// Components

// Styles
import styles from './styles';
// Utils
import CostsChart from './components/CostsChart';
import CostsMenu from './components/CostsMenu';
import CostsTable from './components/CostsTable';

const Costs = function (props) {
  const {
    // user,
    classes,
    controls,
    objects,
    actions,
    loading,
  } = props;
  // Este useEffect es para cuando un usuario sea deshabilitado, si entra a este modulo se va a deslogear.
  // Esto habría que aplicarlo a las demás páginas y probar si funciona correctamente.
  // También habría que crear un middleware para cada endpoint validando si el usuario está activo,
  // y si no,
  // retornar un 403 y manejarlo en el front.
  // const location = useLocation();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (user) {
  //     actions.checkUser();
  //     return;
  //   }
  //   navigate('/login', { replace: true });
  // }, [location]);

  const chartColors = () => colorGen();
  const [costs, setCosts] = useState([]);

  const dollarKardex = getDollarKardex(objects.currencies);
  const dollarZcot = getDollarZcot(objects.currencies);
  const { currencyCode, setCurrencyCode, convertZcotValue } = useCurrency({
    dollarKardex,
    dollarZcot,
  });
  useEffect(() => {
    const nextColor = chartColors();
    const settedCosts = Object.entries(controls.materials).map(([key, material]) => {
      const currentColors = nextColor.next().value;
      const sortedCosts = Object.values(material.costs).sort(sortByDate('date'));
      return {
        label: key,
        name: material.name,
        zcot: convertZcotValue(material.zcot),
        data: sortedCosts.map((cost) => convertZcotValue(cost.cost)),
        borderColor: currentColors.borderColor,
        backgroundColor: currentColors.backgroundColor,
        borderWidth: 1,
      };
    });
    setCosts(settedCosts);
  }, [controls.materials, currencyCode]);

  const lastMonthsLabels = useMemo(() => getLastMonthsLabels(10), []);

  const handleChange = useCallback(
    (key, value) => {
      actions.changeControlsByKey(key, value);
    },
    [actions],
  );

  const handleSearch = useCallback(() => {
    actions.getMaterialCosts({
      code: controls.code,
    });
  }, [actions, controls.code]);

  const handleRemoveMaterial = useCallback((id) => {
    const clonedMaterials = { ...controls.materials };
    const { [id]: toDelete, ...rest } = clonedMaterials;
    actions.changeControls({ materials: rest });
  });

  return (
    <div>
      <CostsMenu
        controls={controls}
        loading={loading}
        classes={classes}
        currencyCode={currencyCode}
        setCurrencyCode={setCurrencyCode}
        handleChange={handleChange}
        handleSearch={handleSearch}
      />
      <CostsTable
        classes={classes}
        data={costs}
        lastMonthsLabels={lastMonthsLabels}
        handleRemoveMaterial={handleRemoveMaterial}
      />
      <CostsChart costs={costs} />
    </div>
  );
};

export default composedComponent(Costs, saga, {
  saga: 'sagaCosts',
  middlewares: [withStyles(styles)],
  states: ['app.user', 'app.objects', 'costs.controls', 'costs.invalidControls', 'costs.loading'],
  actions: [costsActions, appActions],
});
