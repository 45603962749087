import { call, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer';
import { truthty } from 'utils/functions';

// eslint-disable-next-line consistent-return
export default function* controlledCall(
  toYield,
  path,
  // eslint-disable-next-line default-param-last
  body = undefined,
  succesFunction,
  failFunction = appActions.setError,
  isOpenFile = false,
) {
  const result = yield call(toYield, path, body);
  if (isOpenFile) {
    if (!result.ok) {
      let data = null;
      yield result.json().then((bodyJson) => {
        data = bodyJson;
      });
      yield put(failFunction(data));
    }
  } else {
    const nextAction = truthty(result.error) ? failFunction : succesFunction;
    if (result.error) {
      yield put(nextAction(result.errorMsg));
    } else {
      yield put(nextAction(result));
    }
    return result;
  }
}
