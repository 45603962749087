import React from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import { lsParams } from 'utils/enums';

const EnhancedTableHead = React.memo((customProps) => {
  const { headCells, orderX, orderByX, onRequestSort } = customProps;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderByX === headCell.id ? orderX : false}>
            {!headCell.unsortable ? (
              <TableSortLabel
                active={orderByX === headCell.id}
                direction={orderByX === headCell.id ? orderX : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderByX === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {orderX === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const MaintainersInternalMaterialsTable = function (props) {
  const {
    actions,
    disabledTable,
    internalMaterials,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    pagination,
    count,
  } = props;

  const { page, rowsPerPage, order, orderBy } = pagination;

  const headCells = [
    { id: 'cMaterial', numeric: false, label: 'Código' },
    { id: 'description', numeric: false, label: 'Nombre' },
    { id: 'cost', numeric: true, label: 'Costo', unsortable: true },
    { id: 'umb', numeric: false, label: 'UMB' },
    { id: 'deleted', numeric: false, label: 'Estado' },
    { id: '', numeric: false, label: 'Acciones', unsortable: true },
  ];

  const handleOpenInternalMaterialForm = (data) => {
    actions.changeControls({ materialForm: data });
    actions.toggleModals('materialForm');
  };

  return (
    <>
      <Grid container mb="20px" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Materiales internos</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              handleOpenInternalMaterialForm({
                id: '',
                code: '',
                name: '',
                umb: null,
                cost: '',
                deleted: '',
                updatedAt: '',
              })
            }>
            Nuevo
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {disabledTable && (
          <>
            <Backdrop
              sx={{ position: 'absolute', zIndex: 1000, background: 'white' }}
              style={{ opacity: 0.7 }}
              open>
              <CircularProgress color="primary" />
            </Backdrop>
            <LinearProgress style={{ zIndex: 1001 }} />
          </>
        )}
        <Table aria-label="tabla de usuarios" sx={{ width: '100%' }}>
          <EnhancedTableHead
            headCells={headCells}
            orderX={order}
            orderByX={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rowsPerPage > 0 &&
              internalMaterials.map((row) => (
                <TableRow key={row.id}>
                  {/* <TableCell align="left">{row?.id}</TableCell> */}
                  <TableCell align="left">{row?.cMaterial}</TableCell>
                  <TableCell align="left">{row?.description}</TableCell>
                  <TableCell align="right">
                    {row?.cost()?.cost?.toLocaleString('es-CL', lsParams)}
                  </TableCell>
                  <TableCell align="left">{row?.umb}</TableCell>
                  <TableCell align="left">{row?.deleted ? 'Bloqueado' : 'Vigente'}</TableCell>
                  <TableCell align="left" width="130px">
                    <IconButton
                      onClick={() =>
                        handleOpenInternalMaterialForm({
                          id: row?.id || '',
                          name: row?.description || '',
                          code: row?.cMaterial || '',
                          umb: row?.umb || '',
                          cost: row?.cost()?.cost || '',
                          deleted: row?.deleted || '0',
                          updatedAt: row?.updatedAt || '',
                        })
                      }>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={13}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count: total }) => `${from}-${to} de ${total}`}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaintainersInternalMaterialsTable;
