import Login from '../screens/Login';
import Home from '../screens/Home';
import Todo from '../screens/Todo';
import Upload from '../screens/Upload/Upload';
import RecoveryPassword from '../screens/RecoveryPassword';
import FormulaCalculation from '../screens/FormulaCalculation';
import Costs from '../screens/Costs';
import Historic from '../screens/Historic';
import Quote from '../screens/Quote';
import Maintainers from '../screens/Maintainers';

const pathList = {
  home: { path: '/', exact: true, component: Home },
  upload: { path: '/upload', exact: true, component: Upload },
  todos: { path: '/todos', exact: true, component: Todo },
  formula: { path: '/formula', exact: true, component: FormulaCalculation },
  costs: { path: '/costos', exact: true, component: Costs },
  historic: { path: '/historico', exact: true, component: Historic },
  quote: { path: '/cotizacion', exact: true, component: Quote },
  maintainers: { path: '/mantenedores', exact: true, component: Maintainers },
};

const Paths = {
  shared: [
    { path: '/login', exact: true, component: Login },
    { path: '/recovery/*', exact: false, component: RecoveryPassword },
    // { path: '/update-password', component: RecoveryPassword },
    // { path: '/register', component: Register },
  ],
  admin: [
    pathList.home,
    pathList.formula,
    pathList.costs,
    pathList.historic,
    pathList.quote,
    pathList.maintainers,
  ],
  user: [
    pathList.home,
    pathList.formula,
    pathList.costs,
    pathList.historic,
    pathList.quote,
    pathList.maintainers,
  ],
};

export default Paths;
