import React, { useEffect, useCallback } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';

import { CardContent, Snackbar, CardMedia } from '@mui/material';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { getParameterByName, composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import Alert from '@mui/material/Alert';
import Paths from 'utils/paths';
import styles from './styles';
import { recoveryPasswordActions } from './reducer';
import saga from './saga';

import RecoveryForm from './components/recoveryForm';
import UpdatePasswordForm from './components/updatePasswordForm';
import logo from '../../commons/assets/images/TcitLogo.png';

const RecoveryPassword = function (props) {
  const { classes, actions, controls, error, message, successChangePassword, loading } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathLogin = Paths.shared[0].path;

  useEffect(() => {
    const token = getParameterByName('token', location.search) ?? '';
    const email = getParameterByName('email', location.search) ?? '';
    actions.setControls({ token, email });
  }, [actions, location, location.search]);

  const onChangeText = useCallback((name, value) => {
    actions.setControls({ [name]: value });
  }, []);

  const toggleError = () => {
    actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.toggleSuccess();
  };

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card>
            <CardContent style={styles.cardContent}>
              <CardMedia className={classes.image} image={logo} title="TCIT" />
              <Routes>
                <Route
                  path="/"
                  element={
                    <RecoveryForm
                      onChangeText={onChangeText}
                      email={controls.email}
                      classes={classes}
                      loading={loading}
                      controls={controls}
                      actions={actions}
                      navigate={navigate}
                    />
                  }
                />
                <Route
                  exact
                  path="/password"
                  element={
                    <UpdatePasswordForm
                      onChangeText={onChangeText}
                      password={controls.password}
                      verifyPassword={controls.verifyPassword}
                      classes={classes}
                      loading={loading}
                      controls={controls}
                      actions={actions}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successChangePassword}
        autoHideDuration={3000}
        onClose={() => {
          if (successChangePassword) {
            navigate(pathLogin);
          }
        }}>
        <Alert onClose={toggleSuccess} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default composedComponent(RecoveryPassword, saga, {
  saga: 'sagaRecoveryPassword',
  // @ts-ignore
  middlewares: [withStyles(styles)],
  states: [
    'recoveryPassword.controls',
    'recoveryPassword.message',
    'recoveryPassword.error',
    'recoveryPassword.successChangePassword',
    'recoveryPassword.loading',
  ],
  actions: [recoveryPasswordActions, appActions],
});
