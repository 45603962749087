// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
// components
// icons

import { Box, ListItemButton, Toolbar } from '@mui/material';
import colors from 'utils/colors';
// utils
// styles
import styles from './styles';

const useStyles = makeStyles(styles);

const SideBar = function (props) {
  const {
    //  user,
    //  isOpen,
    toggleDrawer,
    handleItemClick,
  } = props;
  const [pathName, setPathName] = useState('/');
  // El useState se va a usar cuando se implemente la interfaz para móviles.
  // const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const views = [
    { label: 'Fórmula', module: '/formula' },
    { label: 'Costos', module: '/costos' },
    { label: 'histórico', module: '/historico' },
    { label: 'cotización', module: '/cotizacion' },
    { label: 'mantenedores', module: '/mantenedores' },
  ];
  // if (user?.user?.type === 'user') views.pop();

  const drawerWidth = 280;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          background: colors.secondary,
          boxSizing: 'border-box',
        },
      }}
      // classes={classes.paperAnchorLeft}
      // open={isOpen}
      // open
      onClose={toggleDrawer}>
      {/* <NavBar withoutLogout /> */}
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List sx={{ mt: '20px' }}>
          {views.map((view) => (
            <ListItemButton
              onClick={handleItemClick(view.module)}
              selected={pathName === view.module}
              className={classes.listItemRoot}
              key={`menu-${view.label}`}>
              <ListItemIcon>{view.icon}</ListItemIcon>
              <ListItemText
                primary={view.label}
                sx={{ textTransform: 'uppercase' }}
                primaryTypographyProps={{
                  // fontSize: 15,
                  color: 'white',
                  fontWeight: 'medium',
                  lineHeight: '20px',
                  mt: '10px',
                  mb: '10px',
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
