const gray = 'D9D9D9';
const darkBlue = '002060';
const lightBlue = 'DAE3F3';
const yellow = 'FFFF99';
const black = '000000';

const baseBorder = {
  style: 'thin',
  color: { rgb: gray },
};
const fullBorder = {
  top: baseBorder,
  bottom: baseBorder,
  left: baseBorder,
  right: baseBorder,
};

const baseUpload = (description = '', quantBase = '') => ({
  base: [
    ['', '', '', 'PLANTILLA DE FÓRMULA NUTRICIONAL', '', '', ''],
    ['', '', 'Nombre', description, '', '', ''],
    ['', '', 'Cant. base', quantBase, '', '', ''],
    ['', '', 'UMB', '', '', '', ''],
    ['', '', 'Especie', '', '', '', ''],
    ['', '', '', '', '', '', ''],
    ['', '', '', '', '', '', ''],
    ['', '', '', '', '', '', ''],
    ['', '', 'Código', 'Cantidad', 'UN', ''],
  ],
  wscols: [
    { wch: 6 },
    { wch: 2 },
    { wch: 10 },
    { wch: 40 },
    { wch: 15 },
    { wch: 6 },
    { wch: 12 },
    { wch: 6 },
    { wch: 14 },
  ],
  wsrows: [{ hpx: 20 }],
  wsmerges: [
    { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
    { s: { r: 2, c: 5 }, e: { r: 2, c: 6 } },
    { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },
    { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },
    { s: { r: 5, c: 5 }, e: { r: 5, c: 6 } },
    { s: { r: 6, c: 5 }, e: { r: 6, c: 6 } },
  ],
  style: {
    title: {
      font: {
        // name: 'Calibri',
        sz: 14,
        bold: true,
        color: { rgb: darkBlue },
      },
    },

    labelData: {
      font: {
        // name: 'Calibri',
        // sz: 24,
        bold: true,
        color: { rgb: darkBlue },
      },
      fill: {
        fgColor: { rgb: lightBlue },
      },
      border: {
        ...fullBorder,
      },
    },

    valueData: {
      font: {
        // name: 'Calibri',
        // sz: 16,
        bold: false,
        color: { rgb: darkBlue },
      },
      fill: {
        fgColor: { rgb: yellow },
      },
      alignment: {
        horizontal: 'left',
      },
      border: {
        ...fullBorder,
      },
    },

    tableHeader: {
      font: {
        // name: 'Calibri',
        // sz: 24,
        bold: true,
        color: { rgb: black },
      },
      fill: {
        fgColor: { rgb: lightBlue },
      },
    },
  },
});

export default baseUpload;
