import { createAction, createReducer } from '../../../utils/reducer';

const PATH = 'file/';

export const FILE_TYPES = {
  START_FILE_UPLOAD: `${PATH}START_FILE_UPLOAD`,
  END_FILE_UPLOAD: `${PATH}END_FILE_UPLOAD`,
  ADD_FILE_STORE: `${PATH}ADD_FILE_STORE`,
};

export const fileActions = {
  startFileUpload: createAction(FILE_TYPES.START_FILE_UPLOAD, 'payload'), // change payload.
  endFileUpload: createAction(FILE_TYPES.END_FILE_UPLOAD),
  addFileStore: createAction(FILE_TYPES.ADD_FILE_STORE),
};

const initialState = {
  fileControls: {
    uploading: false,
  },
};

const fileReducer = createReducer(initialState, {
  // [FILE_TYPES.START_FILE_UPLOAD](state) {
  //   state.fileControls = {
  //     ...state.fileControls,
  //     uploading: true,
  //   };
  // },
  [FILE_TYPES.START_FILE_UPLOAD]: (state) => ({
    ...state,
    fileControls: {
      ...state.fileControls,
      uploading: true,
    },
  }),
  // [FILE_TYPES.END_FILE_UPLOAD](state) {
  //   state.fileControls = {
  //     ...state.fileControls,
  //     uploading: false,
  //   };
  // },
  [FILE_TYPES.END_FILE_UPLOAD]: (state) => ({
    ...state,
    fileControls: {
      ...state.fileControls,
      uploading: false,
    },
  }),
});

export default fileReducer;
