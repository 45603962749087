import { QUOTE_STATE } from './enums';

// No se usa actualmente.
export const getMPercPpto = (lines, currKardex) => {
  const totalKardex = lines.reduce((acc, elem) => acc + elem.kardex, 0);
  return (currKardex / totalKardex) * 100;
};

export const getPrice = (projectedCost, mgPerc) => {
  const decimalPerc = mgPerc / 100;
  return projectedCost / (1 - decimalPerc);
};

export const getMarginPerUnit = (kardex, projectedCost, mgPerc) =>
  getPrice(projectedCost, mgPerc) - kardex;

// export const getTotalMargin = (zcot, mgPerc, volumeKg) =>
//   (getPrice(zcot, mgPerc) - zcot) * volumeKg;
export const getTotalMargin = (projectedCost, mgPerc, volumeKg) =>
  getPrice(projectedCost, mgPerc) * (mgPerc / 100) * volumeKg;

export const getTotalSale = (projectedCost, mgPerc, volumeKg) =>
  getPrice(projectedCost, mgPerc) * volumeKg;

export const getQuoteStateLabel = (number) => {
  const labels = {
    [QUOTE_STATE.PENDING]: 'Pendiente',
    [QUOTE_STATE.AWARDED]: 'Adjudicado',
    [QUOTE_STATE.LOST]: 'Perdido',
  };
  return labels[number];
};
