import camelize from 'camelcase-keys-deep';

export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  speciesArr() {
    const result = this.id
      ? this.env()
          .UserSpecie.findAllBy('user_id', this.id)
          .map((userSpecie) => userSpecie.specie().code)
      : [];
    return result;
  }

  speciesNames() {
    const result = this.id
      ? this.env()
          .UserSpecie.findAllBy('user_id', this.id)
          .map((userSpecie) => userSpecie.specie().name)
          .join(', ')
      : [];
    return result;
  }
}
