import { takeLatest, spawn, put } from 'redux-saga/effects';
import { get, post, update, apiSuccess, objectToQueryString } from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';

// Reducer
import {
  appActions,
  DELETE_STATE_FROM_API_SUCCESS,
  GET_STATE_FROM_API_SUCCESS,
} from 'commons/reducer';
import { MAINTAINERS, maintainersActions } from '../reducer';

function* fetchData() {
  yield takeLatest(MAINTAINERS.FETCH_DATA, function* (action) {
    // console.log('FETCH_DATA_SAGA');
    yield controlledCall(
      get,
      `api/v1/maintainers/get-data`,
      action.controls,
      maintainersActions.fetchDataSuccess,
      maintainersActions.fetchDataFail,
    );
  });
}

function* updateCurrencies() {
  yield takeLatest(MAINTAINERS.UPDATE_CURRENCIES, function* (action) {
    yield put(appActions.setLoader('update-currencies', true));
    // console.log('UPDATE_CURRENCIES_SAGA');
    const result = yield controlledCall(
      post,
      `api/v1/maintainers/update-currencies/`,
      action.payload,
      maintainersActions.updateCurrenciesSuccess,
      // maintainersActions.updateCurrenciesFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Datos actualizados'));
    }
    yield put(appActions.setLoader('update-currencies', false));
  });
}

function* createUser() {
  yield takeLatest(MAINTAINERS.CREATE_USER, function* (action) {
    yield put(appActions.setLoader('create-user', true));
    // console.log('CREATE_USER_SAGA');
    const result = yield controlledCall(
      post,
      `api/v1/maintainers/create-user/`,
      action.payload,
      maintainersActions.createUserSuccess,
      // maintainersActions.createUserFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Usuario creado'));
    }
    yield put(appActions.setLoader('create-user', false));
  });
}

function* updateUser() {
  yield takeLatest(MAINTAINERS.UPDATE_USER, function* (action) {
    yield put(appActions.setLoader('update-user', true));
    // console.log('UPDATE_USER_SAGA');
    const result = yield controlledCall(
      update,
      `api/v1/maintainers/update-user/${action.payload.id}`,
      action.payload,
      maintainersActions.updateUserSuccess,
      // maintainersActions.updateUserFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Usuario actualizado'));
      yield put(apiSuccess(DELETE_STATE_FROM_API_SUCCESS, result));
    }
    yield put(appActions.setLoader('update-user', false));
  });
}
function* disableUser() {
  yield takeLatest(MAINTAINERS.DISABLE_USER, function* (action) {
    yield put(appActions.setLoader('disable-user', true));
    // console.log('DISABLE_USER_SAGA');
    const result = yield controlledCall(
      update,
      `api/v1/maintainers/disable-user/${action.payload.id}`,
      {},
      maintainersActions.disableUserSuccess,
      // maintainersActions.disableUserFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Usuario deshabilitado'));
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, result));
    }
    yield put(appActions.setLoader('disable-user', false));
  });
}

function* enableUser() {
  yield takeLatest(MAINTAINERS.ENABLE_USER, function* (action) {
    yield put(appActions.setLoader('enable-user', true));
    // console.log('ENABLE_USER_SAGA');
    const result = yield controlledCall(
      update,
      `api/v1/maintainers/enable-user/${action.payload.id}`,
      {},
      maintainersActions.enableUserSuccess,
      // maintainersActions.enableUserFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Usuario habilitado'));
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, result));
    }
    yield put(appActions.setLoader('enable-user', false));
  });
}
// INTERNAL MATERIALS
function* getInternalMaterials() {
  yield takeLatest(MAINTAINERS.GET_INTERNAL_MATERIALS, function* (action) {
    yield put(appActions.setLoader('get-internal-materials', true));
    // console.log('GET_INTERNAL_MATERIALS_SAGA');
    const settedPayload = {
      ...action.payload,
      page: action.payload.page + 1,
    };
    const response = yield get(
      `api/v1/maintainers/get-internal-materials/?${objectToQueryString(settedPayload)}`,
      '',
    );
    if (!response.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(MAINTAINERS.GET_INTERNAL_MATERIALS_SUCCESS, response));
    } else {
      yield put(apiSuccess(MAINTAINERS.GET_INTERNAL_MATERIALS_FAIL, response.error));
    }
    yield put(appActions.setLoader('get-internal-materials', false));
  });
}

function* createMaterial() {
  yield takeLatest(MAINTAINERS.CREATE_MATERIAL, function* (action) {
    yield put(appActions.setLoader('create-material', true));
    // console.log('CREATE_MATERIAL_SAGA');
    const response = yield post(`api/v1/maintainers/add-material`, action.payload);
    if (!response.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(MAINTAINERS.CREATE_MATERIAL_SUCCESS, response));
      yield put(appActions.setSuccess('Material creado'));
      action.callback();
    } else {
      // console.log('response', response);
      yield put(apiSuccess(MAINTAINERS.CREATE_MATERIAL_FAIL, response.error));
      yield put(
        appActions.setError(
          response?.errorMsg && !response?.errorMsg.includes('<')
            ? response.errorMsg
            : 'Hubo un error inesperado.',
        ),
      );
    }
    yield put(appActions.setLoader('create-material', false));
  });
}

function* updateMaterial() {
  yield takeLatest(MAINTAINERS.UPDATE_MATERIAL, function* (action) {
    yield put(appActions.setLoader('update-material', true));
    // console.log('UPDATE_MATERIAL_SAGA');
    const response = yield post(`api/v1/maintainers/update-material`, action.payload);
    if (!response.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(MAINTAINERS.UPDATE_MATERIAL_SUCCESS, response));
      yield put(appActions.setSuccess('Material actualizado'));
      action.callback();
    } else {
      // console.log('response', response);
      yield put(apiSuccess(MAINTAINERS.UPDATE_MATERIAL_FAIL, response.error));
      yield put(
        appActions.setError(
          response?.errorMsg && !response?.errorMsg.includes('<')
            ? response.errorMsg
            : 'Hubo un error inesperado.',
        ),
      );
    }
    yield put(appActions.setLoader('update-material', false));
  });
}

// INTERNAL FORMULAS
function* getInternalFormulas() {
  yield takeLatest(MAINTAINERS.GET_INTERNAL_FORMULAS, function* (action) {
    yield put(appActions.setLoader('get-internal-formulas', true));
    // console.log('GET_INTERNAL_FORMULAS_SAGA');
    const settedPayload = {
      ...action.payload,
      page: action.payload.page + 1,
    };
    const response = yield get(
      `api/v1/maintainers/get-internal-formulas/?${objectToQueryString(settedPayload)}`,
      '',
    );
    if (!response.error) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(MAINTAINERS.GET_INTERNAL_FORMULAS_SUCCESS, response));
    } else {
      yield put(apiSuccess(MAINTAINERS.GET_INTERNAL_FORMULAS_FAIL, response.error));
    }
    yield put(appActions.setLoader('get-internal-formulas', false));
  });
}

export default function* root() {
  yield spawn(fetchData);
  yield spawn(updateCurrencies);
  yield spawn(createUser);
  yield spawn(updateUser);
  yield spawn(disableUser);
  yield spawn(enableUser);
  yield spawn(getInternalMaterials);
  yield spawn(createMaterial);
  yield spawn(updateMaterial);
  yield spawn(getInternalFormulas);
}
