import { createTheme } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FA7624',
      contrastText: 'white',
    },
    secondary: {
      main: blueGrey[500],
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: '42px',
    },
    h2: {
      fontSize: '36px',
    },
    h3: {
      fontSize: '30px',
    },
    h4: {
      fontSize: '24px',
    },
    h5: {
      fontSize: '21px',
    },
    h6: {
      fontSize: '18px',
    },
  },
});

export default theme;
