import React, { useCallback } from 'react';
// Material
import { Grid, TextField } from '@mui/material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// Icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// Utils
import { useForm } from 'utils/hooks/useForm';
import { isNumber } from 'utils/math';
import { numberBetween, valueHasData } from 'utils/validationsHelper';
import { downloadHistoric } from 'utils/xlsx';
import SearchMaterialByName from 'commons/components/SearchMaterialByName';

const HistoricFilters = function (props) {
  const { controls, actions, classes, handleChange, handleSearch, loading } = props;
  const { materialId } = controls;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: handleChange,
    validations: {
      materialId: [
        {
          check: (value) => !valueHasData(value) || isNumber(value),
          message: 'El código debe ser numérico ',
        },
        {
          check: (value) => !valueHasData(value) || numberBetween(value, 400_000, 499_999),
          message: 'Sólo códigos válidos entre 400000 y 499999 ',
        },
      ],
    },
  });

  const handleFilter = (e) => {
    e.preventDefault();
    onSubmit(() => {
      handleSearch();
    });
  };

  const handleDownLoadExcel = (data) => {
    downloadHistoric(data);
  };

  const handleExport = () => {
    onSubmit(() => {
      actions.getExcelData(
        {
          materialId: controls.materialId,
        },
        handleDownLoadExcel,
      );
    });
  };

  const handleChangeMaterialName = useCallback(
    (value) => {
      handleChange('materialId', value);
    },
    [handleChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <form onSubmit={handleFilter}>
          <Grid container spacing={1} wrap="nowrap" mb="20px">
            <Grid item>
              <TextField
                id="materialId"
                label="Filtrar por código material"
                variant="outlined"
                disabled={loading}
                value={materialId}
                onChange={onChange}
                error={errors.materialId?.length > 0}
                helperText={errors.materialId ? errors?.materialId[0] : null}
                inputProps={{ maxLength: 6 }}
              />
            </Grid>
            <Grid item mb="20px">
              <SearchMaterialByName
                historic
                materialId={controls.materialId}
                callback={handleChangeMaterialName}
                sx={{ width: 250 }}
              />
            </Grid>
            <Grid item>
              <ButtonWithLoader
                type="submit"
                buttonId="search-quotes"
                variant="contained"
                color="primary"
                className={classes.button}>
                Consultar
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item>
        <ButtonWithLoader
          type="button"
          buttonId="get-excel-data"
          variant="outlined"
          color="primary"
          onClick={handleExport}
          startIcon={<FileDownloadIcon />}
          className={classes.button}>
          Exportar
        </ButtonWithLoader>
      </Grid>
    </Grid>
  );
};

export default HistoricFilters;
