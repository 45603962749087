import React from 'react';

const UploadFile = function (props) {
  const { accept, id, onChange, dataModule, children } = props;

  return (
    <>
      <input
        accept={accept}
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={onChange}
        data-module={dataModule}
      />
      <label htmlFor={id}>{children}</label>
    </>
  );
};

export default UploadFile;
