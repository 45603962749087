import React from 'react';
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Styles

import { styled, alpha } from '@mui/material/styles';
import { Button, Menu, MenuItem } from '@mui/material';

// import styles from './styles';

// const useStyles = makeStyles(styles);

const CustomMenu = function (props) {
  const { children, variant = 'outlined', sx } = props;
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeBeforeClick = (callback) => {
    callback();
    setAnchorEl(null);
  };

  const StyledMenu = styled((pr) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...pr}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));
  // const StyledMenu = React.memo((pr) => (
  //   <Menu
  //     elevation={0}
  //     anchorOrigin={{
  //       vertical: 'bottom',
  //       horizontal: 'right',
  //     }}
  //     transformOrigin={{
  //       vertical: 'top',
  //       horizontal: 'right',
  //     }}
  //     {...pr}
  //   />
  // ));
  // const childrenWithProps = React.Children.map(children, (child) => {
  //   // Checking isValidElement is the safe way and avoids a typescript
  //   // error too.
  //   if (React.isValidElement(child) && child.type.name === 'CustomMenuItem') {
  //     // console.log('child', child.type.name);
  //     return React.cloneElement(child, { handleClose });
  //   }
  //   return child;
  // });

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        variant={variant}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={sx}>
        Opciones
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {/* <div onClick={handleClose} onKeyDown={() => {}} role="grid" tabIndex={0}> */}
        {/* {children} */}
        {/* {childrenWithProps} */}
        {children(closeBeforeClick)}
        {/* </div> */}
      </StyledMenu>
    </>
  );
};

const CustomMenuItem = function (props) {
  const { onClick, children } = props;
  const handleClick = () => {
    onClick();
  };
  return (
    <MenuItem onClick={handleClick} disableRipple>
      {children}
    </MenuItem>
  );
};

CustomMenu.MenuItem = CustomMenuItem;

export default CustomMenu;
