/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';
import {
  appActions,
  DELETE_STATE_FROM_API_SUCCESS,
  GET_STATE_FROM_API_SUCCESS,
} from 'commons/reducer';
import apiRequest, { post, destroy, apiSuccess } from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';

// Reducer
import { FORMULA_CALCULATION, formulaCalculationActions } from '../reducer';

function* calculateFormula() {
  yield takeLatest(FORMULA_CALCULATION.CALCULATE_FORMULA, function* (action) {
    yield put(appActions.setLoader('calculate-formula', true));
    // @ts-ignore
    const body = JSON.stringify(action.data);
    const response = yield apiRequest('api/v1/formulas/calculate', { method: 'POST', body });
    if (response.error) {
      yield put(apiSuccess(FORMULA_CALCULATION.CALCULATE_FORMULA_FAIL, response.error));
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(FORMULA_CALCULATION.CALCULATE_FORMULA_SUCCESS, response));
    }
    yield put(appActions.setLoader('calculate-formula', false));
  });
}

function* submit() {
  yield takeLatest(FORMULA_CALCULATION.SUBMIT, function* (action) {
    yield put(appActions.setLoader('submit', true));
    // console.log('SUBMIT_SAGA');
    const result = yield controlledCall(
      post,
      'api/v1/formulas/add-formula-template/',
      action.controls,
      formulaCalculationActions.submitSuccess,
      // formulaCalculationActions.submitFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Fórmula agregada'));
      action.callback({ code: result.code, startDate: null, endDate: null });
    }
    yield put(appActions.setLoader('submit', false));
  });
}

function* searchMaterial() {
  yield takeLatest(FORMULA_CALCULATION.SEARCH_MATERIAL, function* (action) {
    yield put(appActions.setLoader('search-material', true));
    // console.log('search-material_SAGA');
    yield controlledCall(
      post,
      'api/v1/formulas/search-material/',
      action.data,
      formulaCalculationActions.searchMaterialSuccess,
      // formulaCalculationActions.searchMaterialFail
    );
    // if (!result.error) {
    //   yield put(appActions.setSuccess('Fórmula agregada'));
    // }
    yield put(appActions.setLoader('search-material', false));
  });
}

function* addMaterialFormula() {
  yield takeLatest(FORMULA_CALCULATION.ADD_MATERIAL_FORMULA, function* (action) {
    yield put(appActions.setLoader('add-material-formula', true));
    // console.log('add material_formula_SAGA');
    const result = yield controlledCall(
      post,
      'api/v1/formulas/add-material-formula/',
      action.data,
      formulaCalculationActions.addMaterialFormulaSuccess,
      // formulaCalculationActions.addMaterialFormulaFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Material agregado a la fórmula'));
      yield put(
        formulaCalculationActions.calculateFormula({
          code: action.data.fMaterialId,
          startDate: null,
          endDate: null,
        }),
      );
    }
    yield put(appActions.setLoader('add-material-formula', false));
  });
}

function* addVariableCost() {
  yield takeLatest(FORMULA_CALCULATION.ADD_VARIABLE_COST, function* (action) {
    yield put(appActions.setLoader('add-variable-cost', true));
    // console.log('add material_formula_SAGA');
    const result = yield controlledCall(
      post,
      'api/v1/formulas/add-variable-cost/',
      action.data,
      formulaCalculationActions.addVariableCostSuccess,
      // formulaCalculationActions.addVariableCostFail
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Costo variable agregado a la fórmula'));
      yield put(
        formulaCalculationActions.calculateFormula({
          code: action.data.materialId,
          startDate: null,
          endDate: null,
        }),
      );
    }
    yield put(appActions.setLoader('add-variable-cost', false));
  });
}

function* deleteVariableCost() {
  yield takeLatest(FORMULA_CALCULATION.DELETE_VARIABLE_COST, function* (action) {
    yield put(appActions.setLoader('delete-variable-cost', true));
    // console.log('delete material_formula_SAGA');
    const result = yield controlledCall(
      destroy,
      `api/v1/formulas/delete-variable-cost/${action.payload.id}`,
      null,
      formulaCalculationActions.deleteVariableCostSuccess,
      // formulaCalculationActions.deleteVariableCostFail
    );
    if (!result.error) {
      yield put(apiSuccess(DELETE_STATE_FROM_API_SUCCESS, result));
      yield put(appActions.setSuccess('Costo variable eliminado'));
      yield put(
        formulaCalculationActions.calculateFormula({
          code: action.payload.materialId,
          startDate: null,
          endDate: null,
        }),
      );
    }
    yield put(appActions.setLoader('delete-variable-cost', false));
  });
}

export default function* root() {
  yield spawn(calculateFormula);
  yield spawn(submit);
  yield spawn(searchMaterial);
  yield spawn(addMaterialFormula);
  yield spawn(addVariableCost);
  yield spawn(deleteVariableCost);
}
