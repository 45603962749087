import { removeManyKeysByFilter } from 'utils/functions';
import { MAINTAINERS } from 'screens/Maintainers/reducer';
import { QUOTE } from 'screens/Quote/reducer';
import { LOGIN } from '../../screens/Login/reducer';
import { createReducer, createAction } from '../../utils/reducer';

// GLOBAL
export const GET_USER = 'app/GET_USER';
export const GET_USER_SUCCESS = 'app/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'app/GET_USER_FAIL';
// Test
export const CHECK_USER = 'app/CHECK_USER';
export const CHECK_USER_SUCCESS = 'app/CHECK_USER_SUCCESS';
export const CHECK_USER_FAIL = 'app/CHECK_USER_FAIL';
export const GET_STATE_FROM_API = 'app/GET_API_FROM_STATE';
export const GET_STATE_FROM_API_SUCCESS = 'app/GET_API_FROM_STATE_SUCCESS';
export const DELETE_STATE_FROM_API = 'app/DELETE_API_FROM_STATE';
export const DELETE_STATE_FROM_API_SUCCESS = 'app/DELETE_API_FROM_STATE_SUCCESS';

export const GET_MATERIALS_BY_NAME = 'app/GET_MATERIALS_BY_NAME';
export const GET_MATERIALS_BY_NAME_SUCCESS = 'app/GET_MATERIALS_BY_NAME_SUCCESS';
export const GET_MATERIALS_BY_NAME_FAIL = 'app/GET_MATERIALS_BY_NAME_FAIL';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const RESET = 'app/RESET';

export const appActions = {
  // api fetch
  // logOut: createAction(LOG_OUT),
  getUser: createAction(GET_USER),
  checkUser: createAction(CHECK_USER),
  setUser: createAction(GET_USER_SUCCESS, 'payload'),
  reset: createAction(RESET),
  // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  // custom
  getMaterialsByName: createAction(GET_MATERIALS_BY_NAME, 'payload'),
  getMaterialsByNameSuccess: createAction(GET_MATERIALS_BY_NAME_SUCCESS, 'payload'),
  getMaterialsByNameFail: createAction(GET_MATERIALS_BY_NAME_FAIL, 'payload'),
};

const initialObjects = {
  materials: {},
  variableCosts: {},
  currencies: {},
  quotes: {},
  quoteMaterials: {},
  clients: {},
  users: {},
  species: {},
  userSpecies: {},
  formulaResumes: {},
};

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  objects: {
    ...initialObjects,
  },
  user: null,
  loaders: {},
  foundMaterials: [],
};

const mergeObjects = (state, action) => {
  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });
  // state.objects = newObjects;
  return {
    ...state,
    objects: newObjects,
  };
};

// const deleteObjects = (state, action) => {
//   Object.keys(action.payload.response.ids).forEach((key) => {
//     state.objects[key] = removeManyKeysByFilter(
//       state.objects[key],
//       action.payload.response.ids[key],
//     );
//   });
// };

const deleteObjects = (state, action) => {
  const newObjects = Object.keys(action.payload.response.ids).reduce(
    (acc, key) => {
      acc[key] = removeManyKeysByFilter(state.objects[key], action.payload.response.ids[key]);
      return acc;
    },
    { ...state.objects },
  );
  return {
    ...state,
    objects: newObjects,
  };
};

function mergeActions(actions) {
  return actions.reduce((result, action) => ({ ...result, [action]: mergeObjects }), {});
}

function deleteActions(actions) {
  return actions.reduce((result, action) => ({ ...result, [action]: deleteObjects }), {});
}

const app = createReducer(initialState, {
  [GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [DELETE_STATE_FROM_API_SUCCESS]: deleteObjects,
  ...mergeActions([
    // FORMULA_CALCULATION.CALCULATE_FORMULA_SUCCESS,
    // HISTORIC.FETCH_DATA_SUCCESS,
    // HISTORIC.UPDATE_QUOTE_MATERIAL_SUCCESS,
    QUOTE.GET_MATERIAL_SUCCESS,
    MAINTAINERS.FETCH_DATA_SUCCESS,
    MAINTAINERS.UPDATE_CURRENCIES_SUCCESS,
    MAINTAINERS.CREATE_USER_SUCCESS,
    MAINTAINERS.UPDATE_USER_SUCCESS,
  ]),
  ...deleteActions([
    // FORMULA_CALCULATION.DELETE_VARIABLE_COST_SUCCESS
  ]),
  [LOGIN.LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload,
    loadingUser: false,
  }),
  [GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload,
    loadingUser: false,
  }),
  [GET_USER]: (state) => ({
    ...state,
    loadingUser: true,
  }),
  [GET_USER_FAIL]: (state) => ({
    ...state,
    user: null,
    loadingUser: false,
  }),
  [CHECK_USER_SUCCESS]: (state, action) => {
    const { payload } = action;
    if (payload.user.disabled) {
      return {
        ...state,
        objects: { ...initialObjects },
        user: null,
      };
    }
    return {
      ...state,
      user: payload,
    };
  },
  [CHECK_USER]: (state) => ({
    ...state,
  }),
  [CHECK_USER_FAIL]: (state) => ({
    ...state,
    user: null,
  }),
  [RESET]: (state) => ({
    ...state,
    objects: { ...initialObjects },
  }),
  [TOGGLE_DRAWER]: (state) => ({
    ...state,
    drawerIsOpen: !state.drawerIsOpen,
  }),
  [TOGGLE_ERROR]: (state) => ({
    ...state,
    error: false,
    errorMsg: !state.errorMsg,
  }),
  [SET_ERROR]: (state, action) => ({
    ...state,
    error: true,
    errorMsg: action.msg,
  }),
  [TOGGLE_SUCCESS]: (state) => ({
    ...state,
    success: false,
    successMsg: !state.successMsg,
  }),
  [SET_SUCCESS]: (state, action) => ({
    ...state,
    success: true,
    successMsg: action.msg,
  }),
  [SET_LOADER]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [action.id]: action.state,
    },
  }),
  [GET_MATERIALS_BY_NAME_SUCCESS]: (state, action) => ({
    ...state,
    foundMaterials: [...action.payload.materials],
  }),
});

export default app;
