import { useState } from 'react';
import { getItemInStorage } from 'utils/functions';

const useQuotePdf = () => {
  const [pdf, setPdf] = useState('');

  const getTempPdf = (data) => {
    const session = getItemInStorage('user');
    const authorizationHeader = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session && session.token}`,
    };

    fetch(`/api/v1/quotes/temp-pdf`, {
      method: 'POST',
      headers: authorizationHeader,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((myBlob) => {
        const reader = new FileReader();
        reader.onload = function () {
          const src = this.result;
          setPdf(src);
          return src;
        };
        reader.readAsDataURL(myBlob);
      });
  };
  const getPdf = (id) => {
    const session = getItemInStorage('user');
    const authorizationHeader = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session && session.token}`,
    };

    fetch(`/api/v1/quotes/pdf/${id}`, {
      method: 'GET',
      headers: authorizationHeader,
    })
      .then((response) => response.blob())
      .then((myBlob) => {
        // Download
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(myBlob);
        a.download = `COTIZACION-${id}`;
        a.click();
      });
  };

  return { pdf, getTempPdf, getPdf };
};

export default useQuotePdf;
