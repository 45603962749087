import React from 'react';
// Material
import { Button, Grid, Typography } from '@mui/material';
// Icons
import { CheckCircleOutline, Add, PictureAsPdf } from '@mui/icons-material';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import useQuotePdf from 'utils/hooks/useQuotePdf';

const FinalStep = function (props) {
  const { resetForm, newQuoteId } = props;
  // usar setPrvStep en caso de error en la api para volver a intentarlo
  const { getPdf } = useQuotePdf();
  return (
    <Grid container justifyContent="center" mt="50px">
      <Grid
        item
        container
        sx={{ border: '1px solid #d9d9d9', maxWidth: '80%', borderRadius: '5px', p: '30px 0' }}>
        <Grid item container justifyContent="center" mb="10px" sx={{ fontSize: '100px' }}>
          <CheckCircleOutline fontSize="inherit" color="primary" />
        </Grid>
        <Grid item container justifyContent="center" mb="30px">
          <Typography variant="h4" textAlign="center">
            Cotización generada exitosamente
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" mb="10px" spacing={1}>
          <Grid item>
            <Button variant="outlined" startIcon={<Add />} onClick={resetForm}>
              Nueva cotización
            </Button>
          </Grid>
          <Grid item>
            <ButtonWithLoader
              variant="contained"
              startIcon={<PictureAsPdf />}
              onClick={() => getPdf(newQuoteId)}>
              Descargar pdf
            </ButtonWithLoader>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinalStep;
