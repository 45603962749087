import moment from 'moment';

export const formatDate2 = (date) => moment(date).format('D [de] MMMM YYYY');
export const formatDateSlash = (date) => moment(date).format('DD/MM/YYYY');
export const formatDateWithHour = (date) => moment(date).format('D [de] MMMM YYYY - HH:mm');
export const formatHour = (date) => moment(date).format('HH:mm');

export const getLastMonthsDates = (months) => {
  const now = new Date();
  const dates = Array(months)
    .fill()
    .map((e, i) => moment(now).subtract(i, 'month'));
  return dates.reverse();
};
export const getLastMonthsLabels = (months) =>
  getLastMonthsDates(months).map((date) => date.format('MMM-YY'));

export const isSameYearAndMonth = (dateA, dateB) => moment(dateA).isSame(dateB, 'month');
