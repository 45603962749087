import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Material
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Project
import { composedComponent } from 'utils/functions';
import { Tab, Tabs } from '@mui/material';
import FetchLoading from 'commons/components/FetchLoading';
import { maintainersActions } from './reducer';
import saga from './saga';

// Components
import MaintainersCurrencyForm from './components/MaintainersCurrencyForm';
import MaintainersUsersTable from './components/MaintainersUsersTable';
import MaintainersInternalMaterialsTable from './components/MaintainersInternalMaterialsTable';
import MaintainersCreateOrUpdateUserModal from './components/MaintainersCreateOrUpdateUserModal';

// Utils

// Styles
import styles from './styles';
import MaintainersMaterialFormModal from './components/MaintainersMaterialFormModal';
import MaintainersInternalMaterialFilters from './components/MaintainersInternalMaterialFilters';
import MaintainersInternalFormulasTable from './components/MaintainersInternalFormulasTable';

const TabPanel = function (props) {
  const { children, value, index, sx } = props;

  return (
    <div
      style={{ ...sx, width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Grid item xs={12} sx={{ p: 3 }}>
          {children}
        </Grid>
      )}
    </div>
  );
};

const Maintainers = function (props) {
  const {
    user,
    classes,
    controls,
    actions,
    objects,
    modals,
    loading,
    fetchSuccess,
    fetchError,
    disabledTable,
    filteredIds,
    count,
  } = props;
  const { users } = objects;
  const TABS = {
    DOLAR: 0,
    USERS: 1,
    INT_MATERIALS: 2,
    INT_FORMULAS: 3,
  };
  const USER_TABS = {
    INT_MATERIALS: 0,
    INT_FORMULAS: 1,
  };
  const [tabValue, setValue] = React.useState(
    user.user === 'admin' ? TABS.DOLAR : USER_TABS.INT_MATERIALS,
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const usersArr = useMemo(() => Object.values(users), [users]);

  useEffect(() => {
    actions.fetchData();
  }, [actions]);

  // Internal materials
  const [pagination, setPagination] = useState({
    rowsPerPage: 5,
    page: 0,
    order: 'desc',
    orderBy: 'cMaterial',
  });

  useEffect(() => {
    if (user.user.type === 'admin' && tabValue !== TABS.INT_MATERIALS) return;
    if (user.user.type === 'user' && tabValue !== USER_TABS.INT_MATERIALS) return;
    actions.getInternalMaterials({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
    });
  }, [actions, tabValue]);

  const handleSearch = useCallback(() => {
    setPagination((old) => ({
      ...old,
      page: 0,
    }));
  }, []);

  useEffect(() => {
    if (!fetchSuccess) return;
    if (user.user.type === 'admin' && tabValue !== TABS.INT_MATERIALS) return;
    if (user.user.type === 'user' && tabValue !== USER_TABS.INT_MATERIALS) return;
    actions.getInternalMaterials({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      order: pagination.order,
      orderBy: pagination.orderBy,
      ...(controls.materialSearch ? { code: controls.materialSearch.materialId } : {}),
    });
  }, [actions, pagination, tabValue]);

  const handleChangePage = useCallback((event, newPage) => {
    setPagination((old) => ({
      ...old,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setPagination({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === 'asc';
    setPagination((old) => ({
      ...old,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }));
  };

  function descendingComparator(a, b, orderByX) {
    const aParsed = a[orderByX] || '';
    const bParsed = b[orderByX] || '';
    if (!Number.isNaN(Number(aParsed))) {
      if (bParsed < aParsed) {
        return -1;
      }
      if (bParsed > aParsed) {
        return 1;
      }
      return 0;
    }
    return bParsed.localeCompare(aParsed, 'en', { sensitivity: 'base' });
  }

  function getComparator(orderX, orderByX) {
    // console.log('orderX', orderX);
    // console.log('orderByX', orderByX);
    return orderX === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByX)
      : (a, b) => -descendingComparator(a, b, orderByX);
  }

  const { order, orderBy } = pagination;
  const internalMaterials = useMemo(() => Object.values(objects.materials), [objects.materials]);
  const filteredInternalMaterials = useMemo(
    () =>
      internalMaterials
        .filter((material) => filteredIds.materialIds.includes(material.id))
        .sort(getComparator(order, orderBy)),
    [internalMaterials, filteredIds, order, orderBy],
  );
  // console.log('internalMaterials', internalMaterials);
  // console.log('filteredInternalMaterials', filteredInternalMaterials);
  // FORMULAS
  const [formulaPagination, setFormulaPagination] = useState({
    rowsPerPage: 5,
    page: 0,
    order: 'desc',
    orderBy: 'material_id',
  });

  useEffect(() => {
    // if (tabValue !== TABS.INT_FORMULAS) return;
    if (user.user.type === 'admin' && tabValue !== TABS.INT_FORMULAS) return;
    if (user.user.type === 'user' && tabValue !== USER_TABS.INT_FORMULAS) return;
    actions.getInternalFormulas({
      page: formulaPagination.page,
      rowsPerPage: formulaPagination.rowsPerPage,
    });
  }, [actions, tabValue, formulaPagination]);

  // const { order, orderBy } = formulaPagination;
  const internalFormulas = useMemo(
    () => Object.values(objects.formulaResumes),
    [objects.formulaResumes],
  );
  const filteredInternalFormulas = useMemo(
    () =>
      internalFormulas
        .filter((formula) => filteredIds.formulaIds.includes(formula.material_id))
        .sort(getComparator(formulaPagination.order, formulaPagination.orderBy)),
    [internalMaterials, filteredIds, formulaPagination.order, formulaPagination.orderBy],
  );
  // console.log('filteredInternalFormulas', filteredInternalFormulas);

  const handleChangeFormulaPage = useCallback((event, newPage) => {
    // console.log('handle change page');
    setFormulaPagination((old) => ({
      ...old,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerFormulaPage = useCallback((event) => {
    setFormulaPagination({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  }, []);

  // TOGGLES
  const handleToggleUserForm = useCallback(() => {
    actions.toggleModals('userForm');
  }, [actions]);

  const handleToggleMaterialForm = useCallback(() => {
    actions.toggleModals('materialForm');
  }, [actions]);
  // SEARCH FILTERS
  const handleChangeMaterialSearch = useCallback(
    (key, value) => {
      actions.changeMaterialSearch(key, value);
    },
    [actions],
  );

  return (
    <FetchLoading loading={loading} success={fetchSuccess} error={fetchError}>
      <Grid container sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          {user.user.type === 'admin' && <Tab label="Dólar" />}
          {user.user.type === 'admin' && <Tab label="Usuarios" />}
          <Tab label="Materiales internos" />
          <Tab label="Fórmulas internas" />
        </Tabs>
      </Grid>
      <Grid container>
        {user.user.type === 'admin' && (
          <TabPanel value={tabValue} index={TABS.DOLAR}>
            <MaintainersCurrencyForm controls={controls} actions={actions} />
          </TabPanel>
        )}
        {user.user.type === 'admin' && (
          <TabPanel value={tabValue} index={TABS.USERS}>
            <MaintainersUsersTable
              users={usersArr}
              controls={controls}
              actions={actions}
              disabledTable={disabledTable}
            />
            <MaintainersCreateOrUpdateUserModal
              open={modals.userForm}
              data={controls.userForm}
              actions={actions}
              toggleModalFnc={handleToggleUserForm}
            />
          </TabPanel>
        )}
        <TabPanel
          value={tabValue}
          index={user.user.type === 'admin' ? TABS.INT_MATERIALS : USER_TABS.INT_MATERIALS}>
          <MaintainersInternalMaterialFilters
            controls={controls}
            loading={loading}
            classes={classes}
            actions={actions}
            handleChange={handleChangeMaterialSearch}
            handleSearch={handleSearch}
          />
          <MaintainersInternalMaterialsTable
            controls={controls}
            actions={actions}
            // objects={objects}
            count={count}
            pagination={pagination}
            disabledTable={disabledTable}
            filteredIds={filteredIds}
            internalMaterials={filteredInternalMaterials}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            handleToggleMaterialForm={handleToggleMaterialForm}
          />
          <MaintainersMaterialFormModal
            open={modals.materialForm}
            data={controls.materialForm}
            actions={actions}
            toggleModalFnc={handleToggleMaterialForm}
            handleSearch={handleSearch}
          />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={user.user.type === 'admin' ? TABS.INT_FORMULAS : USER_TABS.INT_FORMULAS}>
          <MaintainersInternalFormulasTable
            controls={controls}
            actions={actions}
            // objects={objects}
            count={count}
            pagination={formulaPagination}
            disabledTable={disabledTable}
            filteredIds={filteredIds}
            internalFormulas={filteredInternalFormulas}
            handleChangePage={handleChangeFormulaPage}
            handleChangeRowsPerPage={handleChangeRowsPerFormulaPage}
            // handleRequestSort={handleRequestSort}
            // handleToggleMaterialForm={handleToggleMaterialForm}
          />
        </TabPanel>
      </Grid>
    </FetchLoading>
  );
};

export default composedComponent(Maintainers, saga, {
  saga: 'sagaUsers',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'maintainers.controls',
    'maintainers.invalidControls',
    'maintainers.loading',
    'maintainers.fetchSuccess',
    'maintainers.fetchError',
    'maintainers.modals',
    'maintainers.disabledTable',
    'maintainers.filteredIds',
    'maintainers.count',
  ],
  actions: [maintainersActions],
});
