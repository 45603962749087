import React from 'react';
import { LinearProgress } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import AuthUserContext from 'commons/context/user';
import Layout from 'commons/containers/Layout';
import Login from 'screens/Login';

const Auth = function ({ user, loading, children }) {
  const location = useLocation();

  if (loading) {
    return <LinearProgress />;
  }

  if (user) {
    if (user?.user?.disabled) return <Navigate to="/login" />;

    if (location.pathname === '/login') {
      return <Navigate to="/" />;
    }

    return (
      <Layout>
        <AuthUserContext.Provider value={user}>{children}</AuthUserContext.Provider>
      </Layout>
    );
  }

  return <Login />;
};
export default Auth;
